import React from "react";
import logo from '../resources/images/icam-logo-full-horiz-1c-white.svg';
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core';
import './Header.css';
import { useHistory } from 'react-router-dom';
import { AccountInfo } from '../types/UserInfoTypes';
import {formatUserFullname} from "../utils/formatUtils";
import moment from 'moment';

type HeaderProps = {
    accountInfo: AccountInfo|null|undefined
    onLogout: ()=>void
    onLogin: ()=>void
}



export const StyledButton = withStyles({
    root: {
      background: 'linear-gradient(45deg, #1C1B20 30%, #1C1B20 90%)',
      borderRadius: 3,
      borderColor: 'white',
      border: 0,
      color: 'white',
      height: 48,
      padding: '0 30px'
    },
    label: {
      textTransform: 'capitalize',
      color:"white"
    },
  })(Button);

export const StyledHeaderButton = withStyles({
    root: {
      background: 'linear-gradient(45deg, #1C1B20 30%, #1C1B20 90%)',
      border: `2px solid white`,
      color: 'white',
      height: 48,
      padding: '0 30px'
    },
    label: {
      textTransform: 'capitalize',
      color:"white",
      fontSize: 20,
    },
  })(Button);

export function Header(props: HeaderProps): JSX.Element{
        const history = useHistory()
        const accountInfo = props.accountInfo;
        const userInfoSpan = accountInfo ? (
             <div className="Header-right" >
                <span className="userInfo">
                    <span className="userInfo-span">{formatUserFullname(accountInfo.fullName)}</span><br/>
                    <span className="userInfo-span">{accountInfo.email}</span><br/>
                    <span className="userInfo-span">Last Login: {accountInfo && accountInfo.attributes && accountInfo.attributes.last_login  ? moment.utc(accountInfo.attributes.last_login).local().startOf('seconds').fromNow() : "N/A"}</span>
                </span>
                <span style={{paddingTop: 5}} ><StyledHeaderButton  variant="outlined"
                    onClick={() => {
                        props.onLogout();
                    }}>
                            Log Out
                </StyledHeaderButton></span>
            </div>
        ) : (
            <div className="Header-right">
                <StyledHeaderButton variant="outlined" onClick={()=>props.onLogin()}>Log In</StyledHeaderButton>
            </div>
        )
        return (
            <div className="Header" style={{paddingTop: accountInfo ? 30 : 0}}>
                <div className="Header-left">
                    <img src={logo} className="Header-logo" alt="logo" onClick={()=>history.push("/")}/>
                </div>
                {userInfoSpan}
            </div>
        )
    }
