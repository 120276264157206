import { AccountInfo } from "../types/UserInfoTypes";
import React, { useEffect, useState } from "react";
import { TaskQueryResult } from "../types/FlowableQueryResultsTypes";
import axios from "axios";
import config from "../config";
import { formatDateString12HourFromDate } from "../utils/dateUtils";
import { Divider, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Select } from "@material-ui/core";
import { LightTooltip } from "../components/LightTooltip";
import { claimTask, isSuperUser, unclaimTask } from "../actions/FlowableTaskActions";
import {AiOutlineDoubleLeft, AiOutlineDoubleRight} from 'react-icons/ai'
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { useHistory } from "react-router";
import { PROCESS_INSTANCE_ID_QS_KEY } from "../actions/FlowableFormActions";
import { TASK_ID_QS_KEY } from "../forms/ViewTask";
import { Picker } from "../components/Picker";
import _ from "lodash";

const ALL_QUEUE_TYPES = ['CANDIDATE', 'ALL'] as const
type QueueTypeTuple = typeof ALL_QUEUE_TYPES
type QueueType = QueueTypeTuple[number]

export function QueueView(props: { accountInfo: AccountInfo }):JSX.Element {
  const [candidateTasks, setCandidateTasks] = useState<TaskQueryResult[]>([])
  const [assignedTasks, setAssignedTasks] = useState<TaskQueryResult[]>([])
  const [refresh, setRefresh] = useState<Record<string, unknown>>({})
  const [queueAssignee, setQueueAssignee] = useState<AccountInfo|null>(props.accountInfo)
  const [queueType, setQueueType] = useState<QueueType>('CANDIDATE')
  const history = useHistory()
  useEffect(() => {
    async function getQueueTasks() {
      const assignedTasksFut = getAssignedTasks(queueAssignee);
      const candidateTasksFut = fetchQueueTasks(queueType, props.accountInfo);
      setAssignedTasks(await assignedTasksFut)
      setCandidateTasks(await candidateTasksFut)
    }
    getQueueTasks()
  }, [refresh, queueAssignee, queueType])

  return <>
    <Grid container spacing={1} style={{ height: "100%" }}>
      <Grid item xs={6} className={"queueHeader"}><h3>Candidate</h3></Grid>
      <Grid item xs={6} className={"queueHeader"}><h3>Assigned</h3></Grid>
      <Grid item xs={5} style={{ height: "100%", overflow:"auto" }}><>
        {isSuperUser(props.accountInfo) ? <Select 
          onChange={e => setQueueType(e.target.value as QueueType)} 
          value={queueType}
          variant="outlined"
          style={{width:'100%', height:"60px"}}>
        {ALL_QUEUE_TYPES.map(opt => 
          <MenuItem key={opt} value={opt} >
            <ListItemText primary={_.capitalize(opt.toLowerCase())}></ListItemText> 
          </MenuItem>
        )}
        </Select> : <></>}
        <List>
        {candidateTasks.map(t => <ListItem key={`candidate-${t.id}`}><ListItemText  primary={t.name} secondary={<>
          <div>{`Created ${formatDateString12HourFromDate(new Date(t.created))}`}</div>
          <div>{t.assignee ? `Created by ${t.assignee.lastName}, ${t.assignee.firstName}` : "Unassigned"}</div>
        </>}></ListItemText>
        <ListItemSecondaryAction>
          <LightTooltip title="Claim">
            <IconButton edge="end" onClick={async ()=>{await claimTask(t.id, queueAssignee ?? undefined); setRefresh({})}}>
              <AiOutlineDoubleRight />
            </IconButton>
          </LightTooltip>
        </ ListItemSecondaryAction>
        </ListItem>)}</List></>
      </Grid>
      <Grid item xs={1}><Divider style={{ margin: "auto"}} orientation="vertical" /></Grid>
      <Grid item xs={6} style={{ height: "100%", overflow:"auto" }}>
        { isSuperUser(props.accountInfo)? <Picker
            onChange={person =>{
              person ? setQueueAssignee(person as AccountInfo):setQueueAssignee(null)
            }} 
            pickerType='AccountInfo' 
            disabled={false} 
            // task-form call returns objects as stringified json for some reason
            value={queueAssignee}
            validationError={""}
          /> : <></>}
        <List>
        {assignedTasks.map(t => <ListItem key={`candidate-${t.id}`}>
            <LightTooltip title="Unclaim">
              <IconButton edge="start" onClick={async ()=>{await unclaimTask(t.id); setRefresh({})}}>
                <AiOutlineDoubleLeft />
              </IconButton>
            </LightTooltip>
          <ListItemText  primary={t.name} secondary={<>
          <div>{`Created ${formatDateString12HourFromDate(new Date(t.created))}`}</div>
          <div>{t.assignee ? `Assigned to ${t.assignee.lastName}, ${t.assignee.firstName}` : "Unassigned"}</div>
        </>}></ListItemText>
        <ListItemSecondaryAction>
                <LightTooltip title="Do">
                  <IconButton edge="end" onClick={async ()=>{history.push(`/siaRequest?${PROCESS_INSTANCE_ID_QS_KEY}=${t.processInstanceId}&${TASK_ID_QS_KEY}=${t.id}`)}}>
                    <PlayArrowIcon />
                  </IconButton>
                </LightTooltip>
            </ ListItemSecondaryAction></ListItem>)}
            </List>
      </Grid>
    </Grid>
  </>
}

async function getAssignedTasks(queueAssignee:AccountInfo|null): Promise<TaskQueryResult[]> {
  let filterQuery: Record<string, unknown> = { state: "running", assignment: "assignee" }
  if(queueAssignee){
    filterQuery = {...filterQuery, assignee: queueAssignee.id}
  }
  return (await axios.post(`${config.flowableUiApiBaseUrl}app/rest/query/tasks`,
    filterQuery, { withCredentials: true })).data.data
}

async function fetchQueueTasks(queueType:QueueType, accountInfo: AccountInfo): Promise<TaskQueryResult[]> {
  const assignment = queueType === "ALL" && isSuperUser(accountInfo) ? null : 'candidate'
  const fetchedTasks = (await axios.post(`${config.flowableUiApiBaseUrl}app/rest/query/tasks`,
    { state: "running", assignment: assignment, size:10000}, { withCredentials: true })).data.data as TaskQueryResult[]
  // Don't want to show assigned tasks in candidate queue if ALL
  if(queueType === 'ALL'){
    return fetchedTasks.filter(t => t.assignee?.id !== accountInfo.id)
  }
  return fetchedTasks
}