import {AccountInfo} from "../types/UserInfoTypes";
import _ from "lodash";
import {ProcessInstanceQueryResult} from "../types/FlowableQueryResultsTypes";
import {format} from "@loadsmart/utils-phone-number";
import nc from "namecase";

export const formatAssigneeInfo = (accountInfo?: AccountInfo, processInstanceInfo?: ProcessInstanceQueryResult): string => {
    if(accountInfo?.lastName || accountInfo?.firstName) {
        return formatUserFullname(accountInfo.fullName);
    } else {
        let correctUser;
        try {
            const newUsers = JSON.parse(processInstanceInfo?.processVariables["newusers"]);
            correctUser = _.find(newUsers, i => i.id == accountInfo?.id);
        } catch (e) {
            return '';
        }
        
        if (!correctUser || !correctUser.fullName) {
            return '';
        }

        if (!correctUser || !correctUser.fullName) {
            return '';
        }

        return formatUserFullname(correctUser.fullName);
    }
}

export const formatUserFullname = (fullName: string): string => {
    return nc(_.split(fullName, ' ').reverse().join(', '));
}

export const formatPhoneNumbers = (phoneNumber:string): string => {
    const mask = '(XXX) XXX-XXXX';
    return format(phoneNumber, mask);
}

export const createDisplayName = (accountInfo:AccountInfo): string => {
    const emailOut = (accountInfo.email) ? '(' + accountInfo.email.toLowerCase() + ')' : ''
    return formatUserFullname(accountInfo.fullName) + ' ' + emailOut;
}
