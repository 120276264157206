import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import './App.css';
import {
  Switch,
  Route,
  HashRouter,
} from "react-router-dom";
import { ISARequest } from './forms/ISARequest';
import { Header } from './header/Header';
import { AppBar, Button, createTheme, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Snackbar, ThemeProvider } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { grey, yellow } from '@material-ui/core/colors';
import axios from 'axios';
import config from './config'
import { Welcome } from './welcome/Welcome';
import { SARequestDashboard } from './dashboard/SAReqDash';
import { NewDashboard } from './dashboard/NewDashboard';
import { SuperAdminDashboard } from './dashboard/SuperAdminDashboard';
import { ViewTask } from './forms/ViewTask';
import { Banner } from './components/Banner';
import { AboutISA } from './about/AboutISA';
import {AccountInfo} from "./types/UserInfoTypes";
import { NotFound } from './components/NotFound';
import { Migrate } from './components/Migrate';
import { SessionExpire } from './components/SessionExpire';

const theme = createTheme({
  palette: {
    primary: {
      main: yellow[600],
      contrastText: grey[700]
    },
    secondary: {
      main: grey[800]

    },
  },
  overrides: {
    MuiButton: {
      label: {
        color: grey[800],
      },
    },
    MuiLink: {
      root: {
        color: grey[800],
        "&:hover":{
          color: yellow[600]
        }
      },
    }
  }
});

async function getAccountInfo(){
  const account = await axios.get(`${config.flowableUiApiBaseUrl}idm-app/rest/account`, {withCredentials:true})

  if(!account.data.id){
    return null
  }
  return account.data as AccountInfo;
}

function App(): JSX.Element{
  return <ISAApp />
}

function ISAApp(): JSX.Element {
  // undefined means unknown if logged in, null means not logged in
  const [accountInfo, setAccountInfo] = useState<AccountInfo|null|undefined>(undefined)
  const [error, setError] = useState<string|null>(null);
  const [needsAuth, setNeedsAuth] = useState<boolean|null>(false);
  const [sessionExpire, setSessionExpire] = useState<boolean>(false);

  async function logout(){
    try{
      window.location.replace('/#');
      await axios.get(`${config.flowableUiApiBaseUrl}app/logout`, {withCredentials:true})
    } finally{
      // Logout default URL redirects to Flowable, which would throw error, so we catch and just clear the state
      setAccountInfo(null)
      setSessionExpire(false)
    }
  }

  const tokenExpirySSOIdleInSeconds = (Number(config.sessionTimeout)*60) as number; // see comments in ISA-CLARITY-367 
  const tokenExpirySSOIdlePercentageInSeconds = ((tokenExpirySSOIdleInSeconds * 0.1)) as number; 
  const timeRemainingWarningInSeconds = (tokenExpirySSOIdleInSeconds-tokenExpirySSOIdlePercentageInSeconds) as number;

  let timeOutModal : null | ReturnType<typeof setTimeout>;

  async function clearTimeOuts () {
    if(timeOutModal !== null){
      clearTimeout(timeOutModal)
    }
  }

  useEffect(() => {
    if (!accountInfo) {
      updateAccountInfo()
    }
  }, [accountInfo])

  axios.interceptors.response.use(res => {
    if (res && res.status && res.status === 200) {    /// restrict to flowable api http
      clearTimeOuts();
      timeOutModal = setTimeout(()=> {setSessionExpire(true)}, timeRemainingWarningInSeconds * 1000);
    }
    return res;
  },
  
  res => {
    if (res && res.code === "ERR_NETWORK" && !(res.response.status === 400)) {
      if (! (window.location.hash === "#/")) {
        setNeedsAuth(true);
        sessionStorage.setItem('intended', window.location.hash);
      }
    }

    if (res.response && res.response.status && res.response.status === 500) {
      setError('There was a problem with this request. Please try again.')
    }
    throw res;
  });

  const isLoggedIn = accountInfo !== null && accountInfo !== undefined;

  if (sessionStorage.getItem('intended') && isLoggedIn) {
    const intended = sessionStorage.getItem('intended');
    if (intended) {
      window.location.replace(intended);
      sessionStorage.removeItem('intended');
    }
  }

  return (
    <ThemeProvider theme={theme}>
    <HashRouter>
    <div style={{minHeight:"100vh", display:"flex"}}>
      <AppBar position={"fixed"} color={"secondary"} style={{ zIndex: theme.zIndex.drawer + 1, height:"140px", background: `url(${process.env.PUBLIC_URL + '/background-texture.png'}) repeat-x center center fixed` }}>
      <Banner show={isLoggedIn} position="top" />
      <Banner show={isLoggedIn} position="bottom" />
        <div className={`version-number ${isLoggedIn ? "logged-in" : ""}`}>Version: {config.versionNumber}</div>

      <Header accountInfo={accountInfo} onLogout={() => {
          async function logout(){
            try{
              window.location.replace('/#');
              await axios.get(`${config.flowableUiApiBaseUrl}app/logout`, {withCredentials:true})
            } finally{
              // Logout default URL redirects to Flowable, which would throw error, so we catch and just clear the state
              setAccountInfo(null)
            }
          }
          logout()
        }}
        onLogin={()=>{
          window.location.replace(`${config.flowableUiApiBaseUrl}oauth2/authorization/${config.flowableAuthClient}`)
        }}
      />
      </AppBar>
      {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
      <Paper style={{flexGrow: 1,
        backgroundColor:"black",
        paddingTop:"100px",
        background: `url(${process.env.PUBLIC_URL + '/background-texture.png'}) repeat-x center center fixed`,
        backgroundSize: "cover"}}>
      <Switch>
        <Route path="/siaRequest">
          <RouteOrLogin accountInfo={accountInfo}><ISARequest accountInfo={accountInfo as AccountInfo} fieldTest={false as boolean} /></RouteOrLogin>
        </Route>
        <Route path="/dashboard/:menuOption(assigned|candidate|my-processes|completed|manage-processes|manage-tasks)" exact>
              <RouteOrLogin accountInfo={accountInfo}><SARequestDashboard accountInfo={accountInfo as AccountInfo} /></RouteOrLogin>
        </Route>
        <Route path="/newDashboard">
          <RouteOrLogin accountInfo={accountInfo}><NewDashboard accountInfo={accountInfo as AccountInfo}/></RouteOrLogin>
        </Route>
        <Route path="/superAdminDashboard">
          <RouteOrLogin accountInfo={accountInfo}><SuperAdminDashboard accountInfo={accountInfo as AccountInfo}/></RouteOrLogin>
        </Route>
        <Route path="/migrate">
        <RouteOrLogin accountInfo={accountInfo}><Migrate/></RouteOrLogin>
        </Route>
        <Route path="/viewTask">
          <RouteOrLogin accountInfo={accountInfo}><ViewTask /></RouteOrLogin>
        </Route>
        <Route path="/aboutISA">
          {accountInfo ? <AboutISA/> : <Route ref={() => {
                window.location.replace(`${config.flowableUiApiBaseUrl}oauth2/authorization/${config.flowableAuthClient}`);
            }}/>
          }
        </Route>
        <Route path="/fieldTesting">
          <RouteOrLogin accountInfo={accountInfo}><ISARequest accountInfo={accountInfo as AccountInfo} fieldTest={true as boolean}/></RouteOrLogin>
        </Route>
        <Route path="/" exact>
          <Welcome accountInfo={accountInfo}/>
        </Route>
        <Route>
          <NotFound/>
        </Route>
      </Switch>
      </Paper>
      <Snackbar
          open={error !== null}
          autoHideDuration={6000}
          onClose={() => { setError(null); }}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
          <Alert severity="error" onClose={() => { setError(null); }}>{error}</Alert>
        </Snackbar>
        <Dialog open={needsAuth === true}
          onClose={() => { setNeedsAuth(false); }}>
          <DialogTitle id="form-dialog-title">
                Session Expired
                
          </DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                  Please login.
              </Grid> 
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={ () => {setNeedsAuth(false)}} style={{marginRight:"7px"}}>
              CANCEL
            </Button>
            <Button color="primary" variant="contained"
                    onClick={() => {  
                      window.location.replace(`${config.flowableUiApiBaseUrl}oauth2/authorization/${config.flowableAuthClient}`)
                    }
                    }>
                    Login
            </Button>
          </DialogActions>
        </Dialog>
        {sessionExpire && <SessionExpire logout={logout} timeRemainingWarningInSeconds={tokenExpirySSOIdlePercentageInSeconds} setSessionExpire={setSessionExpire} getAccountInfo={getAccountInfo} clearLogOut={clearTimeOuts}/>}
    </div>
  </HashRouter>
  </ThemeProvider>
  );

  async function updateAccountInfo(){
    try{
      const newAccountInfo = await getAccountInfo()
      if(!_.isEqual(newAccountInfo, accountInfo)){
        setAccountInfo(newAccountInfo)
      }
    } catch(e){
      console.error(e);
      setAccountInfo(null)
    }
  }
}

// TODO: Replace with better version using ReactRouter v5/v6 and AccountContexts/providers
function RouteOrLogin(props:{accountInfo: AccountInfo | null | undefined, children: JSX.Element}): JSX.Element{
  return (_.isUndefined(props.accountInfo) || _.isNull(props.accountInfo)) ? <Welcome accountInfo={props.accountInfo}/> : props.children;
}

export default App;
