import { Button, withStyles } from "@material-ui/core";

export const ArchiveButton = withStyles({
  root: {
    background: '#B33A3A',
    color: 'white',
  },
  label: {
    textTransform: 'capitalize',
    color:"white",
    fontSize:14
  },
})(Button);