import { Button, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { Picker } from "../components/Picker";
import { AccountInfo } from "../types/UserInfoTypes";
import { isSuperUser } from "../actions/FlowableTaskActions";

export type ProcessInstanceFilters = {
  businessKey?: string
  processDefinitionName?:string
  startedBy?:AccountInfo
}

export function ProcessInstanceFilter(props:{onFilterApply: (p:ProcessInstanceFilters)=>void, accountInfo:AccountInfo, initialFilters:ProcessInstanceFilters}):JSX.Element{
  const [processInstanceFilters, setProcessInstanceFilters] = useState<ProcessInstanceFilters>(props.initialFilters)
  return <>
      <TextField label="Process Instance ID" variant={"outlined"} size="small" style={{width:"100%"}} margin="dense"
            onChange={(evt)=>setProcessInstanceFilters({...processInstanceFilters, businessKey:evt.target.value.trim()})}
      />
      <TextField label="Process Definition Name" variant={"outlined"} size="small" style={{width:"100%"}} margin="dense"
        onChange={(evt)=>setProcessInstanceFilters({...processInstanceFilters, processDefinitionName:evt.target.value.trim()})}
      />
    {isSuperUser(props.accountInfo) ? <div style={{marginTop:"7px"}}>
      <Picker
        size="small"
        onChange={person =>{ 
          setProcessInstanceFilters({...processInstanceFilters, startedBy:person as AccountInfo ?? null})
        }} 
        pickerType='AccountInfo' 
        disabled={false} 
        // task-form call returns objects as stringified json for some reason
        validationError={""}
        value={processInstanceFilters.startedBy}
      /> 
    </div> : <></>}
      <Button color={"primary"} style={{width:"100%", marginTop:"15px"}} size="small" variant="contained" onClick={()=>props.onFilterApply(processInstanceFilters)}>Apply Filters</Button>
  </>
}