import {FlowableFormField, FormState} from "../types/FlowableFormTypes";
import React, {ElementType} from "react";
import {getExpressionFunc, getMaxCharacters} from "./FormUtils";
import {flatten} from "flat";
import {
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup, InputBaseComponentProps,
    InputLabel,
    Link,
    Radio,
    RadioGroup,
    TextField
} from "@material-ui/core";
import {FileUpload} from "../components/FileUpload";
import {PdfGenerator} from "./PdfGenerator";
import DOMPurify from "dompurify";
import { Picker} from "../components/Picker";
import { GroupInfo, AccountInfo } from "../types/UserInfoTypes";
import {fieldValidationError} from "./ISARequest";
import {DropdownComponent} from "../components/DropdownComponent";
import InputMask from "react-input-mask";
import NumberFormat from "react-number-format";
import {formatDateStringNoHoursFromString} from "../utils/dateUtils";
import _ from "lodash";
import {DropDownOption} from "../types/FormComponentTypes";
import axios from "axios";
import config from "../config";
import qs from "querystringify";
import {createDisplayName} from "../utils/formatUtils";


const parseDateField = (formState:FormState, field: FlowableFormField) => {
    return formatDateStringNoHoursFromString(formState[field.id] as string);
}

/**
 * The field rendering switch. Determines which fields should be rendered, how they're validated, etc. Relies on {FlowableForm.tsx}.
 * @param field
 * @param fields
 * @param formState
 * @param setFormState
 * @param addToFormState
 * @param taskId
 */
export function renderField(field: FlowableFormField, fields: FlowableFormField[], formState: FormState, setFormState: (fs:FormState)=>void, addToFormState:(fs:FormState, key:string, value:unknown) => FormState, taskId: string|null): JSX.Element {
    const handleChange = (e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setFormState(addToFormState(formState, field.id, e.target.value))
    const handleNumberChange = (e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setFormState(addNumberToFormState(formState, field.id, e.target.value as string))
    const visibilityFunc = getExpressionFunc(field.params?.visibilityExpression, true)
    const maxCharacters = getMaxCharacters(field.params?.validationExpression);
    const isVisible = visibilityFunc(flatten(formState, {safe:true}))
    if(!isVisible){
        // sometimes there are 2 fields with same id and a condition controls which is visible
        // in such a case we need to make sure that ALL fields with given id are hidden before setting state to undefined
        const allHidden = fields.filter(f => f.id === field.id).every(f => {
            const visibilityFunc = getExpressionFunc(f.params?.visibilityExpression, true)
            return !visibilityFunc(flatten(formState, {safe:true}))
        })
        if(formState[field.id] && !field.readOnly && allHidden){
            setFormState(addToFormState(formState, field.id, undefined))
        }
        return <></>
    }
    switch (field.type) {
        case "text":
            return getTextField(field, handleChange, formState);
        case "multi-line-text":
            return (
                <div className={'fieldDiv'}>
                    <InputLabel error={fieldValidationError(field, formState).length > 0}>
                        {field.name}{field.required ? '*' : ''}
                    </InputLabel>
                    <TextField onChange={handleChange} multiline error={fieldValidationError(field, formState).length > 0}
                               style={{width:"100%"}} variant="outlined" rows={3}
                               value={formState[field.id] as string | number | readonly string[] | undefined}
                               disabled={field.readOnly}
                               helperText={fieldValidationError(field, formState)}
                    />
                    {getMaxCharacterCalculation(field, maxCharacters, formState)}
                </div>
            )
        case "integer":
            return getNumberTextField(field, handleNumberChange, formState, 0)
        case "decimal":
            return getNumberTextField(field, handleNumberChange, formState, undefined)
        case "date":
            return getDateField(field, handleChange, formState);
        case "boolean":
            return (
                <div className={'fieldDivCheckbox'}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox onChange={(e,val)=>setFormState(addToFormState(formState, field.id, val))} checked={formState[field.id] as boolean | undefined}></Checkbox>}
                            label={field.name+(field.required ? '*' : '')}
                            disabled={field.readOnly}
                        />
                    </FormGroup>
                </div>
            )
        case "radio-buttons":
            return (
                <div className={'fieldDiv'}>
                    <InputLabel error={fieldValidationError(field, formState).length > 0}>
                        {field.name}{field.required ? '*' : ''}
                    </InputLabel>
                    <RadioGroup name={field.id} onChange={handleChange} value={formState[field.id]}>
                        {field.options?.map(opt => <FormControlLabel key={"radio-group-val-"+opt.name} value={opt.name} control={<Radio />} label={opt.name} disabled={field.readOnly}/>)}
                    </RadioGroup>
                </div>
            )
        case "dropdown":
            return <DropdownComponent
                field={field}
                formState={formState}
                setFormState={setFormState}
                addToFormState={addToFormState}
                optionsFunc={() => Promise.resolve(field.options?.map(o => {return {id: o.id ?? o.name, name:o.name}})?? [])}
            />
        case "process-dropdown":
            return <DropdownComponent
                field={field}
                formState={formState}
                setFormState={setFormState}
                addToFormState={addToFormState}
                optionsFunc={() => getProcessDropDownOptions(field.params?.processKey, field.params?.processFilterVariable, formState[field.params?.processFilterVariable as string] as string|undefined)}
            />
        case "upload":
            return <><InputLabel error={fieldValidationError(field, formState).length > 0}>{field.name}{field.required ? '*' : ''}</InputLabel>
                <FileUpload
                    onFileAdd={fileId => setFormState(addToFormStateArrayCommaDelimited(formState, field.id, fileId))}
                    onFileRemove={fileId => setFormState(removeFormStateArrayCommaDelimited(formState, field.id, fileId))}
                    readonly = {field.readOnly}
                    acceptedFileTypes = {(field.params?.allowedFileTypes ?? "").split(",")}
                    fileIds={formState[field.id] ? (formState[field.id] as string).split(","):[]}
                    multiple={field.params?.multiple}
                    taskId={taskId}
                /></>
        case "pdf-section": {
            const value = field.value as {[key:string]:unknown}
            const pdfTemplateImage = field.pdfTemplateImage as string
            const pdfTemplate = field.pdfTemplate as string
            const signatures = field.pdfSignatures ? (field.pdfSignatures).split(",") : []

            return (<div style={{"paddingBottom": "10px"}}>
                <PdfGenerator formValues={value} pdfHeaderImage={pdfTemplateImage} pdfHtmlTemplate={pdfTemplate} signatures={signatures}/>
            </div>)
        }
        case "expression": {
            const value = field.value ? field.value : ""
            if (field.htmlExpression){
                //this does not error. sanitized in case there's any fishy code
                const sanitized = DOMPurify.sanitize(value as string)
                return <div dangerouslySetInnerHTML={{__html: sanitized}}></div>
            }
            return <div>{value as string}</div>
        }
        case "people": {
            let val;
            // task-form call returns objects as stringified JSON
            // TODO: Figure out how to serialize specific objects (e.g. requestor)
            if(typeof formState[field.id] === 'string') {
                val = JSON.parse(formState[field.id] as string);
            } else {
                val = formState[field.id] as AccountInfo;
                if(val) { // if not undefined, create the display name
                    val.displayName = createDisplayName(val);
                }
            }
            return (
                <div className={'fieldDiv'}>
                    <InputLabel error={fieldValidationError(field, formState).length > 0}>
                        {field.name}{field.required ? '*' : ''}
                    </InputLabel>
                    <Picker
                        onChange={person => setFormState(addToFormState(formState, field.id, person))}
                        pickerType='AccountInfo'
                        disabled={field.readOnly}
                        extraOptions={(formState["newusers"] as AccountInfo[])}
                        value={val}
                        validationError={fieldValidationError(field, formState)}
                    />
                </div>
            )
        }
        case "functional-group":
            return (
                <div className={'fieldDiv'}>
                    <InputLabel error={ fieldValidationError(field, formState).length > 0 }>
                        {field.name}{field.required ? '*' : ''}
                    </InputLabel>
                    <Picker onChange={group => setFormState(addToFormState(formState, field.id, group))} pickerType='GroupInfo' disabled={field.readOnly}
                        // task-form call returns objects as stringified json for some reason
                            value={typeof formState[field.id] === 'string' ? JSON.parse(formState[field.id] as string) : formState[field.id] as GroupInfo}
                            validationError={fieldValidationError(field, formState)}
                    />
                </div>
            )
        case "container":
            // not sure where this one comes from
            break
        case "hyperlink": {
            const link = field.name
            if (field.value){
                return (
                    <div className={'fieldDiv'}>
                        <Link href={field.value as string} target="_blank" underline="always">
                            {field.name}
                        </Link>
                    </div>
                )
            }
            return (
                <div className={'fieldDiv'}>
                    {link}
                </div>
            )
        }
        case "spacer":
            return <p/>
        case "horizontal-line":
            return (
                <Divider style={{marginTop:"7px", marginBottom:"5px"}}/>
            )
        case "headline":
            return (
                <div className="fieldHeadlineDiv">
                    <span className="fieldHeadlineSpan">{field.name}</span>
                </div>
            )
        case "headline-with-line":
            return (
                <div className="fieldHeadlineDiv">
                    <span className="fieldHeadlineSpan">{field.name}</span>
                    <Divider  style={{marginTop:"7px", marginBottom:"5px"}}/>
                </div>
            )
    }
    return <div>Unable to render</div>
}

function getDateField(field: FlowableFormField, handleChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void, formState: FormState) {
    const validationError = fieldValidationError(field, formState);

    return (
        <div className={'fieldDiv'}>
            <InputLabel error={validationError.length > 0}>
                {field.name}{field.required ? '*' : ''}
            </InputLabel>
            <TextField
                type="date"
                error={fieldValidationError(field, formState).length > 0}
                onChange={handleChange}
                value={formState[field.id] ? parseDateField(formState, field): null}
                variant="outlined"
                disabled={field.readOnly}
                helperText={validationError}
            />
        </div>
    )
}
function getTextField(field: FlowableFormField, handleChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void, formState: FormState) {
    let inputComponent = null;
    const validationError = fieldValidationError(field, formState)
    if (field.params?.mask) {
        let mask = field.params.mask;
        mask = mask.replaceAll("A", "a");
        inputComponent =
            <InputMask onChange={handleChange} placeholder={field.placeholder ?? ''} mask={mask} value={formState[field.id] as string | number | readonly string[] | undefined} disabled={field.readOnly}>
                {(inputProps: unknown) => <TextField {...inputProps} style={{ width: "100%" }} error={validationError.length > 0} variant="outlined" disabled={field.readOnly}/>}
            </InputMask>;
    }
    else {
        inputComponent = <TextField placeholder={field.placeholder ?? ''} onChange={handleChange} error={validationError.length > 0} variant="outlined" style={{ width: "100%" }} value={formState[field.id]} disabled={field.readOnly} helperText={validationError}/>;
    }
    return (
        <div className={'fieldDiv'}>
            <InputLabel error={validationError.length > 0}>
                {field.name}{field.required ? '*' : ''}
            </InputLabel>
            {inputComponent}
        </div>
    );
}

function getNumberTextField(field: FlowableFormField, handleChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void,
                            formState: FormState, decimalVal: number|undefined){
    const maxLen = field.params?.maxLength ? Number(field.params.maxLength) : undefined

    return (
        <div className={'fieldDiv'}>
            <InputLabel error={fieldValidationError(field, formState).length > 0}>
                {field.name}{field.required ? '*' : ''}
            </InputLabel>
            <TextField
                value={formState[field.id] as number | undefined}
                onChange={handleChange}
                variant="outlined" style={{ width: "100%" }}
                type="numberformat"
                InputProps={{
                    inputComponent: NumberFormatCustom as ElementType<InputBaseComponentProps>,
                    inputProps:{
                        decimal: decimalVal,
                        maxLength: maxLen
                    }
                }}
                disabled={field.readOnly}
            />
        </div>
    )
}

function getMaxCharacterCalculation(field: FlowableFormField, maxCharacters: number, formState: FormState) {
    return <div style={{float:'right'}}>{(maxCharacters > 0) ? 'max '+ maxCharacters + ' characters (' + ((formState[field.id] as string | undefined || "").length + " / " + maxCharacters) + ')' : <></> }</div>

}

async function getProcessDropDownOptions(processDefinitionKey: string|undefined, processFilterVarName: string|undefined, processFilterVarValue: string|undefined): Promise<DropDownOption[]>{
    const queryStringObj: Record<string, unknown> = {
        processDefinitionKey: processDefinitionKey
    }
    if(processFilterVarName){
        queryStringObj['variableName'] = processFilterVarName
    }
    if(processFilterVarValue){
        queryStringObj['variableValue'] = processFilterVarValue
    }
    return (await axios.get(`${config.flowableUiApiBaseUrl}app/rest/processDropDown/processesWithVariableValue?${qs.stringify(queryStringObj)}`, {withCredentials: true})).data
}


function addNumberToFormState(currentFormState: FormState, keyToAdd: string, valueToAdd: string): FormState {
    const newFormState = { ...currentFormState }
    valueToAdd = valueToAdd.replaceAll(',','')
    newFormState[keyToAdd] = parseFloat(valueToAdd)
    return newFormState
}

function addToFormStateArrayCommaDelimited(currentFormState: FormState, keyToAdd: string, valueToAdd: unknown): FormState {
    const newFormState = { ...currentFormState }
    if(!newFormState[keyToAdd]){
        newFormState[keyToAdd] = valueToAdd
    } else {
        newFormState[keyToAdd] += "," + valueToAdd
    }
    return newFormState
}

function removeFormStateArrayCommaDelimited(currentFormState: { [key: string]: unknown }, keyToRemoveFrom: string, valueToRemove: unknown): { [key: string]: unknown } {
    if(!currentFormState[keyToRemoveFrom]){
        return currentFormState
    }
    const newFormState = { ...currentFormState }
    const values: string[] = (newFormState[keyToRemoveFrom] as string).split(",")
    const newValues = _.without(values, valueToRemove)
    newFormState[keyToRemoveFrom] = newValues.join(",")
    return newFormState
}


interface NumberFormatCustomProps {
    decimal: number|undefined;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { ...other } = props;

    return (
        <NumberFormat {...other}
                      decimalScale={props.decimal}
                      isNumericString
        />
    );
}