import React from "react";
import { List, ListItem, ListItemText, Typography } from "@material-ui/core"
import axios from "axios"
import _ from "lodash"
import { useHistory } from "react-router-dom"
import { AccountInfo } from "../types/UserInfoTypes"
import config from "../config"
import { PROCESS_INSTANCE_ID_QS_KEY } from "../actions/FlowableFormActions"
import { ProcessInstanceQueryResult, SubscriptionQueryResult, TaskQueryResult } from "../types/FlowableQueryResultsTypes"
import { FileDetails } from "../types/FlowableFormTypes";
import fileDownload from "js-file-download";

export function ActionsList(props: { processInstance: ProcessInstanceQueryResult; accountInfo: AccountInfo, completedTasks: TaskQueryResult[], subscriptions: SubscriptionQueryResult[]}): JSX.Element {
  const history = useHistory()

  const {completedTasks, subscriptions} = props;

  const hasCounterDoc = completedTasks.filter(obj => obj.formKey ==="CounterSignedMemoDocumentUpload").length > 0;

  return subscriptions.length > 0 ? <List>
    {subscriptions.map(a => <ListItem button
      key={`actions-${a.id}`}
      onClick={async () => {
        const processIdToLaunch = await getProcessIdToLaunch(a, props.processInstance);
        history.push(`/siaRequest?${PROCESS_INSTANCE_ID_QS_KEY}=${processIdToLaunch}`);
      } }>
      <ListItemText primary={a.eventName}></ListItemText>
    </ListItem>
    )}
   {hasCounterDoc ? <ListItem button
      onClick={async () => {
        fileDetailsSummary(props.processInstance.id);
      }}
   ><ListItemText>Download Counter Signed Document</ListItemText></ListItem> : <></>}
  </List> : <Typography color="textSecondary">No actions are available for this process</Typography>;
}

async function getProcessIdToLaunch(subscription: SubscriptionQueryResult, processInstance: ProcessInstanceQueryResult): Promise<string> {
  await axios.post(`${config.flowableUiApiBaseUrl}app/rest/signals/${subscription.executionId}/${encodeURIComponent(subscription.eventName)}`, {}, {withCredentials:true})
  let processInstanceIdToLaunch = subscription.processInstanceId
  if(subscription.eventName === "Renew Agreement"){
    const processInstancesWithBusinessKey = await getNewProcessIdForClone(processInstance);
    processInstanceIdToLaunch = processInstancesWithBusinessKey[0].id
  }
  return processInstanceIdToLaunch;
}


const fileDetailsSummary = async (processInstanceId: string) => {

  const processInstance1 = (await axios.get(`${config.flowableUiApiBaseUrl}app/rest/process-instances/${processInstanceId}?includeProcessVariables=true`, {withCredentials:true})).data
  const processVariables = processInstance1.processVariables.uploadisateamcountersignedmemo;  
  const fileIds = (processVariables as string).split(",")

  for (const fileId of fileIds){
    const fileDetail = await getFileDescription(fileId);
    axios.get(`${config.flowableUiApiBaseUrl}app/rest/content/${fileDetail.id}/raw`, {withCredentials:true, responseType:'blob'})
      .then(res => fileDownload(res.data, fileDetail.name as string))
  }
}

export async function getFileDescription(fileId: string): Promise<FileDetails> {
  return (await axios.get(`${config.flowableUiApiBaseUrl}app/rest/content/${fileId}`, {withCredentials:true})).data
}

async function getNewProcessIdForClone(processInstance: ProcessInstanceQueryResult | null) {
  const newBusinessId = getIncrementBusinessId(processInstance?.businessKey);
  const processInstancesWithBusinessKey = (await axios.post(`${config.flowableUiApiBaseUrl}app/rest/query/process-instances`,
    {
      businessKey: newBusinessId,
      name: config.appName + " Request - " + new Date().toISOString()
    },
    { withCredentials: true })).data.data as ProcessInstanceQueryResult[];
  if (processInstancesWithBusinessKey.length != 1) {
    throw new Error(`Expected one process instance with business key ${newBusinessId} but got ${processInstancesWithBusinessKey.length}`);
  }
  return processInstancesWithBusinessKey;
}

function getIncrementBusinessId(processInstanceId: string | undefined): string | undefined {
  if(!processInstanceId){
    return processInstanceId
  }
  const increment = parseInt(processInstanceId.slice(-2)) + 1
  return processInstanceId.substring(0, processInstanceId.length - 2) + _.padStart(increment + "", 2, "0")
}