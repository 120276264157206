import {FlowableFormField, FormState} from "../types/FlowableFormTypes";
import React, {useEffect, useState} from "react";
import _ from "lodash";
import {Checkbox, FormHelperText, InputLabel, ListItemText, MenuItem, Select} from "@material-ui/core";
import {fieldValidationError} from "../forms/ISARequest";
import {DropDownOption} from "../types/FormComponentTypes";



export function DropdownComponent(props:{field: FlowableFormField, formState: FormState, setFormState: (fs:FormState)=>void, addToFormState: (fs:FormState, key:string, value:unknown )=>FormState, optionsFunc: (p:Record<string, unknown>) => Promise<DropDownOption[]> }) : JSX.Element {
    const validationError = fieldValidationError(props.field, props.formState);
    const [options, setOptions] = useState<DropDownOption[]>([])
    const defaultValue = options?.length ? options[0].id : ''
    const isMultiselect = props.field.params?.multiselect ? true : false
    const optionsIdToOption = new Map<unknown, DropDownOption>(options.map(x => [x.id, x]))
    let formStateVal: string[]|string|undefined = props.formState[props.field.id] as string[]|string|undefined
    if(!formStateVal || _.isEmpty(formStateVal)){
        formStateVal = isMultiselect ? [defaultValue] : defaultValue
    }
    useEffect(()=>{
        async function getDropDownOptions(){
            const newOptions = await props.optionsFunc({});
            const newOptionIds = newOptions.map(o => o.id)
            const hasSelectedOptions = isSelectedOptionAvailable();
            if(!hasSelectedOptions && !_.isEqual(options, newOptions)){
                props.setFormState(props.addToFormState(props.formState, props.field.id, undefined))
            }
            if(!_.isEqual(options, newOptions)){
                setOptions(newOptions)
            }

            function isSelectedOptionAvailable() {
                let selectedOptionAvailable = false;
                if (_.isArray(formStateVal)) {
                    selectedOptionAvailable = _.intersection(newOptionIds, formStateVal).length == formStateVal.length;
                } else {
                    selectedOptionAvailable = newOptionIds.indexOf(formStateVal as string) !== -1;
                }
                return selectedOptionAvailable;
            }
        }
        getDropDownOptions()
    }, [props.optionsFunc])

    const selectDropdownValue = (value:unknown|string[]) => {
        if (!isMultiselect && value === defaultValue){
            value = null
        }
        if(isMultiselect){
            value = (value as string[]).filter(i => i !== defaultValue)
        }
        props.setFormState(props.addToFormState(props.formState, props.field.id, value))
    }

    const handleSelectAll = () => {
        if(options?.filter(opt => opt.id !== defaultValue).length === (props.formState[props.field.id] as string[])?.length){
            selectDropdownValue([]);
        } else {
            selectDropdownValue(options?.filter(opt => opt.id !== defaultValue).map(e => e.id));
        }
    };
    return (
        <div className={'fieldDiv'} style={{maxWidth: 800}}>
            <InputLabel error={validationError.length > 0}>
                {props.field.name}{props.field.required ? '*' : ''}
            </InputLabel>
            <Select onChange={e => selectDropdownValue(e.target.value)}
                    style={{ marginTop: "5px", width: "100%" }}
                    error={validationError ? true : false}
                    variant="outlined"
                    value={formStateVal}
                    disabled={props.field.readOnly}
                    multiple={isMultiselect}
                    renderValue={(selected)=>{
                        if(_.isArray(selected)){
                            return selected.map(s => (optionsIdToOption.get(s))).filter(s=>s).map(s => s?.name).join(", ")
                        } else{
                            return (optionsIdToOption.get(selected) as DropDownOption)?.name
                        }
                    }}
            >
                       <div onChange={(e) => {e.stopPropagation();}}   > 
                       {isMultiselect ? <MenuItem  onClick={(e) => {e.stopPropagation(); handleSelectAll();}} value="Select All">
                            <Checkbox onChange={(e) => {e.stopPropagation();}} checked = {options?.filter(opt => opt.id !== defaultValue).length === (props.formState[props.field.id] as string[])?.length}  /> 
                            <ListItemText primary={"Select All"}></ListItemText>
                        </MenuItem> : <></>}
                        </div>
                {options?.filter(opt => isMultiselect ? opt.id !== defaultValue : true)
                    .map(opt =>
                        <MenuItem key={"drop-down-value"+opt.id} value={opt.id} >
                            {isMultiselect ? <Checkbox checked={(props.formState[props.field.id] as string[])?.indexOf(opt.id) > -1} />: <></>}
                            <ListItemText primary={opt.name}></ListItemText>
                        </MenuItem>
                    )}
            </Select>
            {validationError ? <div className="MuiFormHelperText-contained"><FormHelperText error >{validationError}</FormHelperText></div> : null}
        </div>
    );
}