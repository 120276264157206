import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { AccountInfo } from "../types/UserInfoTypes";
import { ProcessInstanceQueryResult } from "../types/FlowableQueryResultsTypes";
import { ProcessDetailsView } from "./ProcessDetailsViewNew";
import { ProcessInstanceFilter, ProcessInstanceFilters } from "./ProcessInstanceFilter";
import { ProcessList } from "./ProcessInstanceList";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {RiFilterFill} from 'react-icons/ri'

export function ProcessView(props: { accountInfo: AccountInfo}):JSX.Element {
  const [selectedProcessInstance, setSelectedProcessInstance] = useState<ProcessInstanceQueryResult|undefined>(undefined)
  const initialFilters = {startedBy:props.accountInfo, businessKey:"", processDefinitionName:""}
  const [processInstanceFilters, setProcessInstanceFilters] = useState<ProcessInstanceFilters>(initialFilters)
  return <Grid container spacing={1} style={{ height: "100%" }}>
    <Grid item xs={3} style={{height:"100%"}}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <RiFilterFill style={{paddingRight:'3px', paddingTop:'2px'}} /><Typography>Filter</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <ProcessInstanceFilter
              onFilterApply={filters => setProcessInstanceFilters(filters)}
              accountInfo={props.accountInfo}
              initialFilters={initialFilters}
          />
          </div>
        </AccordionDetails>
      </Accordion>
      <div style={{ height: "100%", overflow:"auto" }}>
        <ProcessList accountInfo={props.accountInfo} onProcessSelect={p => setSelectedProcessInstance(p)} processInstanceFilter={processInstanceFilters}></ProcessList>
      </div>
    </Grid>
    <Grid item xs={9} style={{ height: "100%", overflow:"auto", paddingLeft:"25px" }}>
    {selectedProcessInstance ? <ProcessDetailsView processInstance={selectedProcessInstance} accountInfo={props.accountInfo}></ProcessDetailsView> : <></>}
    </Grid>
  </Grid>;
}