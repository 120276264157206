import React, {useEffect, useMemo, useState} from 'react';

import {HTMLOptions, jsPDF, jsPDFOptions} from 'jspdf';

import { StyledButton } from '../header/Header';
import _ from 'lodash';
import {
    PDFDocument,
    PDFString,
    rgb,
} from 'pdf-lib'
import Handlebars from 'handlebars';
import {useLocation} from "react-router-dom";
import axios from "axios";
import config from "../config";
import { formatPhoneNumbers } from '../utils/formatUtils';

const TEXT_FIELD_WIDTH = 150
const TEXT_FIELD_HEIGHT = 20
const TEXT_FIELD_BORDER_WIDTH = 1
const TEXT_WIDTH = 150
const LINE_HEIGHT = 40
const PAD_LEFT = 50

/**
 * Custom hook to allow easy access to query params.
 */
function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

/**
 *
 * @param props
 * @constructor
 */
export function PdfGenerator(props: {formValues: {[key:string]:unknown}, pdfHeaderImage: string, pdfHtmlTemplate: string, signatures: string[]}): JSX.Element {
    const [filename, setFilename] = useState('');
    const query = useQuery();
    useEffect(() => {
        async function getBusinessKey(){
            const processInstanceResponse = (await axios.get(
                `${config.flowableUiApiBaseUrl}app/rest/process-instances/${query.get('processInstanceId')}`,
                {
                    withCredentials:true
                })).data;

            const { businessKey } = processInstanceResponse;
            setFilename(businessKey)
        }
        getBusinessKey();
    });

    return <StyledButton onClick={() => prepareDocForDownload(props, filename)} color="secondary">
                Click to Download Signatory Document
            </StyledButton>
}

function prepareFormValues(formValues: {[key:string]:unknown}) {
    // eslint-disable-next-line
    const applicationsForAgreement = formValues.applications && Array.isArray(formValues.applications) ? formValues.applications.map((application): any => {
        const requestInfo: string[] = formValues.requestNumber ? (formValues.requestNumber as string).split('-') : [];
        const ipRouterNetwork: string = requestInfo.length > 3 ? requestInfo[requestInfo.length - 3] : '';
        const requestNumber: string = requestInfo.length > 3 ? requestInfo[requestInfo.length - 2] : '';
        const request: string = (application.newSignOnRequestType ?? '') as string;

        if (ipRouterNetwork === "NIPR" && request.includes("Single Sign-On")) {
            application.ssoId = `${formValues.organizationacronym}-210-${requestNumber}-${application.applicationacronym}`;
        }
        if (ipRouterNetwork === "NIPR"  && request.includes("AMID-LDAP")) {
            application.ldapId = `${formValues.organizationacronym}-310-${requestNumber}-${application.applicationacronym}`;
        }
        if (ipRouterNetwork === "SIPR"  && request.includes("Single Sign-On")) {
            application.ssoId = `${formValues.organizationacronym}-410-${requestNumber}-${application.applicationacronym}`;
        }
        if (ipRouterNetwork === "SIPR"  && request.includes("AMID-LDAP")) {
            application.ldapId = `${formValues.organizationacronym}-610-${requestNumber}-${application.applicationacronym}`;
        }
        if (ipRouterNetwork === "NIPR"  && request.includes("IGA")) {
            application.igaId = `${formValues.organizationacronym}-710-${requestNumber}-${application.applicationacronym}`;
        }
        if (ipRouterNetwork === "SIPR"  && request.includes("IGA")) {
            application.igaId = `${formValues.organizationacronym}-810-${requestNumber}-${application.applicationacronym}`;
        }
        if (ipRouterNetwork === "NIPR"  && request.includes("PAM")) {
            application.pamId = `${formValues.organizationacronym}-710-${requestNumber}-${application.applicationacronym}`;
        }
        if (ipRouterNetwork === "SIPR"  && request.includes("PAM")) {
            application.pamId = `${formValues.organizationacronym}-810-${requestNumber}-${application.applicationacronym}`;
        }
        return application;
    }) : [];

    return {...formValues, ...{applicationsForAgreement}};
}

/**
 *
 * @param props
 * @param filename
 */
function prepareDocForDownload(props: {[key:string]:unknown}, filename: string) {
    const formValues:{[key:string]:unknown|string} = prepareFormValues(props.formValues as {[key:string]:unknown|string});
    const pdfHeaderImage: string = props.pdfHeaderImage as string
    const htmlString : string = props.pdfHtmlTemplate as string
    const signatures: string[] = props.signatures as string[]
    const template = Handlebars.compile(htmlString)
    const formIssm = JSON.parse(formValues.issm as string);
    let issm;

    if(formIssm.attributes){
        const formatPhoneNumber = formatPhoneNumbers(formIssm.attributes["phone_number"][0])
        issm = {...formIssm, "attributes": {...formIssm.attributes, "phone_number": formatPhoneNumber}}
    }   

    const convertedFormValues = formIssm.attributes ? convertFormValues({...formValues, issm}) : convertFormValues(formValues);
    const renderedHtml = template(convertedFormValues)
    const renderedHtmlParts = renderedHtml.split("<!--ADD_PAGE-->");
    const sigDoc = document.createElement('html')
    sigDoc.id = 'memo'

    sigDoc.style.width = "635px"
    sigDoc.style.fontSize = '14px'

    const image = document.createElement('img')
    image.src = pdfHeaderImage 
    image.style.width = '670px'
    image.style.height = '115px'
    sigDoc.appendChild(image)
    sigDoc.appendChild(addHTMLString(renderedHtmlParts[0]))

    generatePdfContent(sigDoc, signatures, filename, renderedHtmlParts)
}

/**
 * Recursively convert any JSON strings into objects
 * @param formValues
 */
function convertFormValues(formValues: unknown):unknown {
    if(!formValues){
        return formValues
    }
    else if(typeof formValues === "object" ){
        if(_.isArray(formValues)){
            return formValues.map(fv => convertFormValues(fv))
        // if regular object
        } else {
            const convertedFormValues: Record<string, unknown> = {};
            for(const [key, val] of Object.entries(formValues)){
                convertedFormValues[key] = convertFormValues(val)
            }
            return convertedFormValues
        }
    } else if(typeof formValues === "string"){
        return tryParseJSONObject(formValues)
    } else {
        return formValues
    }
}

/**
 *
 * @param jsonString
 */
function tryParseJSONObject (jsonString: string): boolean|unknown{
    try {
        const o = JSON.parse(jsonString);

        if (o && typeof o === "object") {
            return o;
        }
    }
    catch (e) { return jsonString}

    return jsonString;
}

const addHTMLString = (text: string): HTMLElement => {
    const div = document.createElement('div')
    div.innerHTML = text
    return div
}

async function addPage(pdf: jsPDF, newPage: HTMLElement) {

    // a4 height - margins
    const pageHeight = 1123 - 175;

    const htmlOptions: HTMLOptions = {
        autoPaging: 'text',
        margin: [100, 75, 75, 75]
    }

    // add page to the document to calculate height before adding to pdf
    newPage.id = `pdf-page`
    document.body.append(newPage)
    const addedElement = document.getElementById(newPage.id);

    // calculate heights and page info
    const elementHeight = addedElement?.offsetHeight;
    const pagesToAdd = Math.ceil((elementHeight ?? pageHeight) / pageHeight);
    const currentPageIndex = pdf.getNumberOfPages() - 1;

    // add pages before adding html to avoid error in adobe acrobat
    for (let j = 0; j < pagesToAdd;j++) {
        pdf.addPage();
    }
    pdf.setPage(0);

    // add the page and remove the element from the dom
    await pdf.html(newPage, {...htmlOptions, ...{y: (pageHeight * currentPageIndex)}});
    addedElement?.remove();

}

/**
 *
 * @param sigDoc
 * @param signatures
 * @param filename
 */
async function generatePdfContent(sigDoc: HTMLElement, signatures: string[], filename: string, otherPages: string[]){
    // Default export is a4 paper, portrait, using millimeters for units
    const options: jsPDFOptions = {
        unit: 'px',
        hotfixes: ["px_scaling"],
    }

    const pdf = new jsPDF(options);

    await addPage(pdf, sigDoc);

    for (let i = 1; i < otherPages.length; i++) {
        const newPage = document.createElement('html')

        newPage.style.width = "635px"
        newPage.style.fontSize = '14px'
        newPage.appendChild(addHTMLString(otherPages[i]))
        await addPage(pdf,  newPage);
    }
    const pdfBytes = pdf.output('arraybuffer')
    const pdfBuffer = await addSignatures(pdfBytes, signatures);
    const pdfHeader = await addHeaderFooter(pdfBuffer, filename);
    const blob = new Blob([pdfHeader], {type: 'application/pdf'});
    downloadFile(blob, `${filename}-SignatoryDocument.pdf`)
}

const downloadFile = (blob:Blob, fileName:string) => {
    const link = document.createElement('a');
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  };

async function addHeaderFooter(pdfBytes: ArrayBuffer, filename: string){
    const pdfDoc = await PDFDocument.load(pdfBytes);
    addText();
    const pdfBuffer = await pdfDoc.save({ useObjectStreams: false })
    return pdfBuffer;

    function addText() {
        const pageCount = pdfDoc.getPageCount();
        for(let i = 1; i <= pageCount; i++) {
            const currentPage = pdfDoc.getPage(i-1);
            const footer = config.appName + ' Service Agreement (JAN 2024)          Controlled Unclassified Information (CUI)            Pg. ' + String(i) + ' of ' + String(pageCount);
            if(i > 1){
                currentPage.setFontSize(9);
                currentPage.moveTo(200,803)
                currentPage.drawText('Controlled Unclassified Information (CUI)', {lineHeight: 0});
                currentPage.setFontSize(12);
                currentPage.moveTo(38,788)
                currentPage.drawText('SUBJECT: ICAM  Enterprise Network Services:', {lineHeight: 0});
                currentPage.moveTo(38,775)
                currentPage.drawText(`Parent ISLA# ${filename}`, {lineHeight: 0});
                currentPage.drawLine({
                    start: { x: 38, y: 772 },
                    end: { x: 540, y: 772 },
                    thickness: 1,
                    opacity: 0.5
                })
            }
            currentPage.setFontSize(9);
            currentPage.moveTo(75,30)
            currentPage.drawText(footer)
            currentPage.drawLine({
                start: { x: 70, y: 40 },
                end: { x: 525, y: 40 },
                thickness: 1,
                opacity: 0.5
            })
            
        }
    }
}

async function addSignatures(pdfBytes: ArrayBuffer, signatures: string[]) {
    const pdfDoc = await PDFDocument.load(pdfBytes);
    const lastPage = pdfDoc.getPage(pdfDoc.getPageCount()-1);
    lastPage.moveTo(260,750);
    lastPage.setFontSize(14);
    lastPage.drawText("Signature Page");
    lastPage.moveTo(58,720);
    lastPage.setFontSize(12);
    lastPage.drawText("DIGITAL Signature required below by: (1) “Service Partner’s Government” & (2)", {color: rgb(0,0,1)});
    lastPage.moveDown(20);
    lastPage.drawText("Countersigned by the PEO C3T, PM I2S, PdL E-ICAM, E-ICAM PO", {color: rgb(0,0,1)});
    lastPage.moveLeft(45);
    const form = pdfDoc.getForm()
    lastPage.setFontSize(14)
    for(const signature of signatures){
        lastPage.moveDown(20);
        lastPage.moveRight(PAD_LEFT);
        lastPage.drawText(signature);
        lastPage.moveLeft(PAD_LEFT);
        createTextField(signature, "Email");
        createTextField(signature, "Name");
        createTextField(signature, "Rank");
        createTextField(signature, "Phone");
        createTextField(signature, "Date");
        createSignatureField(signature);
    }
    const pdfBuffer = await pdfDoc.save({ useObjectStreams: false })
    return pdfBuffer

    function createSignatureField(signature: string) {
        lastPage.moveDown(LINE_HEIGHT);
        lastPage.moveRight(PAD_LEFT);
        const pagePos = lastPage.getPosition();
        // from https://github.com/Hopding/pdf-lib/issues/112
        const sigWidgetDict = pdfDoc.context.obj({
            Type: 'Annot',
            Subtype: 'Widget',
            FT: 'Sig',
            Rect: [pagePos.x , pagePos.y, pagePos.x + 225, pagePos.y + 20],
            Border: [0, 0, 0],
            T: PDFString.of(signature),
            P: lastPage.ref,
        });
        const sigWidgetDictRef = pdfDoc.context.register(sigWidgetDict);
        lastPage.node.Annots()?.push(sigWidgetDictRef);
        lastPage.moveLeft(PAD_LEFT);
        lastPage.moveDown(LINE_HEIGHT);
    }

    function createTextField(fieldPrefix: string, fieldName: string) {
        lastPage.moveDown(LINE_HEIGHT);
        lastPage.moveRight(PAD_LEFT);
        lastPage.drawText(fieldName + ":");
        lastPage.moveRight(TEXT_WIDTH);
        form.createTextField(fieldPrefix + "_" + fieldName).addToPage(lastPage, {
            x: lastPage.getX(),
            y: lastPage.getY(),
            width: TEXT_FIELD_WIDTH,
            height: TEXT_FIELD_HEIGHT,
            borderWidth: TEXT_FIELD_BORDER_WIDTH,
        });
        lastPage.moveLeft(TEXT_WIDTH + PAD_LEFT);
    }
}
