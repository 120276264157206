import React, { useEffect, useState } from "react";
import { AccountInfo } from "../types/UserInfoTypes";
import {Export} from '../components/Export';
import { isSuperUser } from "../actions/FlowableTaskActions";
import { Admin } from "../components/Admin";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import config from "../config";
import axios from "axios";
import { AppDefinitionQueryResult } from "../types/FlowableQueryResultsTypes";


export function SuperAdminDashboard(props:{accountInfo: AccountInfo}):JSX.Element{

    if (!isSuperUser(props.accountInfo)) {
        return <div>Invalid Permissions to Access this Dashboard</div>;
    }
  const history = useHistory();
  const [fieldTesting, setFieldTesting ] = useState("")

  useEffect(()=>{
    async function doAsync(){
        try{
          const appDefinition = ((await (axios.get(`${config.flowableUiApiBaseUrl}app/rest/runtime/app-definitions`, { withCredentials: true })))
          .data.data.find((a:AppDefinitionQueryResult) => a.appDefinitionKey === 'fieldTesting'))
          setFieldTesting(appDefinition);
        } catch(e){
          console.error(e);
        }
      }
      doAsync()
    }, [props.accountInfo])

  return (
    <div style={{width:"100%", display:"flex"}}>
        <div style={{backgroundColor:"white", width: "100%", overflow:"hidden", marginTop: 20 }} >
          
          <h1 style={{paddingLeft:"60px", paddingTop:'15px'}}>Super Admin Dashboard</h1>
      <div style={{backgroundColor:"white", display: 'flex', height: '700px', width:"98%", margin:"30px"}}>
          <div  style={{ flexGrow: 1}}>
                  <div><Export /></div>
                  <div style={{marginTop: 20}}> <Button color="primary" variant="contained" onClick = {() => {history.push('/migrate')}}>
                      Migrate
                  </Button>
                  </div>
                  <div style={{marginTop: 20}}><Admin /></div>
                  {fieldTesting != null? 
                  <div style={{marginTop: 20}}> <Button color="primary" variant="contained" onClick = {() => {history.push('fieldTesting')}}>
                      All Fields Form
                  </Button>
                  </div> : ""}
          </div>
      </div></div>
    </div>
  )
}