import React, { useState } from "react";
import { AccountInfo } from "../types/UserInfoTypes";
import { DashboardDrawer, MenuOption } from "./DashboardDrawer";
import { ProcessView } from "./ProcessView";
import { QueueView } from "./QueueView";


export function NewDashboard(props:{accountInfo: AccountInfo}):JSX.Element{
  const [menuOption, setMenuOption] = useState<MenuOption>("Processes")
  return (
    <div style={{width:"100%", display:"flex"}}> 
    <div style={{overflow:"auto"}}>
        <DashboardDrawer
          onSelectOption={(selectedOpt)=>setMenuOption(selectedOpt)} 
        selectedOption={menuOption}
        accountInfo={props.accountInfo}
      />
    </div>
        <div style={{backgroundColor:"white", width: "100%", overflow:"hidden"}} >
          
          <h1 style={{paddingLeft:"60px", paddingTop:'15px'}}>{menuOption}</h1>
      <div style={{backgroundColor:"white", display: 'flex', height: '700px', width:"98%", margin:"30px"}}>
          <div  style={{ flexGrow: 1}}>
            <DashboardDetailPane menuOption={menuOption} accountInfo={props.accountInfo} />
          </div>
      </div></div>
    </div>
  )
}

function DashboardDetailPane(props:{menuOption: MenuOption, accountInfo:AccountInfo}){
  switch(props.menuOption){
    case "Processes":
      return <ProcessView accountInfo={props.accountInfo}></ProcessView>
    case "Queue":
      return <QueueView accountInfo={props.accountInfo}></QueueView>
    default:
      return <></>
  }
}