import {
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Table,
    TableContainer,
    TextField
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../config";
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MuiAlert from '@material-ui/lab/Alert';
import {ProcessInstanceQueryResult, ProcessDefinitionQueryResult, TaskQueryResult} from "../types/FlowableQueryResultsTypes";
import { requestAll } from "../dashboard/SAReqDash";
import _ from "lodash";

const useStyles = makeStyles({
    stickyHeader: {
      position: "sticky",
      left: 0,
      top:0,
      backgroundColor: "white",
    }
  });

export const Migrate = (): JSX.Element => {
    const [loading, setLoading] = useState<boolean>(false);
    const [processInstances, setProcessInstances] = useState<ProcessInstanceQueryResult[]>([]);
    const [filterText, setFilterText] = useState<string>('');
    const [selectedProcessDefinition, setSelectedProcessDefinition] = useState<string|null|unknown>(null);
    const [newProcessDefinition, setNewProcessDefinition] = useState<string|null|unknown>(null);
    const [tasks, setTasks] = useState<TaskQueryResult[]>([]);
    const [status, setStatus] = useState<string|null|unknown>(null);
    const [selectedVersion, setSelectedVersion] = useState<number|null|unknown>(null);
    const [targetVersion, setTargetVersion] = useState<number|null|unknown>(null);
    const [processDefinitions, setProcessDefinitions] = useState<ProcessDefinitionQueryResult[]>([]);
    const [selectedProcessInstances, setSelectedProcessInstances] = useState<string[]>([]);
    const [successes, setSuccesses] = useState<string[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const classes = useStyles();

    const processes: string[] = processDefinitions.map((processDefinition) => {
        return processDefinition.name;
    }).filter((processDefinition, index, names) => {
        return names.indexOf(processDefinition) === index;
    }).sort();

    const selectedProcessDefinitionKey = processDefinitions.filter(p => p.name === selectedProcessDefinition).map((processDefinition) => {
        return processDefinition.key;
    }).filter((value, index) => {
        const result = JSON.stringify(value);
        return index === processDefinitions.filter(p => p.name === selectedProcessDefinition).map((processDefinition) => {
            return processDefinition.key;
        }).findIndex(obj => {
          return JSON.stringify(obj) === result;
        });
      });

    const potentialProcesses: string [] = processDefinitions.filter(p => p.key === selectedProcessDefinitionKey[0]).map((processDefinition) => {
        return processDefinition.name;
    }).filter((processDefinition, index, names) => {
        return names.indexOf(processDefinition) === index;
    }).sort();

    const versions: number[] = processDefinitions.filter((processDefinition) => {
        return processDefinition.name === selectedProcessDefinition;
    }).map((processDefinition) => {
        return processDefinition.version;
    }).sort((a, b) => {
        return b - a;
    });

    const targetVersions: number[] = processDefinitions.filter((processDefinition) => {
        return processDefinition.name === newProcessDefinition;
    }).map((processDefinition) => {
        return processDefinition.version;
    }).sort((a, b) => {
        return b - a;
    });

    const statuses: string[] = [...tasks.map((t) => { return t.name}).filter((item, i, arr) => arr.indexOf(item) === i),"No Action Needed", "Completed"].sort();

    useEffect(() => {
        loadProcessDefinitions();
    }, []);

    useEffect(() => {
        if (selectedProcessInstances.length > 0 && ((successes.length + errors.length) === selectedProcessInstances.length)) {
            setSelectedProcessInstances([]);
        }
    }, [successes, errors]);

    useEffect(() => {
        loadProcessInstance();
        setSelectedProcessInstances([]);
        setSelectedVersion(null);
        setStatus(null);
        setTargetVersion(null);
        setSuccesses([]);
        setErrors([]);
    }, [selectedProcessDefinition]);

    useEffect(() => {
        setTargetVersion(targetVersions[0]);
    }, [newProcessDefinition])
    const setDefault = () => {
        setSelectedVersion(null);
        setSelectedProcessDefinition("ISLA Request");
        setNewProcessDefinition('ISLA Request');
        setTargetVersion(targetVersions[0]);
    };

    const dismissErrors = () => {
        setSuccesses([]);
        setErrors([]);
    };

    const loadProcessDefinitions = () => {
        axios.get(`${config.flowableUiApiBaseUrl}app/rest/process-definitions?size=1000000`, {withCredentials: true, responseType: 'json'}).then((response) => {
            setProcessDefinitions(response.data ? response.data.data : []);
            setDefault();
        })

        return () => {
            setProcessDefinitions([]);
        }
    }

    const loadProcessInstance = () => {
        setLoading(true);
        requestAll(`${config.flowableUiApiBaseUrl}app/rest/query/admin/process-instances`, {"state":"running","processVariableLike":{},"includeProcessVariables":false, "size": 50}).then((response) => {
            requestAll(`${config.flowableUiApiBaseUrl}app/rest/query/tasks`, {
                    processInstanceIdsIn: undefined,
                    state: "running",
                    includeIdentityLinks: true,
                    size: 1000
                  }).then((response) => {
                      setTasks(response as TaskQueryResult[]);
                      setLoading(false);
                  });
            setProcessInstances(response as ProcessInstanceQueryResult[]);
        })
    };

    const renderProcessInstances = () => {

        if (! selectedProcessDefinition) {
            return null;
        }

        const filteredProcessInstances = processInstances.filter((processInstance) => {
            return (status ? getStatus(processInstance) === status : true) && selectedProcessDefinition === processInstance.processDefinitionName && (selectedVersion ? processInstance.processDefinitionVersion === selectedVersion : true) && (filterText ? processInstance.businessKey.toLowerCase().includes(filterText) : true);
        })

        if (filteredProcessInstances.length === 0) {
            return <div style={{marginTop: 20, marginBottom: 20}}>No matching process instances.</div>
        }

      return (
        <TableContainer style={{maxHeight:"370px", marginTop: 30, marginBottom: 30}}>
            <Table stickyHeader
            aria-label="simple table"
            style={{ tableLayout: "fixed" }}>
                <TableHead>
                    <TableRow>
                        <th align="left" className={classes.stickyHeader} style={{width: '50px'}}><div style={{backgroundColor:"white"}}><Checkbox
                                checked={selectedProcessInstances.length === filteredProcessInstances.length}
                                onChange={() => {
                                    selectedProcessInstances.length === filteredProcessInstances.length ? setSelectedProcessInstances([]) : setSelectedProcessInstances([...filteredProcessInstances.map(e => e.id)])
                                }}
                                name={`select-all`}
                            /></div></th>
                        <th align="left" style={{ width: '150px', maxWidth: '150px', paddingLeft: '5px' }} className={classes.stickyHeader}>Process</th>
                        <th align="left" style={{ paddingLeft: '10px' }} className={classes.stickyHeader}>Key</th>
                        <th align="left" style={{ paddingLeft: '10px'}} className={classes.stickyHeader}>Name</th>
                        <th align="left" style={{ paddingLeft: '10px', width: '300px', maxWidth: '300px'}} className={classes.stickyHeader}>Status</th>
                        <th align="left" style={{ paddingLeft: '10px', width: '100px'}} className={classes.stickyHeader}>Version</th>
                        <th align="left" style={{ width: '120px', paddingLeft: '10px' }} className={classes.stickyHeader}>Started By</th>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredProcessInstances.map((processInstance) => (
                        <TableRow key={processInstance.id}>
                            <td><Checkbox
                                checked={selectedProcessInstances.includes(processInstance.id)}
                                onChange={e => {
                                    e.target.checked ? setSelectedProcessInstances([...selectedProcessInstances, processInstance.id]) : setSelectedProcessInstances(selectedProcessInstances.filter((v) => (v !== processInstance.id)))
                                }}
                                name={`process-instance-${processInstance.id}`}
                            /></td>
                            <td style={{ width: '150px', paddingLeft: '5px' }}>{processInstance.processDefinitionName}</td>
                            <td style={{ paddingLeft: '10px' }}>{processInstance.businessKey}</td>
                            <td style={{ paddingLeft: '10px'}}>{processInstance.name}</td>
                            <td style={{ maxWidth: '300px', paddingLeft: '10px'}}>{getStatus(processInstance)}</td>
                            <td style={{paddingLeft: '10px'}}>{processInstance.processDefinitionVersion}</td>
                            <td style={{ width: '120px', paddingLeft: '5px' }}>{processInstance.startedBy ? `${processInstance.startedBy.firstName} ${processInstance.startedBy.lastName}` : `-`}</td>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
      );
    };

    const renderResults = (type: 'success'|'error') => {
        const toShow = type === 'success' ? successes : errors;
        return toShow.map((result, index) => {
                const process = result.split(':')[0];
                const errorResponse = result.split(':')[1];
                const handleClose = () => {
                    if (type === 'success') {
                        setSuccesses(successes.filter((s) => s !== result));
                    } else {
                        setErrors(errors.filter((s) => s !== result))
                    }

                };

                const processInstance = processInstances.find((p) => p.id === process);

                if (!processInstance) {
                    return null;
                }
                return (<div key={`result-${result}`}>
                            <MuiAlert elevation={6} variant="filled"  onClose={handleClose} severity={type} style={{marginTop: "10px"}}>
                                {processInstance.businessKey ? processInstance.businessKey + (type === 'error' ? ": " + errorResponse : "") : processInstance.name} 
                            </MuiAlert>
                            <span style={{float: "right", marginBottom:"10px", marginTop: "10px"}}>
                            {index === toShow.length - 1 ? <Button color="primary" variant="contained" style={{ height: "40px"}} onClick={dismissErrors}>DISMISS ALL</Button> : ""}
                            </span>
                        </div>
                );

            });
    };

    return (
        <>
            <div style={{width:"100%", height: "100%"}}>
        <div style={{backgroundColor:"white", width: "100%", height: "100%", marginTop:40 }} >
          <h1 style={{paddingLeft:"60px", paddingTop:'15px'}}>Super Admin Dashboard</h1>
      <div style={{backgroundColor:"white", height: "100%", width:"95%", margin:"30px"}}>
          <div  style={{ flexGrow: 1, height: "100%"}}>
                {loading ? <CircularProgress id={"migrateLoader"}/> : <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <FormControl style={{width: '100%'}}>
                            <InputLabel id="process">Select Process to Migrate</InputLabel>
                            <Select
                                labelId="process"
                                id="process"
                                value={selectedProcessDefinition ?? ''}
                                onChange={(e) => {
                                    setSelectedProcessDefinition(e.target.value);
                                    setNewProcessDefinition(e.target.value);
                                }}
                            >
                                <MenuItem key={`process-none`} value={''}>Choose Process</MenuItem>
                                {processes.map((process) => {
                                    return (
                                        <MenuItem key={`process-${process}`} value={process}>{process}</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                        <Grid item xs={2}>
                            <FormControl style={{width: '100%'}}>
                                <InputLabel id="version">Select Process Version</InputLabel>
                                <Select
                                    labelId="version"
                                    id="version"
                                    value={selectedVersion ?? ''}
                                    onChange={(e) => {
                                        setSelectedVersion(e.target.value);
                                    }}
                                >
                                    <MenuItem key={`version-none`} value={''}>Choose Version</MenuItem>
                                    {versions.map((version) => {
                                        return (
                                            <MenuItem key={`version-${version}`} value={version}>{version}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl></Grid>
                            <Grid item xs={2}>
                            <FormControl style={{width: '100%'}}>
                                <InputLabel id="status">Status</InputLabel>
                                <Select
                                    labelId="status"
                                    id="status"
                                    value={status ?? ''}
                                    onChange={(e) => {
                                        setStatus(e.target.value);
                                    }}
                                >
                                    <MenuItem key={`status-none`} value={''}>Choose Status</MenuItem>
                                    {statuses.map((s) => {
                                        return (
                                            <MenuItem key={`version-${s}`} value={s}>{s}</MenuItem>
                                        );}
                                    )}
                                </Select>
                            </FormControl></Grid>
                            <Grid item xs={3}>
                                <FormControl style={{width: '100%'}}>
                                    <InputLabel id="newprocess">Select New Process</InputLabel>
                                    <Select
                                        labelId="newprocess"
                                        id="newprocess"
                                        value={newProcessDefinition ?? ''}
                                        onChange={(e) => {
                                            setNewProcessDefinition(e.target.value);
                                        }}
                                    >
                                        <MenuItem key={`newprocess-none`} value={''}>Choose Process</MenuItem>
                                        {potentialProcesses.map((process) => {
                                            return (
                                                <MenuItem key={`newprocess-${process}`} value={process}>{process}</MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl></Grid>
                        <Grid item xs={2}>
                            <FormControl style={{width: '100%'}}>
                                <InputLabel id="targetVersion">Select Target Version</InputLabel>
                                <Select
                                    labelId="targetVersion"
                                    id="targetVersion"
                                    value={targetVersion ?? ''}
                                    onChange={(e) => {
                                        setTargetVersion(e.target.value);
                                    }}
                                >
                                    <MenuItem key={`version-none`} value={''}>Choose Version</MenuItem>
                                    {targetVersions.map((version) => {
                                        return (
                                            <MenuItem key={`version-${version}`} value={version}>{version}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl></Grid>
                        <Grid item xs={12}>
                            <FormControl style={{width: '100%'}}>
                                <TextField
                                    id="filter"
                                    label="Filter By Key"
                                    value={filterText}
                                    onChange={e => setFilterText(e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                </Grid>
                }
                <div>
                    {renderResults('success')}
                    {renderResults('error')}
                </div>
                <div>{renderProcessInstances()}</div>

                {selectedProcessDefinition && targetVersion && (
                <div>
                    <span style={{float: "right", marginBottom:"10px"}}>
            <Button variant="contained" onClick={setDefault} style={{ marginRight: "7px" }}>
              Reset
            </Button>
            <Button color="primary" variant="contained"
                    onClick={
                        () => {
                            const targetDefinition = processDefinitions.find((processDefinition) => {
                                return processDefinition.version === targetVersion && processDefinition.name === newProcessDefinition;
                            });

                            if (targetDefinition) {
                                setErrors([]);
                                setSuccesses([]);

                                selectedProcessInstances.forEach(async (processInstance) => {
                                    const response = await axios.post(`${config.flowableUiApiBaseUrl}admin-app/rest/admin/process-instances/${processInstance}/migrate`, {"activityMappings":[],"toProcessDefinitionId":targetDefinition.id}, {withCredentials: true, responseType: 'json'}).catch((response) => {  
                                        const errorResponse = processInstance + ": " + response.response.data.message;                           
                                        setErrors((errors) => {
                                            return [...errors, errorResponse]
                                        });
                                    });

                                    if (response) {
                                        if (response.status === 200) {
                                            setSuccesses((successes) => {
                                                return [...successes, processInstance]
                                            });
                                            loadProcessInstance();
                                        } else {
                                            setErrors((errors) => {
                                                const errorResponse = processInstance + ": " + response.data.message;   
                                                return [...errors, errorResponse]
                                            });
                                        }
                                    }
                                });
                            }

                        }
                    }>
                        Migrate
            </Button>
            </span>
                    </div>
                    
                )}

        </div>
      </div></div>
    </div>
            </>
    );
    function getStatus(processInstance: ProcessInstanceQueryResult) {
    const openTasks = tasks.filter((task: TaskQueryResult) => processInstance.id === task.processInstanceId);
    const mostRecentOpen = _.last(_.sortBy(openTasks, t => t.created)) ?? null
        if(processInstance.ended){
        return "Completed"
        } else if (mostRecentOpen) {
        //return "testing"
        return openTasks.map(t => t.name).join(",");
        } else {
        return "No Action Needed";
        }
    }
};