import axios from "axios";
import config from "../config";
import {FlowableFormObject, FileDetails, ProcessDefinition} from "../types/FlowableFormTypes";
import {AccountInfo} from "../types/UserInfoTypes";
import {ProcessInstanceFilters} from "../dashboard/ProcessInstanceFilter";
import {isSuperUser} from "../actions/FlowableTaskActions";
import _ from "lodash";
import {ProcessInstanceQueryResult} from "../types/FlowableQueryResultsTypes";
import {useLocation} from "react-router-dom";


export const PROCESS_INSTANCE_ID_QS_KEY = "processInstanceId"
export const CLAIM_QS_KEY = "claim"

export function useQuery() : URLSearchParams{
    return new URLSearchParams(useLocation().search);
}

export async function getProcessDefinition(): Promise<ProcessDefinition> {
    const processDefinitions = await axios.get(`${config.flowableUiApiBaseUrl}app/rest/process-definitions?latest=true`, { withCredentials: true })
    return processDefinitions.data.data.find((processDef: Record<string, unknown>) => processDef.key === "siaTest");
}

export async function getProcessDefinitionFieldTesting(): Promise<ProcessDefinition> {
    const processDefinitions = await axios.get(`${config.flowableUiApiBaseUrl}app/rest/process-definitions?latest=true`, { withCredentials: true })
    return processDefinitions.data.data.find((processDef: Record<string, unknown>) => processDef.key === "field_all");
}

export async function getStartForm(processDefinitionId:string): Promise<FlowableFormObject>{
    return (await axios.get(`${config.flowableUiApiBaseUrl}app/rest/process-definitions/${processDefinitionId}/start-form`, {withCredentials:true})).data
}

export async function getFormObject(taskId: string|null, setFormObject: (form:FlowableFormObject|undefined)=>void) : Promise<void> {
    if(!taskId){
        setFormObject(undefined)
    } else{
        const taskFormResponse = await axios.get(`${config.flowableUiApiBaseUrl}app/rest/task-forms/${taskId}`, { withCredentials: true })
        setFormObject(taskFormResponse.data)
    }
}

export async function uploadFormFile(file: File, taskId: string|null): Promise<string> {
    const fd = new FormData();
    fd.append('file',file)
    const fileUpload = await axios.post(`${config.flowableUiApiBaseUrl}app/rest/content/${taskId}/raw`,
        fd,
        {withCredentials:true,
            headers:{"Content-Type":"multipart/form-data"}})
    return fileUpload.data.id
}

export async function getFileDescription(fileId: string): Promise<FileDetails> {
    return (await axios.get(`${config.flowableUiApiBaseUrl}app/rest/content/${fileId}`, {withCredentials:true})).data
}

export async function startProcessInstance(processDefinitionId: string, startFormId: string|undefined, startFormState: Record<string, unknown>|undefined) : Promise<string> {
    const processInstance = axios.post(`${config.flowableUiApiBaseUrl}app/rest/process-instances`,
        {
            processDefinitionId: processDefinitionId,
            name: config.appName + " Request - " + new Date().toISOString(),
            formId: startFormId,
            values: startFormState
        },
        { withCredentials: true })
    return (await processInstance).data.id
}

export async function getProcesses(accountInfo: AccountInfo, processInstanceFilters?:ProcessInstanceFilters): Promise<ProcessInstanceQueryResult[]> {
    const processUrl = isSuperUser(accountInfo) ? `${config.flowableUiApiBaseUrl}app/rest/query/admin/process-instances` : `${config.flowableUiApiBaseUrl}app/rest/query/process-instances`
    let queryParams:Record<string, unknown> = {
        state: "all",
        size:1000000,
    }
    queryParams = {...queryParams, ..._.pickBy(processInstanceFilters, _.identity)}
    queryParams = {...queryParams, ...{startedBy: processInstanceFilters?.startedBy?.id}}
    const processInstanceResults = await axios.post(processUrl,
        queryParams,
        { withCredentials: true })
    return processInstanceResults.data.data as ProcessInstanceQueryResult[]
}