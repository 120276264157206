import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { AccountInfo } from "../types/UserInfoTypes";
import {ProcessInstanceQueryResult, SubscriptionQueryResult, TaskQueryResult} from "../types/FlowableQueryResultsTypes";
import { TaskList } from "./TaskList";
import {ActionsCard} from "../components/ActionsCard";
import axios from "axios";
import config from "../config";

export function ProcessDetailsView(props:{row: unknown, processInstance:ProcessInstanceQueryResult, accountInfo: AccountInfo}): JSX.Element{
  const [loading, setLoading] = useState<boolean>(false)
  const [openTasks, setOpenTasks] = useState<TaskQueryResult[]>([])
  const [completedTasks, setCompletedTasks] = useState<TaskQueryResult[]>([])
  const [candidateTasks, setCandidateTasks] = useState<TaskQueryResult[]>([])
  const [adHocTasks, setAdHocTasks] = useState<TaskQueryResult[]>([])
  const [subscriptions, setSubscriptions] = useState<SubscriptionQueryResult[]>([])

  async function loadData(){
    setLoading(true)

    const subProcesses: ProcessInstanceQueryResult[] = (await axios.post(`${config.flowableUiApiBaseUrl}app/rest/query/process-instances`, {
      superProcessInstanceId: props.processInstance.id,
      size:1000000,
      sort: "created-asc",
      state: "all",
      includeProcessInstance: true
    }, { withCredentials: true })).data.data

    const subProcessIds: string[] = subProcesses.map((subProcess: ProcessInstanceQueryResult) => {
      return subProcess.id;
    });

    const processIds: string = [...subProcessIds, props.processInstance.id].join(',');

    try{
      const openTasksFut = getOpenTasks(processIds)
      const completedTasksFut = getCompletedTasks(processIds)
      const candidateTasksFut = getCandidateTasks(processIds)
      const adHocTasksFut = getAdHocTasks(processIds)
      const subscriptionsFut = getSubscriptions(props.processInstance.id)
      setOpenTasks(await openTasksFut)
      setCompletedTasks(await completedTasksFut)
      setCandidateTasks(await candidateTasksFut)
      setAdHocTasks(await adHocTasksFut)
      setSubscriptions(await subscriptionsFut)
    } catch(e){
      console.error(e)
    } finally{
      setLoading(false)
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  if(loading){
    return <CircularProgress id={"newDashboardLoader"}/>
  }
  return <div>
    <div style={{float:'left', width:'65%', marginRight:'10px'}}>
      <TaskList 
        processInstance={props.processInstance}
        accountInfo={props.accountInfo}
        completedTasks={completedTasks}
        candidateTasks={candidateTasks}
        adHocTasks={adHocTasks}
        openTasks={openTasks}
        loadData={loadData}
        />
    </div>

    <div style={{float:'right', width: '30%'}}>
      <ActionsCard
        processInstance={props.processInstance}
        accountInfo={props.accountInfo}
        completedTasks={completedTasks}
        subscriptions={subscriptions}
      />
    </div>
    </div>
}


async function getOpenTasks(processInstanceId: string):Promise<TaskQueryResult[]>{
  return (await axios.post(`${config.flowableUiApiBaseUrl}app/rest/query/tasks`,
  {
    processInstanceIdsIn:processInstanceId,
    state: "running",
  }, {withCredentials:true})).data.data as TaskQueryResult[]
}

async function getCompletedTasks(processInstanceId: string): Promise<TaskQueryResult[]>{
  return (await axios.post(`${config.flowableUiApiBaseUrl}app/rest/query/tasks`,
  {
    processInstanceIdsIn:processInstanceId,
    state: "completed",
    sort: "created-desc"
  }, {withCredentials:true})).data.data as TaskQueryResult[]
}

async function getCandidateTasks(processInstanceId: string): Promise<TaskQueryResult[]>{
  return (await axios.post(`${config.flowableUiApiBaseUrl}app/rest/query/tasks`,
  {
    processInstanceIdsIn:processInstanceId,
    state: "running",
    assignment:"candidate",
  }, {withCredentials:true})).data.data as TaskQueryResult[]
}

async function getAdHocTasks(processInstanceId: string): Promise<TaskQueryResult[]>{
  return (await axios.get(`${config.flowableUiApiBaseUrl}app/rest/adHocTasks/${processInstanceId}`, { withCredentials: true })).data;
}

async function getSubscriptions(processInstanceId: string): Promise<SubscriptionQueryResult[]>{
  return (await axios.get(`${config.flowableUiApiBaseUrl}app/rest/process-instances/${processInstanceId}/subscriptions`, {withCredentials:true})).data.filter((subscription: SubscriptionQueryResult) => {
    return subscription.eventName !== 'Update Service Details';
  }) as SubscriptionQueryResult[]
}
