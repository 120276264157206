import _ from "lodash";
import {FormState} from "../types/FlowableFormTypes";
import {compileExpression, Options} from "filtrex";


export function getFormStateWithStringifiedObjects(formState: Record<string, unknown>) : FormState{
    const newFormState = { ...formState };
    for (const [key, val] of Object.entries(newFormState)) {
        if (_.isObject(val) || _.isArray(val)) {
            newFormState[key] = JSON.stringify(val);
        }
    }
    return newFormState
}

export function getMaxCharacters(expr: string|null|undefined) : number{
        if(expr?.includes("maxCharacters")){
            const expressionParts = expr.split(",");
            if(expressionParts.length === 2){
                return parseInt(expressionParts[1].trim());
            }
        }
        return 0
}

export function getExpressionFunc(expr:string|null|undefined, defaultValue: boolean): (vars: FormState) => boolean{
    let validationFunc:(vars: FormState) => boolean  = () => defaultValue;

    function date(dateValue: string)  {
        return Date.parse(dateValue);
    }

    function strlen(stringValue: string) {
        return stringValue.length;
    }

    function maxCharacters(stringValue: string, characterLimit: number) {
        return stringValue.length <= characterLimit;
    }

    if (expr) {
        try {
            validationFunc = compileExpression(expr, {extraFunctions: {date, strlen, maxCharacters}} as Options) as (vars: FormState) => boolean;
        } catch (e) {
            console.error("Error compiling expression " + expr, e);
        }
    }
    return validationFunc;
}