import axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import config from "../config";
import { PROCESS_INSTANCE_ID_QS_KEY, useQuery } from "../actions/FlowableFormActions";
import { FileUpload } from "../components/FileUpload";

export function AboutISA():JSX.Element{
  const query = useQuery()
  const processInstanceId = query.get(PROCESS_INSTANCE_ID_QS_KEY)
  const [variables, setVariables] = useState<AboutVariables|null>(null)
  const [objectUrl, setObjectUrl] = useState<string|null>(null)

  useEffect(()=>{
    async function doAsync(){
      const processInstance = (await axios.get(`${config.flowableUiApiBaseUrl}app/rest/process-instances/${processInstanceId}?includeProcessVariables=true`, {withCredentials:true})).data
      setVariables(processInstance.processVariables)
      const blob = (await axios.get(`${config.flowableUiApiBaseUrl}app/rest/content/${processInstance.processVariables.diagram}/raw`, {withCredentials:true, responseType:'blob'})).data
      setObjectUrl(URL.createObjectURL(blob))
    }
    doAsync()
  },[processInstanceId])
  if(!variables){
    return <></>
  }
  const linksObj = JSON.parse(variables.linksModal)
  const linkElems:JSX.Element[] = []
  for(const link of linksObj as LinkObject[]){
    linkElems.push( <li><a href={link.linkUrl} target="_blank" rel="noopener noreferrer">{link.linkText}</a></li>)
  }
  return(
  <div style={{backgroundColor:"white", color:"black",  padding:"50px", marginTop: '50px', marginRight: '10%', marginLeft:'10%'}}>
    <h1>About {config.appName}</h1>
    {
      objectUrl ?
      <img src={objectUrl} ></img> : <></>
    }
    <h2>Helpful Links</h2>
    <ul>{linkElems}</ul>
    {!_.isEmpty(variables.documents) ? <><h2>Documents</h2>
     <FileUpload
        readonly={true}
        fileIds={variables.documents.split(",")}
        acceptedFileTypes={[]}
        multiple={true}
        onFileAdd={()=>{return}}
        onFileRemove={()=>{return}}
        taskId={null}
    ></FileUpload></> : <></>}
  </div>
  )
}

type LinkObject = {
  linkText: string
  linkUrl: string
}

type AboutVariables = {
  linksModal: string
  diagram:string
  documents: string
}
