import {FileDetails, FlowableFormObject, FormFieldType, FormState, WizardSection} from "../types/FlowableFormTypes";
import React, {useState} from "react";
import {flatten} from "flat";
import _ from "lodash";
import {ConfirmationModal} from "./ConfirmationModal";
import {FormModal} from "./FormModal";
import {SummaryTable} from "./SummaryTable";
import {Button, Snackbar, Step, StepLabel, Stepper, Typography} from "@material-ui/core";
import SaveSharpIcon from "@material-ui/icons/SaveSharp";
import CloseIcon from "@material-ui/icons/Close";
import {Alert} from "@material-ui/lab";
import {fieldValidationError } from "./ISARequest";
import {FileDescription} from "../components/FileUpload";
import {getExpressionFunc} from "./FormUtils";
import {renderField} from "./FieldRenderer";

/**
 * Form container for FlowableForms that are a part of the larger task. Controls when forms should be displayed, and which fields should be a part of the form.
 * @param props
 * @constructor
 */
export function FlowableForm(props: { formObject: FlowableFormObject,
    onTaskComplete?: ((outcome: string|null, formState: Record<string, unknown>)=> Promise<void>),
    onTaskSave?: ((formState: Record<string, unknown>)=>Promise<void>), processInstanceId:string|null,
    onTaskCancel?: ((formState: Record<string, unknown>)=>Promise<void>),taskId: string|null,
    requestType:string|null|unknown}) : JSX.Element {
    const renderedFields = []
    let modalFields = []

    const [formState, setFormState] = useState<FormState>(getFormStateFromFormObject(props.formObject));
    let modalVisible = false
    let modalButtonText = null
    let modalTableName = ''
    let modalTableColumns: {[key:string]:string} = {}
    let modalRequired = false

    const [activeStep, setActiveStep] = useState(0)
    const [saveToastOpen, setSaveToastOpen] = useState(false)
    const [summaryLoaded, setSummaryLoaded] = useState(true)
    let missingRequiredField = !summaryLoaded
    let hasModifiableField = false

    let wizardSections: WizardSection[] = []

    const handleModalSubmit = (tableName: string, table: FormState[]) => {
        const newFormState = { ...formState }
        newFormState[tableName] = table.length > 0 ? table : null
        setFormState(newFormState)
    };
    for (const field of props.formObject.fields) {
        const visibilityFunc = getExpressionFunc(field.params?.visibilityExpression, true)
        const isVisible = visibilityFunc(flatten(formState, {safe:true}))
        if(field.type === "wizard-section"){
            wizardSections.push({title:field.name, fields:[], missingRequiredFields: false, visible: isVisible})
        }
        else if (field.type === "modal-section"){
            const currentSection = _.last(wizardSections)
            if (modalFields.length > 0){
                if(modalVisible){
                    (currentSection ? currentSection.fields : renderedFields).push(
                        <FormModal formState={formState}
                                   required={modalRequired}
                                   modalFields={modalFields}
                                   modalTableName={modalTableName}
                                   modalButtonText={modalButtonText}
                                   modalTableColumns={modalTableColumns}
                                   handleModalSubmit={handleModalSubmit}
                                   processInstanceId={props.processInstanceId}
                                   addToFormState={addToFormState}
                                   disabled={field.readOnly}
                                   taskId={props.taskId}
                                   requestType={props.requestType}
                        />
                    )
                }
                modalVisible = false
                modalButtonText = null;
                modalFields = [];
                modalTableColumns = {}
                modalRequired = false
            }
            else{
                modalVisible = isVisible
                modalButtonText = field.name
                modalTableName = field.id
                if (fieldValidationError(field, formState) && isVisible){
                    if (currentSection){
                        currentSection.missingRequiredFields = true
                    }
                    missingRequiredField = true
                    modalRequired = true
                }
            }
        }
        else if (field.type === "summary-section") {
            let summarySection
            if (!props.processInstanceId) {
                summarySection = <div>Error rendering summary table</div>
            } else {
                const expression = field.expression?.replace(`$`,'').replace('}', '').replace('{', '');
                const value = field.value as string | {[key:string]:unknown}
                if (typeof(value) === 'object'){
                    summarySection = <SummaryTable priorityFields={field.value as Record<string, unknown>} expression={expression} processInstanceId={props.processInstanceId} onLoadTableFields={rows => {
                        if(_.isEmpty(rows)){
                            setSummaryLoaded(false)
                        } else{
                            setSummaryLoaded(true)
                        }
                    }} />
                } else {
                    summarySection = <div>{value}</div>
                }
            }
            const currentSection = _.last(wizardSections)
            if (currentSection) {
                currentSection.fields.push(summarySection)
            } else {
                renderedFields.push(summarySection)
            }
        } else {
            const readOnlyFields: FormFieldType[] = ['expression', 'hyperlink', 'horizontal-line', 'pdf-section', 'headline', 'headline-with-line', 'spacer']
            if (readOnlyFields.indexOf(field.type) == -1 && !field.readOnly){
                hasModifiableField = true
            }
            if (modalButtonText){
                modalFields.push(field)
                modalTableColumns[field.id] = field.name

                // this is required
                // so the complete button becomes available, but does not submit because the required fields in the non-required modal aren't set
                formState[field.id] = ''

            } else {
                const currentSection = _.last(wizardSections)
                if(currentSection){
                    if (fieldValidationError(field, formState) && isVisible){
                        currentSection.missingRequiredFields = true
                        if(currentSection.visible){ // only block completion if required field is in visible wizard section
                            missingRequiredField = true

                        }
                    }
                    currentSection.fields.push(renderField(field, props.formObject.fields, formState, setFormState, addToFormState, props.taskId))
                }
            }
        }
        if(wizardSections.length === 0 && !modalButtonText && field.type !== 'modal-section' && field.type !== 'summary-section'){
            renderedFields.push(renderField(field, props.formObject.fields, formState, setFormState, addToFormState, props.taskId))
            if (fieldValidationError(field, formState) && isVisible){
                missingRequiredField = true
            }
        }
    }
    wizardSections = wizardSections.filter(ws => ws.visible)
    const formButtons: JSX.Element[] = []
    if(wizardSections.length > 0 && activeStep !== 0){
        formButtons.push(
            <Button variant={'outlined'} style={{float:'left'}} onClick={() => setActiveStep(activeStep - 1)}>{"< Previous"}</Button>
        )
    }
    if(wizardSections.length > 0 && activeStep !== wizardSections.length - 1){
        formButtons.push(
            <Button variant={'outlined'} style={{float:'right'}} onClick={() => setActiveStep(activeStep + 1)} disabled={wizardSections[activeStep].missingRequiredFields}>{"Next >"}</Button>
        )
    }
    if((wizardSections.length == 0) || activeStep === wizardSections.length - 1){
        if(props.formObject.outcomes.length > 0){
            formButtons.push(
                ...props.formObject.outcomes.map((outcome: {id: string; name: string;}, outcomeIndex: number) =>
                 outcome.name === 'Delete' ? (
                    <ConfirmationModal
                        key={outcome.name}
                        name={outcome.name}
                        onSubmit={() => {
                            handleTaskComplete(props.onTaskComplete, outcome.name, formState, setFormState)
                        }}
                        />
                ) : (
                    <Button key={outcome.name} id={`outcome-${outcomeIndex}`} color="primary" variant="contained" style={{float:'right', marginLeft: "5px"}}
                            disabled={missingRequiredField}
                            onClick={() => {
                                handleTaskComplete(props.onTaskComplete, outcome.name, formState, setFormState)
                            }}>
                        {outcome.name}
                    </Button>
                )
                    )
            )
        } else{
            if(props.onTaskComplete){
                formButtons.push(
                    <Button variant="contained" color="primary" style={{float:'right'}}
                            disabled={missingRequiredField}
                            onClick={() => handleTaskComplete(props.onTaskComplete, null, formState, setFormState)}>
                        Next
                    </Button>
                )
            }
        }
    }
    if(props.onTaskSave && hasModifiableField){
        formButtons.push(
            <span style={{paddingRight: "10px", float:'right'}}>
        <Button variant="outlined" onClick={async () => {
            if(props.onTaskSave){
                await props.onTaskSave(formState)
                setSaveToastOpen(true)
            }
        }} startIcon={<SaveSharpIcon fontSize="large" />}>Save</Button>
      </span>)
    }
    formButtons.push(
        <Button
            variant='outlined'
            onClick={()=>props?.onTaskCancel ? props?.onTaskCancel(formState):()=>{return}}
            startIcon={<CloseIcon fontSize="large" />}>Cancel
        </Button>
    )
    const errorStep = <Typography variant="caption" color="error">Missing required fields</Typography>
    return (
        <div style={{backgroundColor:"white", marginTop: '50px', marginRight: '10%', marginLeft:'10%'}}>
            <div style={{padding:"20px 50px 75px"}}>
                <Snackbar open={saveToastOpen} autoHideDuration={3000} onClose={()=>setSaveToastOpen(false)} anchorOrigin={{vertical:"top", horizontal:"center"}}>
                    <Alert onClose={()=>setSaveToastOpen(false)} severity="success">
                        Form was successfully saved
                    </Alert>
                </Snackbar>
                {wizardSections.length > 0 ?
                    <><Stepper activeStep={activeStep}>
                        {wizardSections.map(ws =>
                            <Step key={ws.title}>
                                <StepLabel optional={ws.missingRequiredFields ? errorStep : <></>}>{ws.title}</StepLabel>
                            </Step>)}
                    </Stepper>
                        <div>
                            {wizardSections[activeStep].fields}
                        </div></>:
                    <>{renderedFields}</>}
                <div>{formButtons}</div>
            </div>
        </div>
    )
}

function addToFormState(currentFormState: FormState, keyToAdd: string, valueToAdd: unknown): FormState {
    const newFormState = { ...currentFormState }
    newFormState[keyToAdd] = valueToAdd
    return newFormState
}

function getFormStateFromFormObject(formObject: FlowableFormObject): FormState | (() => FormState) {
    const formState: FormState = {}
    const exludeFromState = ['summary-section', 'pdf-section'];
    for(const field of formObject.fields){
        if(field.readOnly && field.type == "upload"){
            if (field.value){
                formState[field.id] = (field.value as FileDetails[]).map(o => o.id).join(",")
            }
        }
        if(field.type === "people" || field.type === "functional-group" || field.type === "modal-section" || (field.type === "dropdown" && field.params?.multiselect)){
            if(field.value){
                formState[field.id] = JSON.parse(field.value as string)
            }
        }
        else if (field.type == "upload" && field.value){
            const value = []
            for (const file of (field.value as FileDescription[])){
                value.push(file.id)
            }
            formState[field.id] = value.join(',')
        }
        else if (!exludeFromState.includes(field.type)) {
            formState[field.id] = field.value
        }
    }
    return formState
}

async function handleTaskComplete(onTaskComplete: ((outcome: string|null, formState: Record<string, unknown>)=> Promise<void>)|undefined, outcome: string|null, formState: Record<string, unknown>, setFormState: (newFormState: Record<string, unknown>)=>void){
    if(onTaskComplete){
        await onTaskComplete(outcome, formState)
    }
    setFormState({})
}
