import { List, ListItem, ListItemText } from "@material-ui/core"
import React, { useEffect, useState } from "react";
import { AccountInfo } from "../types/UserInfoTypes"
import { ProcessInstanceQueryResult } from "../types/FlowableQueryResultsTypes"
import { formatDateString12HourFromDate } from "../utils/dateUtils";
import { ProcessInstanceFilters } from "./ProcessInstanceFilter";
import {getProcesses} from "../actions/FlowableFormActions";


export function ProcessList(props:{accountInfo: AccountInfo, onProcessSelect: (p: ProcessInstanceQueryResult)=>void, processInstanceFilter:ProcessInstanceFilters}):JSX.Element{
  const [processes, setProcesses] = useState<ProcessInstanceQueryResult[]>([])
  const [selectedProcess, setSelectedProcess] = useState<ProcessInstanceQueryResult|undefined>(undefined)
  useEffect(()=>{
      (async ()=>{
        setProcesses(await getProcesses(props.accountInfo, props.processInstanceFilter))
      })()
  }, [props.processInstanceFilter])

  const processItems = []
  for(const process of processes){
    processItems.push(
      <ProcessListItem 
        processInstance={process} 
        onClick={(p)=>{
          setSelectedProcess(p)
          props.onProcessSelect(p)}
        }
        selected={process.id == selectedProcess?.id}></ProcessListItem>
    ) 
  }
  
  return <>
            <List>{processItems}</List>
        </>
}

function ProcessListItem(props: {processInstance: ProcessInstanceQueryResult, onClick: (p:ProcessInstanceQueryResult)=>void, selected: boolean}){
  return <ListItem selected={props.selected} onClick={() => props.onClick(props.processInstance)} button>
      <ListItemText primary={props.processInstance.businessKey} secondary={getProcessItemText(props)}></ListItemText>
    </ListItem>
}

function getProcessItemText(props: { processInstance: ProcessInstanceQueryResult; }): React.ReactNode {
  return <><div>{props.processInstance.processDefinitionName}</div> 
        <div>
        {`Created ${formatDateString12HourFromDate(new Date(props.processInstance.started))} by ${props.processInstance.startedBy.lastName}, ${props.processInstance.startedBy.firstName}`}
        </div></>
}