import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";

export function SessionExpire(props: {
  timeRemainingWarningInSeconds: number,
  setSessionExpire: (sessionExpire: boolean)=> void,
  getAccountInfo: () => void,
  clearLogOut: () => void,
  logout: () => void,
  }): JSX.Element {

    const [secondTillExpire, setSecondTillExpire] = useState<number>(props.timeRemainingWarningInSeconds);

    useEffect(() => {
      if(secondTillExpire === 0){
        sessionStorage.setItem('intended', window.location.hash);
        props.logout()
      }
      else{
        const timeOut = setTimeout(() => setSecondTillExpire(secondTillExpire - 1), 1000);
        return () => {
          clearTimeout(timeOut);
        }
      }
    }, [secondTillExpire]);


  return <Dialog open={true}
            onClose={() => { props.setSessionExpire(false); }}>
            <DialogTitle id="form-dialog-title">
                  Session Expiration
                  
            </DialogTitle>
            <DialogContent style={{ overflow: "hidden" }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                    Your session will expire in {secondTillExpire} second(s). Do you want to stay logged in?
                </Grid> 
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={ () => {props.setSessionExpire(false), props.logout()}} style={{marginRight:"7px"}}>
                Log out
              </Button>
              <Button color="primary" variant="contained"
                      onClick={() => {  
                        props.getAccountInfo();
                        props.setSessionExpire(false);
                        
                      }
                      }>
                      Stay logged in
              </Button>
            </DialogActions>
  </Dialog> 
}