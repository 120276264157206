import {PickerInfo} from "../components/Picker";
import axios, {AxiosResponse} from "axios";
import config from "../config";
import {AccountInfo} from "../types/UserInfoTypes";
import _ from "lodash";
import { ProcessInstanceQueryResult, TaskQueryResult } from "../types/FlowableQueryResultsTypes";

let currBusinessKey: string;

export async function getLatestTask(processId: string, accountInfo: AccountInfo | null):Promise<string|null> {
    const taskResponse = (await axios.post(`${config.flowableUiApiBaseUrl}app/rest/query/tasks`, {
        includeProcessInstance: true,
        assignee: accountInfo?.id,
        includeIdentityLinks: true,
        assignment: "involved",
        page: 0,
        sort: "created-desc",
        state: "open"
    }, { withCredentials: true })).data.data as TaskQueryResult[]

    const processInstanceResults = (await axios.post(`${config.flowableUiApiBaseUrl}app/rest/query/process-instances`,
    {
        processInstanceId:processId,
        involvedGroups: accountInfo?.groups.map(g => g.id).join(","),
        size: 10000
    }, {withCredentials:true})).data.data

    const processesKeyedByPId = _.keyBy(processInstanceResults, p => p.id)
    const tasksKeyedByPId = _.groupBy(taskResponse, t => t.processInstanceId)
    const taskRows = []

    for(const processId of _.keys(tasksKeyedByPId)){
        for(const task of tasksKeyedByPId[processId]){
            taskRows.push(
            taskInfoToTaskRow(processesKeyedByPId[processId], task)
            )
        }
    }
    //filter data based on process id given
    const taskRowsProcessFilter = taskRows.filter((tr) => tr.requestNumber === processId)

    if(taskRowsProcessFilter.length == 0){
        const taskRowsFiltered = taskRows.filter((tr) => tr.businessKey === currBusinessKey);
        if(taskRowsFiltered.length == 0) {
            return null
        }
        else {
            return taskRowsFiltered[0].id
        }
    }
    else{
        //assigning current business key
        currBusinessKey = taskRowsProcessFilter[0].businessKey;
        return taskRowsProcessFilter[0].id
    }
    
}

function taskInfoToTaskRow(processInstanceInfo: ProcessInstanceQueryResult, taskInfo: TaskQueryResult){
    return {
      id: taskInfo.id,
      businessKey: processInstanceInfo?.businessKey,
      assignee: taskInfo?.assignee,//? formatAssigneeInfo(taskInfo.assignee, processInstanceInfo) : `Unassigned ${groupsStr}`,
      completed: taskInfo.endDate,// ? getDateString(new Date(taskInfo.endDate)) : null,
      opened: taskInfo.created,// ? getDateString(new Date(taskInfo.created)):null,
      name: taskInfo.name,
      requestNumber: taskInfo.processInstanceId,
      requestor: processInstanceInfo?.startedBy// ?  `${processInstanceInfo.startedBy.lastName}, ${processInstanceInfo.startedBy.firstName}`:"Unknown"
    }
  }

export async function claimTask(taskId:string, assignee?: PickerInfo):Promise<AxiosResponse<unknown>>{
    return axios.put(`${config.flowableUiApiBaseUrl}app/rest/tasks/${taskId}/action/claim/${assignee? assignee.id:''}`, {}, {withCredentials:true})
}

export async function unclaimTask(taskId:string):Promise<AxiosResponse<unknown>>{
    return axios.put(`${config.flowableUiApiBaseUrl}app/rest/tasks/${taskId}/action/assign`,
        {},
        {withCredentials:true})
}

export function isSuperUser(accountInfo: AccountInfo): boolean {
    return _.includes(accountInfo.privileges, "access-super-admin")
}