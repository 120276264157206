import React from "react"
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

export function NotFound(): JSX.Element {
    return (
        <div style={{backgroundColor:"white", marginTop: '50px', marginRight: '10%', marginLeft:'10%'}}>
            <Grid container spacing={2} style={{ padding: 20}} >
                <Grid item xs={12} style={{fontSize: '28px', textAlign: 'center'}}>
                {`We couldn't find the page you are looking for. Click `} <Link to="/">here</Link> {` to return to the home page.`}
                </Grid>          
            </Grid>
        </div>
    )
}

