import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    TextField
} from "@material-ui/core";
import React, { useState } from "react";
import axios from "axios";
import config from "../config";
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MuiAlert from '@material-ui/lab/Alert';
import {ProcessInstanceQueryResult, ProcessVariables} from "../types/FlowableQueryResultsTypes";
import { requestAll } from "../dashboard/SAReqDash";

export type AlertTypes =
| 'success'
| 'error'

export type AlertInfo = {
    businessKey?: string
    process?:string
    reason?:string
    type?: AlertTypes
  }

export const Admin = (): JSX.Element => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [processInstances, setProcessInstances] = useState<ProcessInstanceQueryResult[]>([]);
    const [filterText, setFilterText] = useState<string>('');
    const [selectedProcessInstances, setSelectedProcessInstances] = useState<string[]>([]);
    const [alerts, setAlerts] = useState<AlertInfo[]>([]);

    const handleClose = () => {
        setOpen(false);
    };

    const loadProcessInstance = () => {
        setLoading(true);
        requestAll(`${config.flowableUiApiBaseUrl}app/rest/query/admin/process-instances`, {"state":"running","processVariableLike":{},"includeProcessVariables":false, "size": 50}).then((response) => {
            setLoading(false);
            setProcessInstances(response as ProcessInstanceQueryResult[]);
        })
    };

    async function optimizeData() {
        const optimizeList = selectedProcessInstances.map((processInstance) => updateLabel(processInstance));
        await Promise.all(optimizeList);

    }

    async function updateLabel(processInstance: string) {
        await axios.put(`${config.flowableUiApiBaseUrl}admin-app/rest/admin/process-instances/${processInstance}/variables/label`, {
                "name": "label",
                "type": "string",
                "value": ""
        }, {withCredentials: true, responseType: 'json'}).catch(() => {
            
                setAlerts((alerts) => {
                    return [...alerts, {process: processInstance, reason: "Label could not be removed", type: "error"}]
                });
            }).then((response) => {
                if (response) {
                
                    if (response.status === 200) {
                        setAlerts((alerts) => {
                            return [...alerts, {process: processInstance, reason: "Label has been removed", type: "success"}]
                        });
                    } else {
                        setAlerts((alerts) => {
                            return [...alerts, {process: processInstance, reason: "Label could not be removed", type: "error"}]
                        });
                    }
                    
                }
                updateIsaRequestDetailsSummary(processInstance);
            })
            
            
        
    }

    async function updateIsaRequestDetailsSummary(processInstance: string) {
        await axios.put(`${config.flowableUiApiBaseUrl}admin-app/rest/admin/process-instances/${processInstance}/variables/isaRequestDetailsSummary`, {
                "name": "isaRequestDetailsSummary",
                "type": "string",
                "value": ""
        }, {withCredentials: true, responseType: 'json'}).catch(() => {
                setAlerts((alerts) => {
                    return [...alerts, {process: processInstance, reason: "ISARequestDetailsSummary could not be removed", type: "error"}]
                });
            }).then((response) => {
                if (response) {
                    if (response.status === 200) {
                        setAlerts((alerts) => {
                            return [...alerts, {process: processInstance, reason: "ISARequestDetailsSummary has been removed", type: "success"}]
                        });
                    } else {
                        setAlerts((alerts) => {
                            return [...alerts, {process: processInstance, reason: "ISARequestDetailsSummary could not be removed", type: "error"}]
                        });
                    }
                    
                }
                fetchVariables(processInstance);
        });
    }

    async function fetchVariables(processInstance: string) {
        await axios.get(`${config.flowableUiApiBaseUrl}app/rest/process-instances/${processInstance}?includeProcessVariables=true`,  {withCredentials: true, responseType: 'json'}).catch(() => {

                setAlerts((alerts) => {
                    return [...alerts, {process: processInstance, reason: "Process Variables could not be fetched", type: "error"}]
                });
            }).then((response) => {
                if (response) {
                    if (response.status === 200) {
                        setAlerts((alerts) => {
                            const processInstanceResult = response.data as ProcessInstanceQueryResult;
                            const processVariables = processInstanceResult.processVariables;
                            const newProcessVariables = {...processVariables,...{variables:""}}
                            updateVariables(newProcessVariables, processInstance)
                            return [...alerts, {process: processInstance, reason: "Process Variables fetched successfully", type: "success"}]
                        });
                    } else {
                        setAlerts((alerts) => {
                            return [...alerts, {process: processInstance, reason: "Process Variables could not be fetched", type: "error"}]
                        });
                    }
                }
            });
    }

    async function updateVariables(processVariables: ProcessVariables, processInstance: string) { 
        await axios.put(`${config.flowableUiApiBaseUrl}admin-app/rest/admin/process-instances/${processInstance}/variables/variables`, {
                "name": "variables",
                "value": processVariables
        }, {withCredentials: true, responseType: 'json'}).catch(() => {

                setAlerts((alerts) => {
                    return [...alerts, {process: processInstance, reason: "Failed to update Process Variables", type: "error"}]
                });
            }).then((response) => {
                if (response) {
                    if (response.status === 200) {
                        setAlerts((alerts) => {
                            return [...alerts, {process: processInstance, reason: "Successfully updated Process Variables", type: "success"}]
                        });
                    } else {
                        setAlerts((alerts) => {
                            return [...alerts, {process: processInstance, reason: "Failed to update Process Variables", type: "error"}]
                        });
                    }
                }
            });
    }

    const renderProcessInstances = () => {

        const filteredProcessInstances = processInstances.filter((processInstance) => {
            return "siaTest" === processInstance.processDefinitionKey && processInstance.businessKey && (1 <= (parseInt(processInstance.businessKey.slice(-2)))) && (filterText ? processInstance.businessKey.toLowerCase().includes(filterText) : true);
        })

        if (filteredProcessInstances.length === 0) {
            return <div style={{marginTop: 20, marginBottom: 20}}>No matching process instances.</div>
        }

      return (
          <div style={{marginTop: 20, marginBottom: 20, height: 200}}>
            <MuiTable>
                <TableHead>
                    <TableRow>
                        <th></th>
                        <th>Process</th>
                        <th>Key</th>
                        <th>Name</th>
                        <th>Version</th>
                        <th>Started By</th>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredProcessInstances.map((processInstance) => (
                        <TableRow key={processInstance.id}>
                            <td><Checkbox
                                checked={selectedProcessInstances.includes(processInstance.id)}
                                disabled={!selectedProcessInstances.includes(processInstance.id) && selectedProcessInstances.length >= 1}
                                onChange={e => {e.target.checked ? setSelectedProcessInstances([...selectedProcessInstances, processInstance.id]) : setSelectedProcessInstances(selectedProcessInstances.filter((v) => (v !== processInstance.id)))
                                }}
                                name={`process-instance-${processInstance.id}`}
                            /></td>
                            <td>{processInstance.processDefinitionName}</td>
                            <td>{processInstance.businessKey}</td>
                            <td>{processInstance.name}</td>
                            <td style={{textAlign: 'center'}}>{processInstance.processDefinitionVersion}</td>
                            <td>{processInstance.startedBy ? `${processInstance.startedBy.firstName} ${processInstance.startedBy.lastName}` : `-`}</td>
                        </TableRow>
                    ))}
                </TableBody>
            </MuiTable>
          </div>
      );
    };

    const renderResults = () => {
        return alerts.map((result) => {
                const handleClose = () => {
                        setAlerts(alerts.filter((s) => s !== result));
                };
                const filtered = processInstances.filter((processInstance) => {
                    return result.process === processInstance.id}).map((r) => {
                        return r.businessKey
                    })
                return (
                    <MuiAlert elevation={6} variant="filled" key={`result-${result}`} onClose={handleClose} severity={result.type} style={{margin: 10}}>
                        {result.reason + ": " + filtered}
                    </MuiAlert>
                );

            });
    };

    return (
        <>
        <Button color="primary" variant="contained" onClick={() => {setOpen(true); loadProcessInstance();}}>
                Optimize Data
            </Button>
        <Dialog open={open} onClose={handleClose} maxWidth={"xl"} fullWidth>
            <DialogTitle id="form-dialog-title">
                Optimize Data
            </DialogTitle>
            <DialogContent>
                {loading ? <CircularProgress id={"migrateLoader"}/> : <Grid container spacing={3}>
                    
                        <Grid item xs={12}>
                            <FormControl style={{width: '100%'}}>
                                <TextField
                                    id="filter"
                                    label="Filter By Key"
                                    value={filterText}
                                    onChange={e => setFilterText(e.target.value)}
                                />
                            </FormControl>
                        </Grid> 
                </Grid>
                }
                <div>
                    {renderResults()}
                    
                </div>
                <div>{renderProcessInstances()}</div>

            </DialogContent>
                    <DialogActions>
            <Button variant="contained" onClick={handleClose} style={{marginRight:"7px"}}>
              CANCEL
            </Button>
            <Button color="primary" variant="contained"
                    onClick={
                        () => {
                                setAlerts([]);
                                optimizeData();
                        }
                    }>
                        Optimize
            </Button>

                    </DialogActions>
        </Dialog>
            </>
    );
};