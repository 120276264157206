import { Button, Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';
import AssignmentTurnedInIconOutlined from '@material-ui/icons/AssignmentTurnedInOutlined';
import DashboardIcon from '@material-ui/icons/Dashboard';
import HomeIcon from '@material-ui/icons/Home';
import { useHistory } from 'react-router-dom';


export function TasksComplete(): JSX.Element{
  const history = useHistory()
  return <div style={{backgroundColor:"white", marginTop: '50px', marginRight: '10%', marginLeft:'10%'}}>
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant="h3" style={{paddingLeft:"50px"}}>Tasks Complete</Typography>
      <Divider />
    </Grid>
    <Grid item xs={3}>
    <AssignmentTurnedInIconOutlined style={{ fontSize: 250, color:"#fdd835", float:"right"}}  />
    </Grid>
    <Grid item xs={9}>
    <Typography style={{paddingTop:"70px"}} variant="h4">You have completed all tasks for this request. Please periodically check the dashboard as further action might be needed at a later date.</Typography>
    </Grid>
    <Grid item xs={12}>
      <Button variant="contained" color="primary" size={"large"} style={{float:'right', marginLeft:'10px', marginRight: '20px'}} startIcon={<DashboardIcon />}  onClick={()=>history.push("/dashboard/assigned")}>
          Dashboard
      </Button>
      <Button variant="contained" color="primary" size={"large"} style={{float:'right', marginLeft:'10px', marginRight: '20px'}} startIcon={<DashboardIcon />}  onClick={()=>history.push("/newDashboard")}>
          Dashboard (Beta)
      </Button>
      <Button variant="contained" color="primary" size={"large"} style={{float:'right'}} startIcon={<HomeIcon />} onClick={()=>history.push("/")}>
          Main Page
      </Button>
    </Grid>
  </Grid></div>

}