import { CircularProgress, Grid } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import React, { useState } from "react";
import { AccountInfo } from "../types/UserInfoTypes";
import { formatDateString12HourFromDate } from "../utils/dateUtils";
import {ProcessInstanceQueryResult, SubscriptionQueryResult, TaskQueryResult} from "../types/FlowableQueryResultsTypes";
import { TaskList } from "./TaskList";
import {ActionsCard} from "../components/ActionsCard";

export function ProcessDetailsView(props:{processInstance:ProcessInstanceQueryResult, accountInfo: AccountInfo}): JSX.Element{
  const [loading] = useState<boolean>(false)
  // const candidateTaskIds = new Set(candidateTasks.map(ct => ct.id))
  if(loading){
    return <CircularProgress id={"newDashboardLoader"}/>
  }
  return <Grid container spacing={1}>
    <Grid item xs={8} alignContent={"center"}>
      <div style={{ backgroundColor: grey[200] }}>
        <h1 >
          <div>{props.processInstance.businessKey}</div>
          <div>{props.processInstance.processDefinitionName}</div>
        </h1>
        {`Created ${formatDateString12HourFromDate(new Date(props.processInstance.started))} by ${props.processInstance.startedBy.lastName}, ${props.processInstance.startedBy.firstName}`}
      </div>

     <h2>Tasks</h2>
     <TaskList 
        processInstance={props.processInstance}
        accountInfo={props.accountInfo}
        completedTasks={[] as TaskQueryResult[]}
        adHocTasks={[] as TaskQueryResult[]}
        openTasks={[] as TaskQueryResult[]}
        candidateTasks={[] as TaskQueryResult[]}
        loadData={() => {console.log('loadData')}}
        />
      </Grid>
      <Grid item xs={4}>
    <ActionsCard
      processInstance={props.processInstance}
      accountInfo={props.accountInfo}
      completedTasks={[] as TaskQueryResult[]}
      subscriptions={[] as SubscriptionQueryResult[]}
    />
    </Grid>

    </Grid>
}
