import React, { useEffect, useState } from 'react';

import {TableRow, TableCell, Table, TableBody, IconButton, CircularProgress} from "@material-ui/core";
import axios from "axios";
import config from '../config'
import fileDownload from 'js-file-download';
import GetAppIcon from '@material-ui/icons/GetApp';
import _ from 'lodash'
import { getFileDescription } from "../actions/FlowableFormActions";
import {FileDetails, FlowableFormField} from "../types/FlowableFormTypes";
import { ProcessInstanceQueryResult } from "../types/FlowableQueryResultsTypes"
import {format, parse} from "date-fns";
import {formatPhoneNumbers} from "../utils/formatUtils";

interface SummaryTableProps {
    processInstanceId:string,
    expression:string | undefined,
    priorityFields?:Record<string, unknown> | undefined,
    onLoadTableFields?:(fields:FlowableFormField[])=>void
}

const processField = (field: FlowableFormField, renderFields: FlowableFormField[]): null | JSX.Element => {
    if (field.value && field.id !== 'deniedreason' && field.type !== "expression"){
        let cellValue = field.value
        if (field.type === 'upload'){
            const tableRows = []
            const fileIds = (cellValue as string).split(",")
            for (const fileId of fileIds){
                tableRows.push(
                    <TableRow key={`file-id-${fileId}`}>
                        <FileDetailsSummary fileId={fileId}></FileDetailsSummary>
                    </TableRow>
                )
            }
            cellValue = (
                <Table><TableBody>{tableRows}</TableBody></Table>
            )
        }
        if (field.type === "date") {
            cellValue = (
                <div>{format(parse(field.value as string, "yyyy-M-d", new Date()), "MM/dd/yy")}</div>
            )
        } else if (field.type === "people"){
            const values = JSON.parse(field.value as string)
            cellValue = (
                <div>
                    <div>ID: {values.id}</div>
                    <div>Name: {values.fullName}</div>
                    {values.email ? <div>Email: {values.email}</div> : <span></span>}
                    {values.attributes ? <div>Phone: {formatPhoneNumbers(values.attributes["phone_number"][0] as string)}</div>: <span></span>}
                    {values.usertelephonenumber ? <div>Phone: {formatPhoneNumbers(values.usertelephonenumber as string)}</div>: <span></span>}
                </div>
            )
        } else if (field.type === 'summary-section' || field.name === 'Label'){
            cellValue = null
        } else if (field.type === 'modal-section'){
            if(field.id === 'newusers') { //skip this modal since it's designed for write-only
                return null;
            }
            // skip the new users modal as it's write only
            const values = JSON.parse(field.value as string)
            const tableRows = []
            for (const value of (values as Array<{ [key: string]: unknown }>)) {
                const tableCells = []
                for (const key of Object.keys(value)) {
                    const fieldDefinition = renderFields.find(f => f.id == key) as FlowableFormField
                    if (fieldDefinition) {
                        const fieldValue = []
                        if (fieldDefinition.type === 'upload') {
                            const fileIds = (value[key] as string).split(",")
                            for (const fileId of fileIds) {
                                fieldValue.push(<FileDetailsSummary key={`file-details-${fileId}`} fileId={fileId}/>)
                            }
                        } else {
                            fieldValue.push(value[key])
                        }
                        tableCells.push(
                            <div key={`field-definition-${fieldDefinition.name}`}>{fieldDefinition.name}: {fieldValue}</div>
                        )
                    } else {
                        console.log(`Unable to find field definition for: ${key}`);
                    }

                }
                tableRows.push(
                    <TableRow><TableCell>
                        {tableCells}
                    </TableCell></TableRow>
                )
            }
            cellValue = (
                <Table><TableBody>{tableRows}</TableBody></Table>
            )
        } else if (field.params && field.params.multiselect) {
            const values = JSON.parse(field.value as string)
            const rows = []
            for (const value of values){
                rows.push(<div key={`field-value-${value}`}>{value}</div>)
            }
            cellValue = (
                <span>{rows}</span>
            )
        }

        if (cellValue){
            return (
                <TableRow key={field.id as string}>
                    <TableCell style={{width: '60%'}}>{field.name}</TableCell>
                    <TableCell>{cellValue as string | JSX.Element}</TableCell>
                </TableRow>
            );
        }
    }

    return null;
};

// eslint-disable-next-line react/display-name
export const SummaryTable = React.forwardRef<HTMLInputElement, SummaryTableProps>((props: SummaryTableProps, ref) => {
  const [tableFields, setTableFields] = useState<FlowableFormField[]>([]);
  const [applications, setApplications] = useState<FlowableFormField[][]>([]);

  useEffect(()=>{
    async function doAsync(){
      try{
        props.onLoadTableFields && props.onLoadTableFields([])
        const [newTableFields, businessKey] = await getTableFields(props.processInstanceId, props.priorityFields);
        // Append the Request Number at the front of the fields taking the form of a form field.
        newTableFields.unshift({
          ...newTableFields[0],
          id: "businessKey",
          name: "Request Number",
          value: businessKey,
          type: "text",
        })

          const [applications] = await getApplications(props.processInstanceId);
        
        setTableFields(newTableFields);
        setApplications(applications);

        props.onLoadTableFields && props.onLoadTableFields(newTableFields)
      } catch(e){
        console.error(e);
        setTableFields([])
        props.onLoadTableFields && props.onLoadTableFields([])
      }
    }
    doAsync()
  }, [props.processInstanceId])

    const renderFields = _.uniqBy(tableFields, 'id');

  const table: JSX.Element[] = renderFields.reduce((accumulator: JSX.Element[], field) => {
      const processedField: JSX.Element | null = processField(field, renderFields);
      if (processedField) {
          return [...accumulator, processedField];
      }
      return accumulator;
  }, []);


    const renderApplications = () => {
        if (applications.length === 0 || props.expression !== 'variables') {
            return null;
        }

        const applicationData = applications.map((application: FlowableFormField[], applicationIndex: number) => {
            const fieldRows: (JSX.Element | null)[] = application.map((field: FlowableFormField) => {
                return processField(field, application);
            });
            return <React.Fragment key={`application-${applicationIndex}`}><h4>{`Application #${applicationIndex+1}`}</h4><Table><TableBody>{fieldRows}</TableBody></Table></React.Fragment>
        });

        return <><h2>Applications</h2>{applicationData}</>
    };

    const tableElem = table.length > 0 ? <Table><TableBody>{table}</TableBody></Table> : <CircularProgress />
    return <div className={'fieldDiv'} ref={ref}>{tableElem}{renderApplications()}</div>
  });

function FileDetailsSummary(props:{fileId: string}) {
  const [fileDetail, setFileDetail] = useState<FileDetails|null>(null)
  useEffect(()=>{
    async function getFileDetails(){
      setFileDetail(await getFileDescription(props.fileId))
    }
    getFileDetails()
  }, [props.fileId])

  return fileDetail ? <><TableCell>
    <div>Name: {fileDetail.name}</div>
    {/* <div>Size: {props.fileDetail.contentSize}</div> */}
  </TableCell>
  <TableCell>
  <IconButton style={{float:"right"}} edge="end" onClick={()=>{
    axios.get(`${config.flowableUiApiBaseUrl}app/rest/content/${fileDetail.id}/raw`, {withCredentials:true, responseType:'blob'})
      .then(res => fileDownload(res.data, fileDetail.name as string))
  }}>
    <GetAppIcon />
  </IconButton>
  </TableCell></>:<></>
}

/**
 * Pull process instance variables & combine with values entered in the Task Forms for each task in the process.
 * IF only Task Form values are used, they are typically out of date with the updated process.
 * IF `priorityFields` are specified, ONLY those fields will be rendered.
 *
 * @param processInstanceId the ID of the process instance
 * @param priorityFields an object containing the specific fields to render for the particular summary.
 */
async function getTableFields(processInstanceId:string, priorityFields:Record<string, unknown> | undefined){
    const processInstanceResponse = (await axios.get(
        `${config.flowableUiApiBaseUrl}app/rest/process-instances/${processInstanceId}?includeProcessVariables=true`,
        {
          withCredentials:true
        })).data;
      
    const {processVariables, businessKey} = processInstanceResponse;

    const taskResponse = (await axios.post(`${config.flowableUiApiBaseUrl}app/rest/query/tasks`, {
        processInstanceIdsIn: [processInstanceId].join(','),
      size:1000000,
      sort: "created-asc",
      state: "completed",
        includeProcessInstance: true
    }, { withCredentials: true })).data.data

    // get the last task for each task definition to avoid duplication
    const latestTaskForTaskDefinition = _.values(_.keyBy(taskResponse, tr => tr.taskDefinitionKey))
    const ids = latestTaskForTaskDefinition.map((data: { id: string; }) => data.id) as string[]


    // TODO: Refactor to be a bulk request on the API side instead of X number of GET calls
    const getTaskForms = ids.map(id => {
      return axios.get(`${config.flowableUiApiBaseUrl}app/rest/task-forms/${id}`, { withCredentials: true })
    })

    const taskFormFields = (await Promise.all(getTaskForms)).map(resp=> {
      return resp.data.fields
    }) as FlowableFormField[]

    let fieldsToMap: FlowableFormField[];
    if(!priorityFields || (Object.keys(priorityFields).length === Object.keys(processVariables.variables).length)) {
        fieldsToMap = _.flatMap(taskFormFields);
    } else {
        fieldsToMap = _.flatMap(taskFormFields).filter(f => _.includes(Object.keys(priorityFields), f.id));
    }
    const mapped = fieldsToMap.map( (f, i, a) => {
      if(!(null === processVariables[f.id] || undefined === processVariables[f.id])) {
        a[i].value = processVariables[f.id];
      }
      return a[i];
    }) as FlowableFormField[];

    return [mapped, businessKey];
  }

async function getApplications(processInstanceId:string){
    const processInstanceResponse = (await axios.get(
        `${config.flowableUiApiBaseUrl}app/rest/process-instances/${processInstanceId}?includeProcessVariables=true`,
        {
            withCredentials:true
        })).data;

    const {processVariables} = processInstanceResponse;

    let applicationList = processVariables.applications;

    const subProcesses: ProcessInstanceQueryResult[] = (await axios.post(`${config.flowableUiApiBaseUrl}app/rest/query/process-instances`, {
        superProcessInstanceId: processInstanceId,
        size:1000000,
        sort: "created-asc",
        state: "all",
        includeProcessInstance: true
    }, { withCredentials: true })).data.data

    if (applicationList && applicationList.length === 0 && subProcesses.length > 0) {
        const subProcessResponse = (await axios.get(
            `${config.flowableUiApiBaseUrl}app/rest/process-instances/${subProcesses[0].id}?includeProcessVariables=true`,
            {
                withCredentials:true
            })).data;

        if (subProcessResponse.processVariables && subProcessResponse.processVariables.applications && subProcessResponse.processVariables.applications.length > 0) {
            applicationList = subProcessResponse.processVariables.applications;
        }
    }

    const subProcessIds = subProcesses.map((subProcess: ProcessInstanceQueryResult) => {
        return subProcess.id;
    });

    const taskResponse = (await axios.post(`${config.flowableUiApiBaseUrl}app/rest/query/tasks`, {
        processInstanceIdsIn: [...subProcessIds, processInstanceId].join(','),
        size:1000000,
        sort: "created-asc",
        state: "completed",
        includeProcessInstance: true
    }, { withCredentials: true })).data.data

    // get the last task for each task definition to avoid duplication
    const latestTaskForTaskDefinition = _.values(_.keyBy(taskResponse, tr => tr.taskDefinitionKey))
    const ids: string[] = latestTaskForTaskDefinition.map((data: { id: string; }) => data.id) as string[]

    // TODO: Refactor to be a bulk request on the API side instead of X number of GET calls
    const getTaskForms = ids.map(id => {
        return axios.get(`${config.flowableUiApiBaseUrl}app/rest/task-forms/${id}`, { withCredentials: true })
    })

    const taskFormFields = (await Promise.all(getTaskForms)).map(resp=> {
        return resp.data.fields
    }) as FlowableFormField[]

    const fieldsToMap: FlowableFormField[] = _.flatMap(taskFormFields);

    // eslint-disable-next-line
    const applications: FlowableFormField[][] = applicationList ? applicationList.map((application: any) => {
        return Object.keys(application).reduce((applicationFields: FlowableFormField[], fieldKey: string): FlowableFormField[] => {
            const field = fieldsToMap.find(mapField => fieldKey === mapField.id);
            if (field) {
                return [...applicationFields, {...field, ...{value: application[fieldKey]}}];
            }
            return applicationFields;
        }, []);
    }) : [];

    return [applications];
}
