import {ProcessInstanceQueryResult, SubscriptionQueryResult, TaskQueryResult} from "../types/FlowableQueryResultsTypes";
import {AccountInfo} from "../types/UserInfoTypes";
import {Card, CardContent, CardHeader} from "@material-ui/core";
import {ActionsList} from "../dashboard/ActionsList";
import React from "react";

export function ActionsCard(props: { processInstance: ProcessInstanceQueryResult; completedTasks: TaskQueryResult[], accountInfo: AccountInfo, subscriptions: SubscriptionQueryResult[]}):JSX.Element {
    return <Card variant="outlined">
        <CardHeader title={"Actions"} style={{ textAlign: "center", backgroundColor: "#424242", color: "white" }} />
        <CardContent>
            <ActionsList
                processInstance={props.processInstance}
                accountInfo={props.accountInfo}
                completedTasks={props.completedTasks}
                subscriptions={props.subscriptions} />
        </CardContent>
    </Card>
}