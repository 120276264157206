import React, { useState } from "react";
import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";
import {AccountInfo, GroupInfo} from "../types/UserInfoTypes";
import config from '../config'
import { Group, Person } from "@material-ui/icons";
import _, { debounce } from "lodash";
import {createDisplayName} from "../utils/formatUtils";

export type PickerType = "GroupInfo" | "AccountInfo"
export type PickerInfo = GroupInfo | AccountInfo | null;



export function Picker(props: {
  onChange: ((picker: PickerInfo | null) => void), value?: PickerInfo | null, pickerType: PickerType,
  disabled: boolean, validationError: string, size?: 'small' | 'medium'
  , extraOptions?: AccountInfo[]
}): JSX.Element {
  const [options, setOptions] = React.useState<PickerInfo[]>([]);
  const [filterText, setFilterText] = useState("")
  const [loading, setLoading] = useState(false)

  async function fetchPeople(filterText: string, pickerType: PickerType, extraOptions?: AccountInfo[]) {
    try {
      const urlEndpoint = pickerType === "AccountInfo" ? "app/rest/workflow-users" : "app/rest/workflow-groups"
      const response = await axios.get(`${config.flowableUiApiBaseUrl}${urlEndpoint}?filter=*${filterText.replaceAll(" ", " *")}`, { withCredentials: true })

      let pickerInfo = []

      if (pickerType === "AccountInfo") {
        let tempData = response.data.data as AccountInfo[]

        const filterValid = (accounts: AccountInfo[]) =>
          accounts.filter(entry => entry.firstName && entry.lastName && entry.email)

        if (extraOptions && extraOptions?.length > 0) {

          const formattedExtras = extraOptions.map(person => (
              {...person, ...{fullName : person.firstName + " " + person.lastName, id : person.email.split('@')[0]} }
          ))

          tempData = [...tempData, ...formattedExtras];

        }

        const accountInfo = _.orderBy(filterValid(tempData), [a => a.lastName.toLocaleLowerCase('en'), a => a.firstName.toLocaleLowerCase('en')]);
        pickerInfo = _.each(accountInfo, acc => acc.displayName = createDisplayName(acc)) as PickerInfo[]
      } else {
        pickerInfo = _.each(response.data.data, grp => grp.displayName = grp.name) as PickerInfo[];
      }

      setOptions(pickerInfo);
    } catch (e) {
      console.error(e);
    }
    finally {
      setLoading(false)
    }
  }

  const debouncedSearch =  React.useRef(debounce((filterText, pickerType, extraOptions) => {
    return fetchPeople(filterText, pickerType, extraOptions)
  }, 250)).current;

  React.useEffect(() => {
      debouncedSearch(filterText, props.pickerType, props.extraOptions);
  }, [filterText,  props.extraOptions, props.pickerType]);

  return (
    <Autocomplete
      onKeyDown={(e) => e.stopPropagation()}
      size={props.size}
      id="asynchronous-demo"
      className="pocsInputDiv"
      getOptionSelected={(option, value) => (option && value) ? option.id === value.id : false}
      getOptionLabel={(option) => option?.displayName||''}
      options={options}
      loading={loading}
      onInputChange={(e, val) => {
        setFilterText(val);
      }}
      inputValue={filterText}
      filterOptions={(options) => 
         options.filter(option => {
           if (option === null) {
             return false;
           }

           if (props.pickerType === "AccountInfo") {
             return option?.fullName?.toLowerCase().includes(filterText.toLowerCase())
           } else {
             return option?.displayName?.toLowerCase().includes(filterText.toLowerCase())
           }
         })
      }
      onChange={(e, val) => {
        props.onChange(val);
      }}
      value={props.value}
      renderOption={(option: PickerInfo) => <>{props.pickerType === "AccountInfo" ?
        <span><Person /> {option?.displayName}</span> : <span><Group /> {option?.displayName}</span>}</>}
      disabled={props.disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.pickerType === 'AccountInfo' ? "Select a person..." : "Select a group..."}
          error={props.validationError.length > 0}
          helperText={props.validationError}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
