import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme, createStyles } from "@material-ui/core";
import React from "react";
import AssignmentIcon from '@material-ui/icons/Assignment';
import { AccountInfo } from "../types/UserInfoTypes";
import { Ballot } from "@material-ui/icons";

const drawerWidth = 200;

export type MenuOption = "Processes" | "Queue"
 
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      justifyContent: 'flex-end',
      ...theme.mixins.toolbar,
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }),
);

export function DashboardDrawer(props: {onSelectOption: (opt: MenuOption)=>void, selectedOption: MenuOption, accountInfo: AccountInfo}): JSX.Element{
  const classes = useStyles();

  return  <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            <div className={classes.toolbar} style={{marginTop:"75px"}} />
            <Divider />
            <List>
                <ListItem selected={props.selectedOption == "Processes"} button onClick={()=>props.onSelectOption("Processes")}>
                  <ListItemIcon><Ballot /></ListItemIcon>
                  <ListItemText primary={"Processes"} />
                </ListItem>
                <ListItem selected={props.selectedOption == "Queue"} button onClick={()=>props.onSelectOption("Queue")}>
                    <ListItemIcon>
                      <AssignmentIcon />
                    </ListItemIcon>
                  <ListItemText primary={"Queue"} />
                </ListItem>
            </List>
      </Drawer>
}