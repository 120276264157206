import { Theme } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import config from '../config'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: "5px"
  },
  avatar: {
    backgroundColor: theme.palette.primary.main
  }
}));

export function Banner(props:{show: boolean, position: ('top'|'bottom')}): JSX.Element|null {
  const classes = useStyles();
  return (
    props.show ? (
        <React.Fragment>
          <Paper elevation={0} className={classes.paper} style={{height:"30px", backgroundColor:config.classificationBannerColorHex as string, textAlign:"center", position: 'fixed', [props.position]: 0, width: '100%'}}>
            {config.classificationBannerText}
          </Paper>
          <Divider />
          <CssBaseline />
        </React.Fragment>
    ) : null
  );
}

export default Banner;