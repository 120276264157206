import {format, isValid, parse} from "date-fns"

export function formatDateString12HourFromDate(date:Date|null):string{
  if(!date){
    return ""
  }
  return `${format(date, "yyyy-MM-dd hh:mm aa")}` 
}

export function formatDateStringNoHoursFromString(dateStr:string) : string {
  let parsed:Date
  parsed = parse(dateStr, "yyyy-M-d", new Date())
  if(!isValid(parsed)){
    // format after task is completed
    parsed = parse(dateStr, "d-M-yyyy", new Date())
  }
  return format(parsed, "yyyy-MM-dd");
}