import React, {useEffect, useState} from "react";
import {
    Button,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip
} from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import axios from "axios";
import config from "../config";
import DeleteIcon from "@material-ui/icons/Delete";
import fileDownload from "js-file-download";
import GetAppIcon from "@material-ui/icons/GetApp";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {getFileDescription, uploadFormFile} from "../actions/FlowableFormActions";

export type FileDescription = {name:string, id:string}
export function FileUpload(props:{onFileAdd:(fileId:string)=>void, onFileRemove:(fileId:string)=>void, readonly:boolean, acceptedFileTypes: string[], fileIds:string[], multiple: boolean|null|undefined, taskId:string|null}):JSX.Element{
    const [files, setFiles] = useState<FileDescription[]>([])
    useEffect(()=>{
        async function fetchFiles(){
            const fileDescriptionPromises = props.fileIds.map(fId => getFileDescription(fId))
            const fileDescriptions = await Promise.all(fileDescriptionPromises)
            setFiles(fileDescriptions)
        }
        fetchFiles()
    }, [JSON.stringify(props.fileIds.sort())])
    const getUniqueName = (fileName:string, index = 0):string => {
        let checkName = fileName, ext = '';
        if(index){
            if(checkName.indexOf('.') > -1){
                const tokens = checkName.split('.'); ext = '.' + tokens.pop();
                checkName = tokens.join('.');
            }

            checkName = `${checkName} (${index})${ext}`;
        }

        const nameExists = files.filter(f => f.name === checkName).length > 0;
        return nameExists ? getUniqueName(fileName, index + 1) : checkName;
    }
    return <div className="fieldDiv">
        <div style={{width:"50%", maxHeight: "300px", overflow: "auto"}}>
            <List>
                {files.map(f =>
                    <ListItem key={"file-list-"+f.id}>
                        <ListItemIcon>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText primary={f.name} />
                        <ListItemSecondaryAction>
                            {!props.readonly ? <IconButton edge="end" aria-label="delete" onClick={() => {
                                axios.delete(`${config.flowableUiApiBaseUrl}app/rest/content/${f.id}/${props.taskId}`, {withCredentials:true})
                                    .then(() => props.onFileRemove(f.id))
                                    .catch(function(error){
                                        alert("There was an error deleting document: " + error.response.data.message)
                                    })
                            }
                            }>
                                <Tooltip title="delete">
                                    <DeleteIcon />
                                </Tooltip>
                            </IconButton>:<></>}
                            <IconButton edge="end" onClick={()=>{
                                axios.get(`${config.flowableUiApiBaseUrl}app/rest/content/${f.id}/raw`, {withCredentials:true, responseType:'blob'})
                                    .then(res => fileDownload(res.data, f.name))
                            }}>
                                <GetAppIcon />
                            </IconButton>
                        </ListItemSecondaryAction>

                    </ListItem>
                )}
            </List>
        </div>
        {!props.readonly && (props.multiple || files.length < 1) ? <Button
            component="label"
            color="primary"
            variant="contained"
            startIcon={<CloudUploadIcon />}
        >
            Upload File
            <input
                onChange={(e)=>{
                    async function handleFileUpload(taskId: string|null){
                        if(e.target.files && e.target.files[0]){
                            let file = e.target.files[0] as File
                            const uniqName = getUniqueName(file.name)
                            if (file.name != uniqName) {
                                file = new File([file], uniqName, { type: file.type });
                            }

                            const fileId = await uploadFormFile(file, taskId)
                            props.onFileAdd(fileId)
                            const newFiles = [...files]
                            newFiles.push({name: (file.name == uniqName ? file.name : uniqName), id:fileId})
                            e.target.value = ''
                        }
                    }
                    handleFileUpload(props.taskId)
                }
                }
                type="file"
                accept={props.acceptedFileTypes.map(f => `.${f.trim()}`).join(",")}
                hidden
            />
        </Button>:<></>}
    </div>
}