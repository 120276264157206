import React, { useEffect, useState } from "react"
import './Welcome.css'

import { Button, Grid } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import config from "../config";
import { PROCESS_INSTANCE_ID_QS_KEY } from "../actions/FlowableFormActions";
import { claimTask, isSuperUser } from "../actions/FlowableTaskActions";
import { TASK_ID_QS_KEY } from "../forms/ViewTask";
import { AccountInfo, GroupInfo } from "../types/UserInfoTypes";
import { TaskQueryResult } from "../types/FlowableQueryResultsTypes";
import _ from "lodash";

export function Welcome(props:{accountInfo: AccountInfo|null|undefined }): JSX.Element {

  function FormRow(buttonText: string, urlPath: string|null, listItems: Array<string>, disabled?:boolean) {
    const history = useHistory();

    function handleClick() {
      history.push(urlPath ? urlPath : '/');
    }


    return (
      <React.Fragment>
        <Grid container item xs={3} justifyContent="center" direction="column">
            <Button color="primary" variant="contained" disabled={disabled}
                onClick={handleClick}>
                {buttonText}
            </Button>
        </Grid>
        <Grid container item xs={3} justifyContent="center" direction="column">
            <ul className="buttonList">
                {listItems.map(item =>
                     <li key={"buttonListItem"+item} className="buttonListItem">{item}</li>
                )}
            </ul>
        </Grid>
      </React.Fragment>
    );
  }

    const [aboutISAProcessId, setAboutISAProcessId ] = useState("")
    const [aboutISATaskId, setAboutISATaskId ] = useState("")
    const canEditISA = _.includes(props.accountInfo?.groups.map((g:GroupInfo)=>g.id), "EditAboutISA")
    const [homeAnnouncements, setHomeAnnouncements] = useState("");
    const loginUrl = config.flowableUiApiBaseUrl + "oauth2/authorization/" + config.flowableAuthClient;
    useEffect(()=>{
        let mounted = true;
        async function doAsync(){
            try{
                const editTask = (await axios.post(`${config.flowableUiApiBaseUrl}app/rest/query/tasks`, {processDefinitionKey:"learnISA"}, {withCredentials:true}))
                    .data.data.find((t:TaskQueryResult) => t.name === "Edit Learn More Page") as TaskQueryResult;
                const editTaskId = editTask?.id
                const editTaskProcessInstanceId = editTask?.processInstanceId
                if(props.accountInfo && canEditISA){
                    try{
                        await claimTask(editTaskId, props.accountInfo)
                    // will throw exception if already claimed by same user
                    } catch(e){
                        console.error(e)
                    }
                }
                if (mounted) {
                    if (editTaskProcessInstanceId) {
                        const processInstance = (await axios.get(`${config.flowableUiApiBaseUrl}app/rest/process-instances/${editTaskProcessInstanceId}?includeProcessVariables=true`, {withCredentials:true})).data
                        if (processInstance?.processVariables?.homePageAnnouncements) {
                            setHomeAnnouncements(processInstance?.processVariables?.homePageAnnouncements);
                        }
                    }
                    setAboutISAProcessId(editTaskProcessInstanceId)
                    setAboutISATaskId(editTaskId)
                }
            } catch(e){
                console.error(e);
            }
        }
        doAsync()

        return () => {
            mounted = false;
        };
    }, [props.accountInfo])

    return (
        <div style={{backgroundColor:"white", marginTop: '50px', marginRight: '10%', marginLeft:'10%'}}>

            <Grid container spacing={2} style={{ padding: 20}} >
                <Grid item xs={12} style={{fontSize: '28px', textAlign: 'center'}}>
                    Welcome to the ICAM Service Level Agreement ({config.appName}) Home Page
                </Grid>
                <Grid container item xs={12} className="welcomeText">
                    {homeAnnouncements ? <p style={{fontWeight: 'bold'}}>{homeAnnouncements}</p> : null}
                    Use this application to request Single Sign-on access (EAMS-A login) for your
                    web application, or request direct access to the Army Master Identity Directory
                    (AMID 2.0) {aboutISAProcessId ? <Link to={`/aboutISA?${PROCESS_INSTANCE_ID_QS_KEY}=${aboutISAProcessId}`} >Click here to learn more about the ISA Process</Link> : <a></a>}
                </Grid>
                {props.accountInfo ? 
                <Grid  container item xs={12} spacing={3}>
                    {FormRow("SUBMIT A NEW ICAM SERVICE REQUEST",
                            "/siaRequest",
                        [ "Start a new SSO request", "Start a new AMID-LDAP request",
                                    "Start a new IGA request", "Start a new PAM request"])}
                    {FormRow("MANAGE MY " + config.appName + "S",
                        "/dashboard/assigned",
                        ["Review status of an " + config.appName, "Renew an " + config.appName, "Discontinue a service"])}
                </Grid> : null }
                {props.accountInfo ?
                <Grid container item xs={12} spacing={3}>
                    {FormRow("LEARN MORE ABOUT THE " + config.appName + " PROCESS",
                        `/aboutISA?${PROCESS_INSTANCE_ID_QS_KEY}=${aboutISAProcessId}`,
                        [], !aboutISAProcessId)}
                    {canEditISA && aboutISAProcessId && aboutISATaskId ? FormRow("EDIT LEARN MORE PAGE",
                        `/siaRequest?${PROCESS_INSTANCE_ID_QS_KEY}=${aboutISAProcessId}&${TASK_ID_QS_KEY}=${aboutISATaskId}`,
                        []): <></>}
                    {props.accountInfo && isSuperUser(props.accountInfo) ? (
                        FormRow("Super Admin Dashboard",
                            "/superAdminDashboard",
                            ["Export " + config.appName + " Data", "Bulk Process Definition Migrations"])
                    ) : null}
                </Grid> : null}
                {props.accountInfo === null ? 
                <Grid container item xs={12} spacing={3}  style={{textAlign: 'center', padding: 50}} alignItems='center' justifyContent='center'>   
                    <Button style={{minWidth: "300px"}} color="primary" variant="contained" onClick={() => {window.location.replace(loginUrl)}}>Log In</Button>
                </Grid> : null}
                <Grid item xs={12} style={{ textAlign: 'center'}}>
                    <div>If you need any assistance with the {config.appName} request tool or process, please contact the {config.appName} Team at <a href={ "mailto:usarmy.belvoir.peo-eis.mbx.isa-team@army.mil"}>usarmy.belvoir.peo-eis.mbx.isa-team@army.mil</a></div>
                </Grid>
            </Grid>


        </div>
    )
}

