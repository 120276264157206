import React from "react";
import { List, ListItem, ListItemText, IconButton, ListItemSecondaryAction } from "@material-ui/core"
import { yellow, green, grey } from "@material-ui/core/colors"
import axios from "axios"
import _ from "lodash"
import { useHistory } from "react-router-dom"
import { AccountInfo } from "../types/UserInfoTypes"
import config from "../config"
import { PROCESS_INSTANCE_ID_QS_KEY } from "../actions/FlowableFormActions"
import { TASK_ID_QS_KEY } from "../forms/ViewTask"
import { formatDateString12HourFromDate } from "../utils/dateUtils"
import { isSuperUser, claimTask } from "../actions/FlowableTaskActions"
import { ProcessInstanceQueryResult, TaskQueryResult } from "../types/FlowableQueryResultsTypes"
import { LightTooltip } from "../components/LightTooltip"
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import SearchIcon from '@material-ui/icons/Search';
import {formatAssigneeInfo} from "../utils/formatUtils";

export function TaskList(props:{processInstance: ProcessInstanceQueryResult; completedTasks: TaskQueryResult[]; candidateTasks: TaskQueryResult[]; adHocTasks: TaskQueryResult[]; openTasks: TaskQueryResult[]; accountInfo: AccountInfo, loadData: () => void}): JSX.Element {
  const history = useHistory()

  const candidateTaskIds = new Set(props.candidateTasks.map(ct => ct.id))

  return <>
          <List>
            {props.openTasks.map(t => <ListItem key={`openedtask-${t.id}`}>
              <PriorityHighIcon style={{ color: yellow[700], paddingRight: "4px" }} />
              <ListItemText primary={t.name} secondary={<>
                <div>{`Created ${formatDateString12HourFromDate(new Date(t.created))}`}</div>
                <div>{t.assignee ? `Assigned to ${formatAssigneeInfo(t.assignee, props.processInstance)}` : "Unassigned"}</div>
              </>}></ListItemText>
              {getSecondaryAction(t, candidateTaskIds)}
            </ListItem>
            )}
          </List>
          {props.completedTasks.length > 0 ? (
              <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                  Completed Tasks ({props.completedTasks.length})
                </AccordionSummary>
                <AccordionDetails>
                  <List style={{width: '100%'}}>
                    {props.completedTasks.map(t => {
                          const editableTask = _.find(props.adHocTasks, { 'formKey': t.formKey });
                          return <ListItem key={`completedTasks-${t.id}`}>
                            <CheckIcon style={{ color: green[500], paddingRight: "4px" }} />
                            <ListItemText style={{ color: grey[600] }} primary={t.name} secondary={<>
                              <div>{`Completed by ${formatAssigneeInfo(t.assignee, props.processInstance)} at ${formatDateString12HourFromDate(new Date(t.endDate))}`}</div>
                            </>}></ListItemText>
                            {isSuperUser(props.accountInfo) && editableTask ? <LightTooltip title="Edit">
                              <IconButton edge="end" onClick={async () => {
                                const createdAdHocTask = (await axios.post(`${config.flowableUiApiBaseUrl}app/rest/adHocTasks/${props.processInstance.id}/${editableTask.id}`, {}, { withCredentials: true })).data as TaskQueryResult;
                                history.push(`/siaRequest?${PROCESS_INSTANCE_ID_QS_KEY}=${createdAdHocTask?.processInstanceId}&${TASK_ID_QS_KEY}=${createdAdHocTask?.id}`);
                              } }>
                                <EditIcon />
                              </IconButton>
                            </LightTooltip> : <></>}
                            <LightTooltip title="View">
                              <IconButton edge="end" onClick={async () => { history.push(`/viewTask?${TASK_ID_QS_KEY}=${t.id}`); } }>
                                <SearchIcon />
                              </IconButton>
                            </LightTooltip>
                          </ListItem>;
                        }
                    )}
                  </List>
                </AccordionDetails>
              </Accordion>
          ) : null}
        </>;

  function getSecondaryAction(task: TaskQueryResult, candidateIds: Set<string>) {
      let secondaryAction = <></>;
      if (props.accountInfo.id == task.assignee?.id) {
        secondaryAction = <ListItemSecondaryAction>
          <LightTooltip title="Do">
            <IconButton edge="end" onClick={async () => { history.push(`/siaRequest?${PROCESS_INSTANCE_ID_QS_KEY}=${task.processInstanceId}&${TASK_ID_QS_KEY}=${task.id}`); } }>
              <PlayArrowIcon />
            </IconButton>
          </LightTooltip>
        </ListItemSecondaryAction>;
      } else if (candidateIds.has(task.id)) {
        secondaryAction = <ListItemSecondaryAction>
          <LightTooltip title="Claim">
            <IconButton edge="end" onClick={async () => { await claimTask(task.id); props.loadData(); } }>
              <PersonAddIcon />
            </IconButton>
          </LightTooltip>
        </ListItemSecondaryAction>;
      }
      return secondaryAction;
    }
}

