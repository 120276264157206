import axios from 'axios';
import React, { useEffect, useState } from 'react';
import config from '../config';
import { FlowableFormObject } from '../types/FlowableFormTypes';
import { useQuery } from "../actions/FlowableFormActions";
import {FlowableForm} from "./FlowableForm";

export const TASK_ID_QS_KEY = "taskId"


export function ViewTask() : JSX.Element{
  const query = useQuery()
  const taskId = query.get(TASK_ID_QS_KEY)
  const [formJson,setFormJson] = useState<FlowableFormObject|null>(null)
  const [processInstanceId, setProcessInstanceId] = useState<string|null>(null)
  useEffect(()=>{
    async function getFormJson(){
      const formResponse = axios.get(`${config.flowableUiApiBaseUrl}app/rest/task-forms/${taskId}`, { withCredentials: true })
      const taskInfoResponse = axios.get(`${config.flowableUiApiBaseUrl}app/rest/tasks/${taskId}`, { withCredentials: true })
      await Promise.all([formResponse, taskInfoResponse])

      setFormJson((await formResponse).data as FlowableFormObject)
      setProcessInstanceId((await taskInfoResponse).data.processInstanceId as string)
    }
    if(!formJson || !processInstanceId){
      getFormJson()
    }
  })
  return formJson && processInstanceId ? <FlowableForm formObject={formJson} processInstanceId={processInstanceId} taskId={taskId} requestType={null}/> : <div>No form data</div>
}