import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AccountInfo } from '../types/UserInfoTypes';
import './ISARequest.css'
import config from '../config'
import _ from "lodash"
import { useHistory } from 'react-router-dom';
import { TASK_ID_QS_KEY } from './ViewTask';
import { TasksComplete } from './TasksComplete';
import {flatten} from 'flat'
import { getLatestTask, claimTask } from '../actions/FlowableTaskActions';
import {
  CLAIM_QS_KEY,
  getFormObject,
  getProcessDefinition,
  getProcessDefinitionFieldTesting,
  getStartForm, PROCESS_INSTANCE_ID_QS_KEY,
  startProcessInstance, useQuery
} from "../actions/FlowableFormActions";
import {
  FlowableFormField,
  FlowableFormObject,
  FormState,
  ProcessDefinition,
} from "../types/FlowableFormTypes";
import {getExpressionFunc, getFormStateWithStringifiedObjects} from "./FormUtils";
import {FlowableForm} from "./FlowableForm";

/**
 * The root of the request forms and flow. An entrypoint for the form rendering.
 * @param props
 * @constructor
 */
export function ISARequest(props: {accountInfo: AccountInfo, fieldTest: boolean}): JSX.Element {

  const query = useQuery()
  const [processDefinition, setProcessDefinition] = useState<ProcessDefinition | null>(null)
  const [startForm, setStartForm] = useState<FlowableFormObject | null>(null)
  const [startFormState, setStartFormState] = useState<Record<string, unknown>|undefined>(undefined)
  const [processId, setProcessId] = useState<string | null>(query.get(PROCESS_INSTANCE_ID_QS_KEY))
  const [taskId, setTaskId] = useState<string | null>(query.get(TASK_ID_QS_KEY))
  const claim = query.get(CLAIM_QS_KEY)
  const [areTasksLeft, setAreTasksLeft] = useState<boolean>(true)
  const [executionId, setExecutionId] = useState<string|null>(null)
  const [requestType, setRequestType] = useState<string|unknown|undefined>(null)
  const history = useHistory()


  useEffect(() => {
    async function doAsync(){
      if(!processDefinition){
        let fetchedProcessDefinition
        if(props.fieldTest){
          fetchedProcessDefinition = await getProcessDefinitionFieldTesting()
        } else {
          fetchedProcessDefinition = await getProcessDefinition()
        }

        setProcessDefinition(fetchedProcessDefinition)
        if(fetchedProcessDefinition.hasStartForm){
          const fetchedStartForm = await getStartForm(fetchedProcessDefinition.id)
          setStartForm(fetchedStartForm)
        }
      }
      if (!processId && processDefinition) {
        if(!processDefinition.hasStartForm || startFormState){
          const processInstanceId = await startProcessInstance(processDefinition.id, startForm?.id, startFormState)
          setProcessId(processInstanceId)
            setRequestType(startFormState?.iprouternetwork)
        }
      }
      if(!taskId && processId){
        const latestTaskId = await getLatestTask(processId, props.accountInfo);
        setTaskId(latestTaskId)
        setAreTasksLeft(latestTaskId != null)
      }
      if(!executionId && taskId){
        const taskInfoResult = await axios.get(`${config.flowableUiApiBaseUrl}app/rest/tasks/${taskId}`, {withCredentials:true})
        setExecutionId(taskInfoResult.data.executionId)
      }
      if(executionId && taskId && claim){
        await claimTask(taskId);
      }
    }
    doAsync()
  })
  if(!areTasksLeft){
    return <TasksComplete />
  }
  if(processDefinition && processDefinition.hasStartForm && startForm && !processId && !startFormState){
    return <FlowableForm
      formObject={startForm}
      onTaskComplete={(outcome, formState)=>Promise.resolve(setStartFormState(getFormStateWithStringifiedObjects(formState)))}
      processInstanceId={null}
      onTaskCancel={()=>Promise.resolve(history.goBack())}
      taskId={taskId}
      requestType={requestType}
      ></FlowableForm>
  }
  return processId && taskId ? <ProcessInstanceTasks processInstanceId={processId} taskId={taskId} accountInfo={props.accountInfo} setTaskId={setTaskId} requestType={requestType}></ProcessInstanceTasks> : <></>
}

function ProcessInstanceTasks(props: { processInstanceId: string, taskId: string | null, setTaskId: (taskId: string|null)=> void, accountInfo: AccountInfo, requestType: string|null|unknown}) {
  const history = useHistory()
  const [formObject, setFormObject] = useState<FlowableFormObject>()
  useEffect(() => {
    getFormObject(props.taskId, setFormObject)
  }, [props.processInstanceId, props.taskId])
  return <div>{formObject ? <FlowableForm key={new Date().getTime()} formObject={formObject} onTaskComplete={async (outcome, formState) => {
    await handleFormSubmit(props.taskId, formObject.id, formState,outcome)
    props.setTaskId(null)
  }}
  onTaskSave = {(formState)=>handleFormSave(props.taskId, formObject.id, formState)}
    processInstanceId={props.processInstanceId}
    onTaskCancel={()=>Promise.resolve(history.goBack())} taskId={props.taskId} requestType={props.requestType}></FlowableForm> : <></>}</div>
}

export function fieldValidationError(field: FlowableFormField, formState: {[key:string]:unknown}): string{
  if(field.required && !formState[field.id]){
    return "Field is required"
  }
  if((field.params?.multiselect && Array.isArray(formState[field.id]) && (formState[field.id] as string[]).length === 0)){
    return "Field is required"
  }
  const validationFunc: (vars: FormState) => boolean = getExpressionFunc(field.params?.validationExpression, true)
  if(!validationFunc(flatten(formState, {safe:true}))){
    return field.params?.validationExpressionErrorMessage ? field.params.validationExpressionErrorMessage : "Field value is invalid"
  }
  switch(field.type){
    case "text":
      return isRegexError(field, formState[field.id])
    case "modal-section":
      return isModalStateInvalid(field, formState)
    case "decimal":
    case "integer":
    case "amount":
      return isNumberFieldInvalid(field, formState)
    case "dropdown":
    case "process-dropdown":
      return (field.required && field.params?.multiselect && _.isEmpty(formState[field.id])) ? "Field is required" : ""
    default:
      return ""
  }
}

function isModalStateInvalid(field: FlowableFormField, formState: {[key:string]:unknown}): string{
  if(field.required &&  (formState[field.id] as FormState[]).length == 0){
    return "Field is required"
  }
  return ""
}

function isNumberFieldInvalid(field: FlowableFormField, formState: { [key: string]: unknown; }): string {
   if(Boolean(field.params?.minLength) &&  String(formState[field.id]).length > 0 && String(formState[field.id]).length < Number(field.params?.minLength)){
    return `Number must be more than ${field.params?.minLength} digits`
   }
   return ""
}

function isRegexError(field: FlowableFormField, formVal: unknown):string {
  //  whether form val is allowed to be blank is controlled by field.required
  if(!formVal){
    return ""
  }
  if(Boolean(field.params?.regexPattern) && formVal as boolean && !new RegExp(`^${field.params?.regexPattern as string}$`).test(formVal as string)){
    return `Invalid format: Should match format of ${field.placeholder ?? field.params?.regexPattern}`
  }
  if (field.params?.mask){
    let mask = field.params?.mask ? field.params?.mask : field.params?.regexPattern as string
    if (mask){
      mask = mask.replace(/[-/\\^$+.()|[\]{}]/g, '\\$&')
                 .replaceAll("9", "\\d")
                 .replaceAll("A", "[a-zA-Z]")
                 .replaceAll("*", "[a-zA-Z0-9]");
    }
    const regx = new RegExp(`^${mask}$`)
    const value = formVal as string
    if(!regx.test(value)){
      return `Invalid format: Should match format of ${field.placeholder ?? field.params?.mask}`
    }
  }
  return ""
}

async function handleFormSubmit(taskId: string | null, formId: string, formState: Record<string,unknown>, outcome:string|null) {
  const newFormState = getFormStateWithStringifiedObjects(formState);
  await axios.post(`${config.flowableUiApiBaseUrl}app/rest/task-forms/${taskId}`, {
    outcome:outcome,
    formId: formId,
    values: newFormState
  }, { withCredentials: true })
}


async function handleFormSave(taskId: string | null, formId: string, formState: Record<string,unknown>) {
  const newFormState = getFormStateWithStringifiedObjects(formState);
  await axios.post(`${config.flowableUiApiBaseUrl}app/rest/task-forms/${taskId}/save-form`, {
    formId: formId,
    values: newFormState
  }, { withCredentials: true })
}




