import { Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import {ProcessInstanceQueryResult} from "../types/FlowableQueryResultsTypes";
import CsvDownloader from 'react-csv-downloader';
import { requestAll } from "../dashboard/SAReqDash";
import config from "../config";

interface ProcessDefinition {
    key: string;
    name: string;
}

const savedReports = [
    {
        name: "E-ICAM Government Security Team",
        variables: [
            "requestNumber",
            "legacyIsaIdentifier",
            "organizationunit",
            "organizationacronym",
            "officetelephonenumber",
            "mobiletelephonenumber",
            "requestorNotes",
            "requestorid",
            "requestor",
            "requestoralternateid",
            "requestoralternate",
            "customergovernmentsignatoryid",
            "customergovernmentsignatory",
            "customergovernmentsignatoryEmail",
            "isso",
            "issm",
            "documentationtype",
            "effectivestartdate",
            "atdexpirationdate",
            "daa",
            "iprouternetwork",
            "atotypedocumentdescription",
            "applicationname",
            "application",
            "applicationacronym",
            "applicationdescription",
            "emassidentifier",
            "environmenttype",
            "applicationdetails",
            "applicationsystemidentifier",
            "applicationsystemidentifiertype",
            "newSignOnRequestType",
            "apptechpoc",
            "apptechpocsipremail",
            "apptechpocalternatesipremail",
            "HostingLocation",
            "addnewhostingurl",
            "iaSupportTeamApprovalReason",
            "isaTeamApprovalReason",
            "returnreasonlabel"
        ]
    }
]

const compareArrays = (array1: string[], array2: string[]) => (
    array1.length === array2.length && array1.every((array1Item: string) => array2.includes(array1Item))
)

export const Export = (): JSX.Element => {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [processInstances, setProcessInstances] = useState<ProcessInstanceQueryResult[]>([]);
    const [status, setStatus] = React.useState<unknown|string>('all');
    const [processDefinitionKey, setProcessDefinitionKey] = React.useState<unknown|string>('siaTest');
    const [variables, setVariables] = useState<string[]>([]);

    useEffect(() => {
        setVariables([]);
    }, [processDefinitionKey]);

    const handleClose = () => {
        setOpen(false);
    };

    const loadProcessInstance = () => {
        setLoading(true);
        requestAll(`${config.flowableUiApiBaseUrl}app/rest/query/admin/process-instances`, {"state":"running","processVariableLike":{},"includeProcessVariables":true, "size": 50}).then((response) => {
            setLoading(false);
            setProcessInstances(response as ProcessInstanceQueryResult[]);
        })
    };

    const processDefinitions: ProcessDefinition[] = processInstances.reduce((accumulator: ProcessDefinition[], current: ProcessInstanceQueryResult) => {
        const processDefinition: ProcessDefinition | undefined = accumulator.find((item) => item.key === current.processDefinitionKey);
        if (! processDefinition) {
            return [...accumulator, {key: current.processDefinitionKey, name: current.processDefinitionName}];
        }
        return accumulator;
    }, [] as ProcessDefinition[]);

    const calculateReports = (variables: string | unknown) => {
        setVariables(variables as string[]);
    }

    const processExportData = () => {

        // eslint-disable-next-line
        const exportData: any[] = processInstances.filter((startProcessInstance: ProcessInstanceQueryResult) => (startProcessInstance.processDefinitionKey === processDefinitionKey)).reduce((accumulator: any[], current: ProcessInstanceQueryResult) => {
            // eslint-disable-next-line
            const processVariables: any = variables.reduce((accumulator: any, variable) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                accumulator[variable] = current.processVariables[variable] ? (typeof current.processVariables[variable] === 'string' ? current.processVariables[variable] : JSON.stringify(current.processVariables[variable])) : '';
                return accumulator;
                // eslint-disable-next-line
            }, {} as  any);
            return [...accumulator, processVariables];
            // eslint-disable-next-line
        }, [] as any[]);

        return Promise.resolve(exportData);
    };

    const processApplicationsExportData = () => {
        // eslint-disable-next-line
        const applicationVariables = processInstances.reduce((accumulator: any[],  current: ProcessInstanceQueryResult) => {
            if (current.processVariables.applications) {
                // eslint-disable-next-line
                const applicationsWithBusinessKey: any[] = current.processVariables.applications.map((application: any) => {
                   return {...{businessKey: current.businessKey}, ...application};
                });
                return [...accumulator, ...applicationsWithBusinessKey];
            }

            return accumulator;
            // eslint-disable-next-line
        }, [] as any[]);

        return Promise.resolve(applicationVariables);
    };

    const selectedSavedReport = () => {
        const matchingReport = savedReports.find((report) => compareArrays(variables,  report.variables));
        return matchingReport ? matchingReport.variables : '';
    }

    const variableOptions: string[] = processInstances.filter((startProcessInstance: ProcessInstanceQueryResult) => (startProcessInstance.processDefinitionKey === processDefinitionKey)).reduce((accumulator: string[], current: ProcessInstanceQueryResult) => {
        return [...accumulator, ...Object.keys(current.processVariables)];
    }, [] as string[]).reduce((accumulator: string[], current: string) => {
        if (! accumulator.includes(current)) {
            return [...accumulator, current];
        }
        return accumulator;
    }, [] as string[]).filter((variableOption: string) => {
        return variableOption !== 'applications';
    });

    return (
        <>
            <Button color="primary" variant="contained" onClick={() => {setOpen(true); loadProcessInstance();}}>
                Export Data
            </Button>
        <Dialog open={open} onClose={handleClose} maxWidth={"xl"} fullWidth>
            <DialogTitle id="form-dialog-title">
                Export Options
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={4}>
                        <FormControl style={{width: '90%'}}>
                            <InputLabel id="status">Status</InputLabel>
                            <Select
                                labelId="status"
                                id="stat    us"
                                value={status}
                                onChange={(e) => {
                                  setStatus(e.target.value);
                                }}
                            >
                                <MenuItem value={'all'}>All</MenuItem>
                                <MenuItem value={'running'}>Running</MenuItem>
                                <MenuItem value={'completed'}>Completed</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl style={{width: '90%'}}>
                            <InputLabel id="process-definition">Process Definition</InputLabel>
                            <Select
                                labelId="process-definition"
                                id="process-definition"
                                value={processDefinitionKey}
                                onChange={(e) => {
                                    setProcessDefinitionKey(e.target.value);
                                }}
                            >
                                <MenuItem key={`choose-process-definition`} value={''}>Choose Process Definition</MenuItem>
                                {processDefinitions.map((processDefinition) => (
                                    <MenuItem key={processDefinition.key} value={processDefinition.key}>{processDefinition.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl style={{width: '90%'}}>
                            <InputLabel id="saved-reports">Saved Reports</InputLabel>
                            <Select
                                labelId="saved-reports"
                                id="saved-reports"
                                defaultValue={''}
                                value={selectedSavedReport()}
                                onChange={(e) => {
                                    calculateReports(e.target.value);
                                }}
                            >
                                <MenuItem key={`choose-saved-reports`} value={''}>Choose Saved Report</MenuItem>
                                {savedReports.map((report) => (
                                    <MenuItem key={report.name} value={report.variables}>{report.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    
                </Grid>
                <h3>Variables</h3>
                <FormControlLabel
                            control={
                                <Checkbox
                                    checked={variables.length === variableOptions.length}
                                    onChange={() => {
                                        variables.length === variableOptions.length ? setVariables([]) : setVariables([...variableOptions])
                                    }}
                                    name={`select-all`}
                                />}
                            label={'Select All'}
                        />
                {loading ? <CircularProgress id={"migrateLoader"}/> : <Grid container>
                {variableOptions.sort().map((variableOption) => (
                    <Grid key={`variable-option-${variableOption}`} item xs={6}>
                        <Box component="div" textOverflow="ellipsis" style={{overflow: 'hidden'}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={variables.includes(variableOption)}
                                    onChange={e => {
                                        e.target.checked ? setVariables([...variables, variableOption]) : setVariables(variables.filter((v) => (v !== variableOption)))
                                    }}
                                    name={`variable-${variableOption}`}
                                        />}
                            label={variableOption}
                        />
                        </Box>
                    </Grid>
                ))}
                </Grid>
                }
            </DialogContent>
            {loading ? <div/> : <DialogActions>
                <CsvDownloader filename="applications" datas={processApplicationsExportData} separator="|" extension=".csv">
                    <Button color="primary" variant="contained">
                        Export Applications
                    </Button>
                </CsvDownloader>
                <CsvDownloader filename="process-definitions" datas={processExportData} separator="|" extension=".csv">
                    <Button color="primary" variant="contained">
                        Export Process Definitions
                    </Button>
                </CsvDownloader>
            </DialogActions>
            }
        </Dialog>
            <div style={{marginTop: 20}}>
                
            </div>
            </>
    );
};