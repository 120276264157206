// noinspection SpellCheckingInspection

import {
  Badge,
  Button,
  Chip,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Select,
  TableContainer,
  TablePagination,
  TableSortLabel,
  TextField,
  Theme,
  Tooltip
} from "@material-ui/core";
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import TableRow from '@material-ui/core/TableRow';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ExpandMore from '@material-ui/icons/ExpandMore';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import MenuIcon from '@material-ui/icons/Menu';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import AddCircle from '@material-ui/icons/AddCircle';
import Alert from '@material-ui/lab/Alert';
import axios, {AxiosError} from 'axios';
import { format, isValid } from "date-fns";
import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { RiFilterFill, RiFilterOffFill } from 'react-icons/ri';
import {GrSearchAdvanced} from 'react-icons/gr'
import { Link, useHistory, useParams } from "react-router-dom";
import { Row, useBlockLayout, useExpanded, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import {AccountInfo, GroupInfo} from "../types/UserInfoTypes";
import config from '../config';
import {Picker, PickerType} from "../components/Picker";
import { PROCESS_INSTANCE_ID_QS_KEY } from "../actions/FlowableFormActions";
import { TASK_ID_QS_KEY } from "../forms/ViewTask";
import './Dashboard.css';
import manageTasksIcon from "../resources/images/files-setting.svg";
import manageProcessesIcon from "../resources/images/manage.svg";
import { SummaryTable } from "../forms/SummaryTable";
import SearchIcon from '@material-ui/icons/Search';
import { Edit } from "@material-ui/icons";
import DeleteIcon from '@material-ui/icons/Delete';
import { ArchiveButton } from "../components/StyledButtons";
import {createDisplayName, formatAssigneeInfo, formatUserFullname} from "../utils/formatUtils";
import {ProcessInstanceQueryResult, SubscriptionQueryResult, TaskQueryResult} from "../types/FlowableQueryResultsTypes";
import { useReactToPrint } from 'react-to-print';
import { ProcessDetailsView } from "./ProcessDetailsView";

// START TYPES

type ProcessRow = {
  type: "ProcessRow"
  id: string;
  processName: string;
  businessKey: string;
  requestNumber: string;
  agreementExpires: Date | null;
  started: string | null;
  status: string;
  assignee: string | null
  updated: string
  updatedRaw: Date
  original: ProcessInstanceQueryResult
  actions: JSX.Element
}

type TaskRow = {
  type: "TaskRow"
  id: string;
  businessKey: string;
  requestNumber: string;
  requestor: string|null;
  opened: string | null
  completed: string | null;
  claimed: string | null;
  name: string;
  assignee: string | null
  actions: JSX.Element | null;
}

type Params = {
  menuOption: string;
}

type StateOptions = "running" | "completed" | "all"
type AssignmentOptions = "assignee" | "candidate" | null
type RestOptions = {state: StateOptions, assignment: AssignmentOptions}

type TableType = "Process" | "Task"

// END TYPES

// REST CALLS / ACTIONS / API INTERACTIONS

async function getNewProcessIdForClone(processInstance: ProcessInstanceQueryResult | null) {
  const newBusinessId = getIncrementBusinessId(processInstance?.businessKey);
  const processInstancesWithBusinessKey = await requestAll(`${config.flowableUiApiBaseUrl}app/rest/query/process-instances`,
      {
        businessKey: newBusinessId
      }) as ProcessInstanceQueryResult[];
  if (processInstancesWithBusinessKey.length != 1) {
    throw new Error(`Expected one process instance with business key ${newBusinessId} but got ${processInstancesWithBusinessKey.length}`);
  }
  return processInstancesWithBusinessKey;
}

async function claimTask(taskId:string){
  return axios.put(`${config.flowableUiApiBaseUrl}app/rest/tasks/${taskId}/action/claim`, {}, {withCredentials:true})
}

async function unclaimTask(taskId:string){
  return axios.put(`${config.flowableUiApiBaseUrl}app/rest/tasks/${taskId}/action/assign`,
      {},
      {withCredentials:true})
}
/* eslint-disable  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types  */
export async function requestAll(url: string,  options: any): Promise<any[]> {
  const size = 500;
  let page = 0;

  const initialResponse = await axios.post(url,
      {...{page, size}, ...options},
      { withCredentials: true });

  if (!initialResponse || !initialResponse.data) {
    return [];
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  let results: any[] = [...initialResponse.data.data];

  while (results.length < initialResponse.data.total) {
    page++;

    const response = await axios.post(url,
        {...{page, size}, ...options},
        { withCredentials: true });

    if (!response || !response.data) {
      break;
    }

    results = [...results, ...response.data.data];

  }

  return results;
}

async function getProcessInstanceData(processInstanceId: string): Promise<ProcessInstanceQueryResult | undefined> {
  try {
    const processIstanceData = await axios.get(
        `${config.flowableUiApiBaseUrl}app/rest/process-instances/${processInstanceId}?includeProcessVariables=false`,
        {
          withCredentials:true
        });

    return processIstanceData.data as ProcessInstanceQueryResult;

  } catch (e) {
    console.log(e)
  }

  return undefined;

}

/**
 *
 * @param accountInfo
 * @param menuOption
 * @param variableFilters
 * @param refreshFunc
 */
async function getProcessRows(accountInfo:AccountInfo, menuOption: string, variableFilters: Record<string, unknown>, refreshFunc: ()=>void): Promise<ProcessRow[]>{
  const restOptions:RestOptions = getRestOptions(menuOption)
  console.time("Get processInstanceData")
  const processUrl = `${config.flowableUiApiBaseUrl}app/rest/query${menuOption === "manage-processes" ?"/admin":""}/process-instances`

  const processInstanceData = await requestAll(processUrl,  {
    state: "all",
    ownedBy: menuOption === "my-processes" ? accountInfo.id : undefined,
    processVariableLike: variableFilters,
    processDefinitionKey: "siaTest",
    includeProcessVariables: false,
    sort: "created-desc"
  }) as ProcessInstanceQueryResult[];

  const allProcesses = await requestAll(processUrl,
      {
        ownedBy: menuOption === "my-processes" ? accountInfo.id : undefined,
        state: "running",
        includeProcessVariables: false,
        size: 1000
      }) as ProcessInstanceQueryResult[];

  const subProcessIds: string[] = allProcesses.filter((process) => {
    return process.processDefinitionKey !== "siaTest";
  }).map((process) => {
    return process.id;
  });

  const mainProcessIds: string[] = processInstanceData.map((process) => {
    return process.id;
  })

  console.timeEnd("Get processInstanceData")

  console.time("Get processInstanceTaskData")

  const taskInfos = await requestAll(`${config.flowableUiApiBaseUrl}app/rest/query/tasks`, {
    processInstanceIdsIn: menuOption === "my-processes" ? [...mainProcessIds, ...subProcessIds ].join(',') : undefined,
    assignment:restOptions.assignment,
    state: "running",
    includeIdentityLinks: true,
    size: 1000
  }) as TaskQueryResult[];

  const completedTaskInfos = await requestAll(`${config.flowableUiApiBaseUrl}app/rest/query/tasks`, {
    processInstanceIdsIn: menuOption === "my-processes" ? [...mainProcessIds, ...subProcessIds ].join(',') : undefined,
    assignment:restOptions.assignment,
    state: "completed",
    includeIdentityLinks: true,
    size: 1000
  }) as TaskQueryResult[];

    console.timeEnd("Get processInstanceTaskData")

    return processInstanceData.map((process: ProcessInstanceQueryResult) => {

      const subProcesses = allProcesses.filter((p: ProcessInstanceQueryResult) => (p.businessKey === process.businessKey && p.id !== process.id));

      const subProcessIds: string[] = subProcesses.map((p: ProcessInstanceQueryResult) => p.id);

      const runningTasks = taskInfos.filter((task: TaskQueryResult) => [...subProcessIds, process.id].includes(task.processInstanceId));
      const completedTasks = completedTaskInfos.filter((task: TaskQueryResult) => [...subProcessIds, process.id].includes(task.processInstanceId));

      return taskInfoToProcessRow(process, runningTasks,
          completedTasks, accountInfo, menuOption, refreshFunc);
    });
}

/**
 *
 * @param accountInfo
 * @param menuOption
 * @param refreshFunc
 */
async function getTaskRows(accountInfo:AccountInfo, menuOption: string, setAssignedTasks: (arg0: number) => void, setCandidateTasks: (arg0: number) => void, refreshFunc: ()=>void): Promise<TaskRow[]>{
  const restOptions = getRestOptions(menuOption);

  let taskInfos: TaskQueryResult[] = [];

  const currentUserGroups = accountInfo.groups.map(e => e.id);
  const requestTasks = (await requestAll(`${config.flowableUiApiBaseUrl}app/rest/query/tasks`,
    {
      state:restOptions.state,
      includeProcessInstance: menuOption === "completed" ? false : true,
      includeIdentityLinks: true,
      sort: "created-desc",
      size: 1000
    }) as TaskQueryResult[]).filter((val: {name: string;}) => val.name !== "Dummy Task");
  

  const assignedTaskInfos = requestTasks.filter((val: { assignee: { id: string; }; candidateGroups: string | string[]; }) => val.assignee && val.assignee.id == accountInfo.id || val.assignee !== null && currentUserGroups.some(filter => val.candidateGroups.includes(filter)))

  const candidateTasks = requestTasks.filter((task) => {
    return currentUserGroups.some(filter => task.candidateGroups.includes(filter));
  });

  const completedTasks = requestTasks.filter((task) => {
    if(menuOption !== "manage-tasks"){
      return currentUserGroups.some(filter => task.candidateGroups.includes(filter));
    }
    else {
      return requestTasks;
    }
    
  });

  const candidateTaskInfosKeyedByPId = _.keyBy(candidateTasks, t => t.processInstanceId)

  if(menuOption === "assigned"){
    taskInfos = assignedTaskInfos;
  } else if(menuOption === "candidate"){
    taskInfos = candidateTasks;
  } else if(menuOption === "manage-tasks" || menuOption === "completed"){
    taskInfos = completedTasks;
  }

  const processIds = taskInfos.map(t => t.processInstanceId)
  const processUrl = `${config.flowableUiApiBaseUrl}app/rest/query${isSuperUser(accountInfo) ?"/admin":""}/process-instances`
  const processResults = await requestAll(processUrl,
   {
      state: menuOption === "completed" ? "all" : "running",
      processInstanceIdIn:processIds.join(","),
      includeProcessVariables: true,
      involvedGroups: accountInfo.groups.map(g => g.id).join(",")
   });

  const processesKeyedByPId = _.keyBy(processResults, p => p.id)
  const tasksKeyedByPId = _.groupBy(taskInfos, t => t.processInstanceId)

  let taskRows:TaskRow[] = []

  if(menuOption != 'completed'){
  setAssignedTasks(assignedTaskInfos.length);
  setCandidateTasks(candidateTasks.length);
  }
  for(const processId of _.keys(tasksKeyedByPId)){
    for(const task of tasksKeyedByPId[processId]){
        const processInstanceData = processesKeyedByPId[processId] ?? await getProcessInstanceData(task.processInstanceId);
        taskRows.push(
          taskInfoToTaskRow(processInstanceData as ProcessInstanceQueryResult, task, !!candidateTaskInfosKeyedByPId[processId], accountInfo, refreshFunc, menuOption)
        )
    }
  }
  if(menuOption === "completed"){
    taskRows = _.sortBy(taskRows, t=>t.completed).reverse()
  } else {
    taskRows = _.sortBy(taskRows, t=>t.opened).reverse()
  }

  return taskRows;
}

// END REST CALLS / ACTIONS / API INTERACTIONS

// START UTILITIES

const getDateString = (date:Date|null):string =>{
  if(!date || !isValid(date)){
    return ""
  }
  return `${format(date, "yyyy-MM-dd hh:mm aa")}`
};

const dateBetweenFilterFn = (rows: Row<Record<string, unknown>>[], columnIds:string[], filterValues:string[]) => {
  const sd = filterValues[0] ? new Date(filterValues[0]) : undefined
  const ed = filterValues[1] ? new Date(filterValues[1]) : undefined
  if(_.isEmpty(columnIds)){
    return rows
  }
  if (ed || sd) {
    return rows.filter(r => {
      const cellDate = new Date(r.values[columnIds[0]])

      if (ed && sd) {
        return cellDate >= sd && cellDate <= ed
      } else if (sd){
        return cellDate >= sd
      } else if (ed){
        return cellDate <= ed
      }
    })
  } else {
    return rows
  }
}

const getIncrementBusinessId = (processInstanceId: string | undefined): string | undefined => {
  if(!processInstanceId){
    return processInstanceId
  }
  const increment = parseInt(processInstanceId.slice(-2)) + 1
  return processInstanceId.substring(0, processInstanceId.length - 2) + _.padStart(increment + "", 2, "0")
}

const isSuperUser = (accountInfo: AccountInfo): boolean => _.includes(accountInfo.privileges, "access-super-admin");

const isTopLevelRequestPoc = (accountInfo: AccountInfo, processInfo: ProcessInstanceQueryResult): boolean => {
  if (!processInfo || processInfo.processDefinitionKey !== "siaTest") // users will only change the top level process info - API will trickle down.
    return false

  let requestor:AccountInfo, requestorAlternate:AccountInfo;
  try {
    requestor = JSON.parse(processInfo.processVariables.requestor)
    requestorAlternate = JSON.parse(processInfo.processVariables.requestoralternate);

    return (accountInfo.id === requestor?.id || accountInfo.id === requestorAlternate?.id);
  } catch (e) {
    console.log(e)
  }
  return false
};

/**
 *
 * @param menuOption
 */
const getRestOptions = (menuOption: string):RestOptions => {
  switch (menuOption) {
    case "my-processes":
      return { state: "all", assignment: null };
    case "completed":
      return { state: "completed", assignment: "assignee" };
    case "assigned":
      return { state: "running", assignment: null };
    case "candidate":
      return { state: "running", assignment: null };
    case "manage-processes":
      return { state: "running", assignment: null }
    case "manage-tasks":
      return { state: "running", assignment: null }
    default:
      return { state: "all", assignment: null };
  }
};

/**
 *
 * @param groupsStr
 * @param accountInfo
 */
const getGroupStr = (groupsStr: string, accountInfo: AccountInfo): string | null => {
  return `Unassigned ${groupsStr && isSuperUser(accountInfo) ? groupsStr : ""}`;
};

// END UTILITIES

// ACTION BUTTONS

/**
 *
 * @param props
 * @constructor
 */
function ArchiveProcessButton(props:{processInstance:ProcessInstanceQueryResult, onDelete:()=>void}):JSX.Element {
  const [open, setOpen] = useState<boolean>(false)
  return <><Dialog
      open={open}
      onClose={()=>setOpen(false)}
  >
    <DialogTitle>
      Delete Process
      <IconButton style={{float:"right"}} onClick={()=>setOpen(false)}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      Are you sure you want to DELETE process with ID {props.processInstance.businessKey}?
    </DialogContent>
    <DialogActions>
      <ArchiveButton variant="contained" onClick={async ()=>{
        await axios.delete(`${config.flowableUiApiBaseUrl}app/rest/process-instances/${props.processInstance.id}`, {withCredentials:true})
        props.onDelete()
        setOpen(true)
      }} >
        Delete Process
      </ArchiveButton>
    </DialogActions>
  </Dialog>
    <Tooltip title={"Delete " + config.appName + " Request"}>
      <IconButton style={{padding:"3px"}} onClick={e => {
        setOpen(true)
        e.stopPropagation()
      }}>
        <DeleteIcon/>
      </IconButton>
    </Tooltip>
  </>
}

/**
 *
 * @param props
 * @constructor
 */
function ViewSummaryButton(props:{processInstanceId: string}): JSX.Element{
  const [open, setOpen] = useState<boolean>(false)
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return <>
    <Dialog
        open={open}
        onClose={()=>setOpen(false)}
    >
      <DialogTitle>
        Process Summary
        <IconButton style={{float:"right"}} onClick={()=>setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {open ? <SummaryTable ref={componentRef} processInstanceId={props.processInstanceId} expression="variables" /> : <></>}
        <Button color="primary" variant="contained" size={"small"} onClick={handlePrint}>
          Print
        </Button>
      </DialogContent>
    </Dialog>
    <Tooltip title={"View Summary"}>
    <IconButton style={{padding:"3px"}} onClick={e => {
      setOpen(true)
      e.stopPropagation()
    }}><SearchIcon /></IconButton>
    </Tooltip></>
}

/**
 *
 * @param props
 * @constructor
 */
function ChangeRequestorDialog(props:{processInstanceId:string, accountInfo: AccountInfo, onChanged?: (newRequestor: AccountInfo)=>void}){
  const [error, setError] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true);
  const [currentRequestor, setCurrentRequestor] = useState<AccountInfo|null>(null)
  const [currentAlternate, setCurrentAlternate] = useState<AccountInfo|null>(null)
  const [processInstanceInfo, setProcessInstanceInfo] = useState(null);
  const [newRequestor, setNewRequestor] = useState<AccountInfo|null>(null)
  const [newAlternate, setNewAlternate] = useState<AccountInfo|null>(null)

  useEffect(() => {
    if (open) {
      axios.get(
          `${config.flowableUiApiBaseUrl}app/rest/process-instances/${props.processInstanceId}?includeProcessVariables=true`,
          {
            withCredentials:true
          }).then((response) => {

        if (response.data) {

          const requestorAccount = response.data?.processVariables?.requestor ? JSON.parse(response.data.processVariables.requestor) : null;
          const requestorAlternateAccount = response.data?.processVariables?.requestoralternate ? JSON.parse(response.data.processVariables.requestoralternate) : null;

          setProcessInstanceInfo(response.data);
          setCurrentRequestor(requestorAccount);
          setCurrentAlternate(requestorAlternateAccount);

          setNewRequestor({...requestorAccount, ...{displayName : createDisplayName(requestorAccount)}});
          setNewAlternate(requestorAlternateAccount ? {...requestorAlternateAccount, ...{displayName : createDisplayName(requestorAlternateAccount)}} : null);
          setLoading(false);
        }
      });
    }
  }, [open]);

  const renderForm = () => {
    if (loading || !currentRequestor || !newRequestor || !processInstanceInfo) {
      return <CircularProgress id={"changeRequestorLoader"}/>;
    }

    if (!(isSuperUser(props.accountInfo) || isTopLevelRequestPoc(props.accountInfo, processInstanceInfo))) {
      return <Alert severity="error">This account does not have permission to change the requestor.</Alert>;
    }

    return (
        <>
          {error && <Alert severity="error">{error}</Alert>}
          <h3>Select New Requestor</h3>
          <Picker
              value={newRequestor}
              onChange={p => p && setNewRequestor(p as AccountInfo)}
              disabled={false}
              pickerType="AccountInfo"
              validationError=""
          ></Picker>
          <h3>Select New Alternate</h3>
          <Picker
              value={newAlternate}
              onChange={p => p && setNewAlternate(p as AccountInfo)}
              disabled={false}
              pickerType="AccountInfo"
              validationError=""
          ></Picker>
        </>
    )
  };

  return <>
    <Dialog open={open} onClose={()=>setOpen(false)}>
      <DialogTitle>
        Change Requestor
        <IconButton style={{float:"right"}} onClick={()=>handleClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {renderForm()}
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={()=>{
          setError('');

          if (currentRequestor && newRequestor) {
            if (newRequestor.id === currentRequestor.id) {
              setError('New Requestor must be different than Current Requestor');
            } else if (!currentAlternate) {
              setError('The agreement form must be completed before the requestor can be changed.')
            } else if (!newAlternate) {
              setError('New Alternate is required.')
            } else if (newAlternate.id === newRequestor.id) {
              setError('New Requestor must be different than Current Requestor');
            } else {
              changeRequestor(props.processInstanceId, newRequestor.id, currentRequestor.id, newAlternate ? newAlternate.id : '')
                  .then(() => {
                    props.onChanged && props.onChanged(newRequestor)
                  });
            }
          }
        }} >
          Change Requestor
        </Button>
      </DialogActions>
    </Dialog>

    <Tooltip title={"Change Requestor"}>
      <IconButton style={{padding:"3px"}} onClick={e => {
        setOpen(true)
        e.stopPropagation()
      }}><Edit/></IconButton>
    </Tooltip>
  </>

  function handleClose(){
    setOpen(false)
    setNewRequestor(currentRequestor)
  }
}

const getCandidateGroupMemoized = _.memoize(async (firstCandidateGroupId) => {
  let firstCandidateGroup;
  try {
    firstCandidateGroup = (await axios.get(`${config.flowableUiApiBaseUrl}app/rest/workflow-groups/${firstCandidateGroupId}`, {withCredentials:true})).data
  } catch (e) {
    if((e as AxiosError).code === 'ERR_BAD_REQUEST') {
      firstCandidateGroup = null;
    }
  }
  return firstCandidateGroup;
});


/**
 *
 * @param processInstanceId
 * @param requestor
 * @param current
 */
async function changeRequestor(processInstanceId: string, requestor: string, current: string, alternate: string){
  // Trigger the process owner change
  const updatedProcesses = await axios.post(
      `${config.flowableUiApiBaseUrl}app/rest/process-instances/${processInstanceId}/initiator`,
      {
        alternate,
        requestor
      },
      {
        withCredentials:true,
        headers: {
          'Content-Type': 'application/json'
        }
      }
  )

  // if no process ids are returned, return a string that will match no id's
  const processInstanceIdsIn: string = updatedProcesses && updatedProcesses.data && Array.isArray(updatedProcesses.data) ? updatedProcesses.data.join(',') : 'no-matching';

  // Pull all tasks associated with those processes
  const taskInfos =  await requestAll(`${config.flowableUiApiBaseUrl}app/rest/query/tasks`,
      {
        processInstanceIdsIn
      }) as TaskQueryResult[]

  // Update the task assignee to reflect the change in requestor.
  if(taskInfos && !_.isEmpty(taskInfos)) {
    await axios.put(`${config.flowableUiApiBaseUrl}app/rest/tasks/action/assign/bulk`, {
      assignee: requestor,
      taskIds: taskInfos.filter(t => t.assignee && t.assignee.id === current).map(t => t.id)
    }, {withCredentials: true})
  }
}

// END ACTION BUTTONS

function formatRequestor(processInstanceInfo: ProcessInstanceQueryResult) {
  const requestAccount = processInstanceInfo && processInstanceInfo.processVariables && processInstanceInfo.processVariables.requestor ? JSON.parse(processInstanceInfo.processVariables.requestor) : null;
  return formatUserFullname(requestAccount ? requestAccount.fullName : (processInstanceInfo && processInstanceInfo.processVariables && processInstanceInfo.processVariables.initiator ? processInstanceInfo.processVariables.initiator : '-' ));
}

function taskInfoToProcessRow(processInstanceInfo: ProcessInstanceQueryResult,openTasks: TaskQueryResult[], completedTasks: TaskQueryResult[], accountInfo: AccountInfo, menuOption:string, refreshFunc: ()=>void): ProcessRow {
  const mostRecentOpen = _.last(_.sortBy(openTasks, t => t.created)) ?? null
  const mostRecentCompleted = _.last(_.sortBy(completedTasks, t => t.endDate))
  const groupsStr = mostRecentOpen?.candidateGroups?.length ? `(Group:${mostRecentOpen?.candidateGroups.join(",")})` : ""
  const updatedDate = _.max([mostRecentOpen?.created, mostRecentCompleted?.endDate])

  return {
    type: "ProcessRow",
    id: processInstanceInfo.id,
    processName: processInstanceInfo.processDefinitionName,
    businessKey: processInstanceInfo.businessKey ?? "Not Assigned",
    requestNumber: processInstanceInfo.id,
    started: getDateString(new Date(processInstanceInfo.started)),
    status: getStatus(),
    agreementExpires: null,
    assignee: mostRecentOpen?.assignee ? formatAssigneeInfo(mostRecentOpen?.assignee, processInstanceInfo) : getGroupStr(groupsStr, accountInfo),
    updated: getDateString(new Date(updatedDate as string)),
    updatedRaw: new Date(updatedDate as string),
    original: processInstanceInfo,
    actions: <span>
      <ViewSummaryButton processInstanceId={processInstanceInfo.id}></ViewSummaryButton>
      <ChangeRequestorDialog
          accountInfo={accountInfo}
          processInstanceId={processInstanceInfo.id}
          onChanged={refreshFunc}
      />
      {isSuperUser(accountInfo) ?
          <ArchiveProcessButton processInstance={processInstanceInfo} onDelete={refreshFunc}/> : <></>}
    </span>
  }

  /**
   *
   */
  function getStatus() {
    if(processInstanceInfo.ended){
      return "Completed"
    } else if (mostRecentOpen) {
      return openTasks.map(t => t.name).join(",");
    } else {
      return "No Action Needed";
    }
  }
}

function taskInfoToTaskRow(processInstanceInfo: ProcessInstanceQueryResult, taskInfo: TaskQueryResult, isClaimable:boolean, accountInfo: AccountInfo, refreshFunc:()=>void, menuOption: string):TaskRow{
  const groupsStr = taskInfo.candidateGroups?.length > 0 ? `(Group:${taskInfo.candidateGroups.join(",")})`:""
  const claimed = processInstanceInfo?.processVariables.claimed
  const taskName = taskInfo.name;
  const claimedDate = Object(claimed)[taskName] || ''



  return {
    type:"TaskRow",
    id: taskInfo.id,
    businessKey: processInstanceInfo?.businessKey ?? "Not Assigned",
    assignee: taskInfo?.assignee ? formatAssigneeInfo(taskInfo.assignee, processInstanceInfo) + ` ${groupsStr}` : `Unassigned ${groupsStr}`,
    completed: taskInfo.endDate ? getDateString(new Date(taskInfo.endDate)) : null,
    claimed: claimedDate ? getDateString(new Date(claimedDate)) : null,
    opened: taskInfo.created ? getDateString(new Date(taskInfo.created)):null,
    name: taskInfo.name,
    requestNumber: taskInfo.processInstanceId,
    requestor: formatRequestor(processInstanceInfo),
    actions:<ActionMenu processInstance={processInstanceInfo} openTasks={[taskInfo]} completedTasks={[]} subscriptions={[]} isClaimable={isClaimable} accountInfo={accountInfo} refreshFunc={refreshFunc} menuOption={menuOption}></ActionMenu>
  }
}

const processRowColumns = [
  {
    // Make an expander cell
    headerName: () => null, // No header
    id: 'expander', // It needs an ID
    Cell: ({ row }: {
      row: Row;
    }) => (
      // Use Cell to render an expander for each row.
      // We can use the getToggleRowExpandedProps prop-getter
      // to build the expander.
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? <ExpandMore /> : <KeyboardArrowRight /> }
      </span>
    ),
    width: 20
  },
  {
    accessor: 'processName',
    headerName: 'Process Name',
    width: 180,
    Filter: SelectColumnFilter
  },
  {
    accessor: 'businessKey',
    headerName: 'Request Number',
    width: 200,
    Filter: TextColumnFilter
  },
  {
    accessor: 'assignee',
    headerName: 'Assignee',
    width: 180,
    Filter: TextColumnFilter,
  },
  {
    accessor: 'started',
    headerName: 'Started',
    width: 180,
    Filter: DateRangeColumnFilter,
    filter: 'dateBetween'
  },
  {
    accessor: 'updated',
    headerName: 'Updated',
    width: 180,
    Filter: DateRangeColumnFilter,
    filter: 'dateBetween'
  },
  {
    accessor: 'status',
    headerName: 'Status',
    Filter: SelectColumnFilter,
    filter: 'status',
    width: 125
  },
  {
    accessor: 'actions',
    headerName: 'Actions',
    width: 180,
  },
] as const;

const taskRowColumnsCandidate = [
  {
    accessor: 'businessKey',
    headerName: 'Request Number',
    width: 190,
    Filter: TextColumnFilter
  },
  {
    accessor: 'requestor',
    headerName: 'Requestor',
    width: 180,
  },
  {
    accessor: 'opened',
    headerName: 'Opened',
    width: 180,
    Filter: DateRangeColumnFilter,
    filter: 'dateBetween'
  },
  {
    accessor: 'name',
    headerName: 'Name',
    width: 250
  },
  {
    accessor: 'assignee',
    headerName: 'Assignee',
    width: 250,
  },
  {
    accessor: 'actions',
    headerName: 'Actions',
    width: 130,
  },
] as const;

  const taskRowColumnsAssigned = [
    {
      accessor: 'businessKey',
      headerName: 'Request Number',
      width: 190,
      Filter: TextColumnFilter
    },
    {
      accessor: 'requestor',
      headerName: 'Requestor',
      width: 180,
    },
    {
      accessor: 'opened',
      headerName: 'Opened',
      width: 180,
      Filter: DateRangeColumnFilter,
      filter: 'dateBetween'
    },
    {
      accessor: 'claimed',
      headerName: 'Claimed',
      width: 180,
      Filter: DateRangeColumnFilter,
      filter: 'dateBetween'
    },
    {
      accessor: 'name',
      headerName: 'Name',
      width: 250
    },
    {
      accessor: 'assignee',
      headerName: 'Assignee',
      width: 250,
    },
    {
      accessor: 'actions',
      headerName: 'Actions',
      width: 130,
    },
  ] as const;

const taskRowColumns = [
  {
    accessor: 'businessKey',
    headerName: 'Request Number',
    width: 190,
    Filter: TextColumnFilter
  },
  {
    accessor: 'requestor',
    headerName: 'Requestor',
    width: 180,
    Filter: TextColumnFilter
  },
  {
    accessor: 'opened',
    headerName: 'Opened',
    width: 180,
    Filter: DateRangeColumnFilter,
    filter: 'dateBetween'
  },
  {
    accessor: 'completed',
    headerName: 'Completed',
    width: 180,
    Filter: DateRangeColumnFilter,
    filter: 'dateBetween'
  },
  {
    accessor: 'name',
    headerName: 'Name',
    width: 250,
    Filter: TextColumnFilter
  },
  {
    accessor: 'assignee',
    headerName: 'Assignee',
    width: 250,
    Filter: TextColumnFilter
  },
  {
    accessor: 'actions',
    headerName: 'Actions',
    width: 130,
  },
] as const;

/**
 *
 * @param props
 * @constructor
 */
export function SARequestDashboard(props:{accountInfo:AccountInfo}): JSX.Element {
    const [tableType, setTableType] = useState<TableType>("Process")
    const [taskRows, setTaskRows] = useState<TaskRow[]>([])
    const [processRows, setProcessRows] = useState<ProcessRow[]>([])
    const [refresh, setRefresh] = useState<Date>(new Date())
    const [loading, setLoading] = useState<boolean>(false)
    const [variableFilters, setVariableFilters] = useState<Record<string, unknown>>({})
    const [assignedTasksNumber, setAssignedTasks] = useState<number>(0)
    const [candidateTasksNumber, setCandidateTasks] = useState<number>(0)

    const {menuOption} = useParams() as Params
    useEffect(() => {
      fetchRows(variableFilters)
    }, [menuOption, refresh, JSON.stringify(variableFilters)])


  const classes = useStyles();
    return (
      <div className={classes.root} style={{width:"100%", height:"100%"}}>
      <div style={{overflow:"auto"}}>
          <DashboardDrawer
          menuOption={menuOption}
          accountInfo={props.accountInfo}
          assignedTasksNumber={assignedTasksNumber}
          candidateTasksNumber={candidateTasksNumber}
        />
      </div>
        <div style={{ background: `url(${process.env.PUBLIC_URL + '/background-texture.png'}) repeat-x center center fixed`, width: "100%"}} >
        <div style={{backgroundColor:"white", display: 'flex', height: '100%', width:"97%", marginLeft:"30px"}}>
            {/* <div  style={{ flexGrow: 1 }}> */}
              {loading ? <CircularProgress id={"dashboardLoader"}/> :
          <DashboardTable
            processRows={processRows}
            taskRows={taskRows}
            tableType={tableType}
            accountInfo={props.accountInfo}
            title={_.startCase(menuOption.replace("-", " "))}
            onVariableFilterChange={f => setVariableFilters(f)}
            variableFilters={variableFilters}
            // renderRowSubComponent={renderRowSubComponent}
          />
                }
            </div>
        </div></div>
      // </div>
    )

    async function fetchRows( variableFilters: Record<string, unknown>){
      const refreshFunc = () => setRefresh(new Date())
      try{
        setLoading(true)
        const fetchedTaskRows = await getTaskRows(props.accountInfo, menuOption, setAssignedTasks, setCandidateTasks, refreshFunc)
        setTaskRows(fetchedTaskRows)
        if (menuOption === "my-processes" || menuOption === "manage-processes") {
          const fetchedRows = await getProcessRows(props.accountInfo, menuOption, variableFilters, refreshFunc)
          setProcessRows(fetchedRows)
          setTableType("Process")
        } else{
          setTableType("Task")
        }
      } catch (e) {
        console.log(e)
        setLoading(false)
        setTaskRows([])
        setProcessRows([])
      } finally{
        setLoading(false)
      }
    }
}

function DashboardTable(props:{tableType:TableType, processRows:ProcessRow[], taskRows:TaskRow[], accountInfo: AccountInfo, title:string, variableFilters: Record<string, unknown>, onVariableFilterChange:(filterObj:Record<string,unknown>)=>void
}): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const filterTypes = React.useMemo(
    () => ({
      dateBetween: dateBetweenFilterFn,
    }),
    []
  )

  const taskTableInstance = useTable({columns: props.title === "Candidate" ? taskRowColumnsCandidate : props.title === "Assigned" ? taskRowColumnsAssigned : taskRowColumns, data: props.taskRows, filterTypes:filterTypes},
    // useResizeColumns,
    useBlockLayout,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  )

  const processTableInstance = useTable({columns: processRowColumns, data: props.processRows, filterTypes:filterTypes},
      useBlockLayout,
      useFilters,
      useSortBy,
      useExpanded,
      usePagination,
  )

  const {
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  prepareRow,
    gotoPage,
      setPageSize,
  page,
  allColumns,
  setAllFilters,
    state: { pageSize, pageIndex }
  } = props.tableType === "Process" ? processTableInstance : taskTableInstance

  const handleChangePage:((event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void) = (event, newPage) => {
    gotoPage(newPage);
  }

  const handleChangeRowsPerPage: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined = event => {
    setPageSize(Number(event.target.value))
  }
  
  const filterOptions = {
    applicationname : "Application Name",
    applicationacronym: "Application Acronym",
    organizationunit: "Organization Name",
    organizationacronym : "Organization Acronym",
    legacyIsaIdentifier: "Legacy ISA Request Number",
    requestor: "Requestor",
    requestoralternate: "Alternate Requestor",
    customergovernmentsignatoryid: "Customer Signatory",
    addnewhostingurl : "URL/IP",
    newSignOnRequestType: "Service Type",

  }
  return <Paper style={{width:"100%", marginBottom: 30}}>

<h1 style={{paddingLeft:"40px", paddingTop:'15px'}}>{props.title}
    <div style={{float:"right"}}>
      <Button variant="outlined" size="small" startIcon={<GrSearchAdvanced />} style={{marginRight:"6px"}} onClick={e =>{
        setAnchorEl(e.currentTarget)
        e.stopPropagation()
      }} >Advanced Search</Button>
      <Button variant="outlined" size="small" style={{marginRight:"40px"}} startIcon={<RiFilterOffFill />} onClick={()=>{
        setAllFilters([])
        props.onVariableFilterChange({})
      }}>Clear Filters</Button>
    </div></h1>
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        horizontal:'left',
        vertical:'bottom'
      }}>
        <div style={{margin:"25px"}}>
          <AdditionalFilters
            filters={props.variableFilters}
            onFiltersChange={(o)=>props.onVariableFilterChange(o)}
            filterOptions={filterOptions} />
        </div>
    </Popover>
    <FilterChips
      filters={props.variableFilters}
      onFiltersChange={(o)=>props.onVariableFilterChange(o)}
      filterOptions={filterOptions}
    ></FilterChips>
    <TableContainer style={{height:"670px"}}>
    <MuiTable {...getTableProps()} stickyHeader aria-label="sticky table">
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key} >
              {
                headerGroup.headers.map(column => (
                  <TableCell {...column.getHeaderProps()} key={column.getHeaderProps().key} sortDirection={
                    column.isSorted
                      ? column.isSortedDesc
                        ? 'desc'
                        : 'asc'
                      : false
                  } >
                    <TableSortLabel active={column.isSorted} direction={column.isSorted
                      ? column.isSortedDesc
                        ? 'desc'
                        : 'asc'
                      : undefined}>
                      <span {...column.getSortByToggleProps()}>{column.render('headerName')}</span>
                  </TableSortLabel>
                  {column.canFilter && column.Filter ? column.render('Filter') : null}
                  {/* Use column.getResizerProps to hook up the events correctly */}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {page.map((row) => {
            // The type cast here is a lie but, we know that the type prepareRow accepts
            // will always match the type of row since it comes from the same TableInstance
            prepareRow(row);
            return rows.length === 0  ? <TableRow> <TableCell colSpan={6}>No records found</TableCell> </TableRow> : (
              <React.Fragment key={`page-${row.id}`}>
                <TableRow {...row.getRowProps()} key={row.getRowProps().key} >
                  {row.cells.map(cell => {
                    return (
                      <TableCell {...cell.getCellProps()} key={cell.getCellProps().key} >
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
                {row.isExpanded && row.original.type === "ProcessRow" ? (
                  <TableRow >
                    <TableCell colSpan={allColumns.length}>
                      <>
                      <ProcessDetailsView
                        row={row}
                        accountInfo={props.accountInfo}
                        processInstance={row.original.original as ProcessInstanceQueryResult}
                      />
                      </> : <></>
                    </TableCell>
                  </TableRow>
                ) : null}
              </React.Fragment>
            );
          })}
        </TableBody>
      </MuiTable>
    </TableContainer>
    <TablePagination
              rowsPerPageOptions={[
                5,
                10,
                25,
                { label: 'All', value: rows.length },
              ]}
              colSpan={3}
              count={rows.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
  </Paper>;
}

/**
 *
 * @param props
 * @constructor
 */
function ActionMenu(props:{processInstance: ProcessInstanceQueryResult|null, openTasks: TaskQueryResult[], completedTasks: TaskQueryResult[], subscriptions: SubscriptionQueryResult[], isClaimable: boolean, accountInfo:AccountInfo, refreshFunc:()=>void, menuOption: string}) {
  const actions: JSX.Element[] = []
  const totalOpenTasks = props.openTasks?.length
  for (const taskIdx in props.openTasks){
    const task = props.openTasks[taskIdx]
    const taskId = task?.id

    if(task?.processInstanceId && !task.endDate && task.assignee && task.assignee.id == props.accountInfo.id && taskId){
      actions.push(
        <MenuItem onClick={() => history.push(`/siaRequest?${PROCESS_INSTANCE_ID_QS_KEY}=${task?.processInstanceId}&${TASK_ID_QS_KEY}=${task?.id}`)} >Do  {totalOpenTasks== 1 ? 'Task' : task?.name}</MenuItem>,
        <MenuItem onClick={async ()=> {await unclaimTask(taskId); props.refreshFunc()}} >Unclaim {totalOpenTasks == 1 ? 'Task' : task?.name}</MenuItem>

      )
    }
    if(!task?.assignee && props.isClaimable && taskId){
      actions.push(
        <MenuItem onClick={async ()=> {await claimTask(taskId); props.refreshFunc()}} >Claim {totalOpenTasks == 1 ? 'Task' : task?.name}</MenuItem>
      )
      actions.push(
        <MenuItem onClick={async ()=> {
          await claimTask(taskId); props.refreshFunc();
          history.push(`/siaRequest?${PROCESS_INSTANCE_ID_QS_KEY}=${task?.processInstanceId}&${TASK_ID_QS_KEY}=${task?.id}`)
        }} >Claim and Do {totalOpenTasks == 1 ? 'Task' : task?.name}</MenuItem>
      )
    }
    if (isSuperUser(props.accountInfo) && props.menuOption !== "completed") {
      actions.push(<AssignMenuAction
        task={task}
        accountInfo={props.accountInfo}
        multipleOpenTasks={totalOpenTasks > 1}
        refreshFunc={props.refreshFunc}
      />)
    }
    if(taskId && task?.endDate && props.menuOption !== "completed"){
      actions.push(
        <MenuItem onClick={async ()=> {history.push(`/viewTask?${TASK_ID_QS_KEY}=${taskId}`)}}>View</MenuItem>
      )
    }
    if (totalOpenTasks > 1){
      actions.push(<Divider style={{marginBottom:"10px"}}/>)
    }
  }
  if(props.menuOption === "manage-processes" && props.completedTasks.length > 0){
    if(props.processInstance){
      actions.push(<EditCompletedTask processInstance={props.processInstance}></EditCompletedTask>)
    }
  }
  if(props.subscriptions){
    for(const subscription of props.subscriptions){
      actions.push(
        <MenuItem onClick={async ()=> {
          await axios.post(`${config.flowableUiApiBaseUrl}app/rest/signals/${subscription.executionId}/${encodeURIComponent(subscription.eventName)}`, {}, {withCredentials:true})
          let processInstanceIdToLaunch = props.processInstance?.id
          if(subscription.eventName === "Renew Agreement"){
            const processInstancesWithBusinessKey = await getNewProcessIdForClone(props.processInstance);
            processInstanceIdToLaunch = processInstancesWithBusinessKey[0].id
          }
          history.push(`/siaRequest?${PROCESS_INSTANCE_ID_QS_KEY}=${processInstanceIdToLaunch}`)
        }}>{subscription.eventName}</MenuItem>
      )
    }
  }

  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <ViewSummaryButton processInstanceId={props.processInstance?.id as string}></ViewSummaryButton>
      {actions.length > 0 && (
        <>
          <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            <MenuIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {actions}
          </Menu>
        </>
      )}
    </div>
  );
}

function AssignMenuAction(props: { task: TaskQueryResult, multipleOpenTasks: boolean, accountInfo: AccountInfo, refreshFunc: () => void }): JSX.Element {
  type AssigneeType = "Candidate Group" | "Assignee"
  const typeMap = {
    "Assignee": "AccountInfo",
    "Candidate Group": "GroupInfo"
  }
  const [assigneeType, setAssigneeType] = useState<AssigneeType>(props.task.assignee ? "Assignee" : "Candidate Group")
  const [open, setOpen] = useState(false)
  const [assignee, setAssignee] = useState<string|undefined>(undefined)
  const [selection, setSelection] = useState<AccountInfo|GroupInfo|null>(null)

  useEffect(()=>{
    async function getCurrentAssignmentInfo(){
      if(props.task.assignee) {
        props.task.assignee.displayName = createDisplayName(props.task.assignee);
        setSelection(props.task.assignee);
        setAssigneeType("Assignee");
      } else {
        if(props.task.candidateGroups?.length > 0){
          const firstCandidateGroupId = _.first(props.task.candidateGroups) as string
          const firstCandidateGroup = await getCandidateGroupMemoized(firstCandidateGroupId);
          if (firstCandidateGroup) {
            firstCandidateGroup.displayName = firstCandidateGroup ? firstCandidateGroup.name : '';
          }
          setAssigneeType("Candidate Group");
          setSelection(firstCandidateGroup)
        }
      }
    }
    getCurrentAssignmentInfo()
  }, [open])
  return <MenuItem
    onClick={() => setOpen(true)}>Assign {props.multipleOpenTasks ? props.task.name : 'Task'}
    <Dialog open={open}>
      <DialogTitle id="simple-dialog-title">Choose Assignee<Divider></Divider> </DialogTitle>
      <DialogContent>
      <div className={"fieldDiv"}>
      <InputLabel>Assignment Type</InputLabel>
      <TextField select
        size="small"
        onChange={e => {
            setAssigneeType(e.target.value as AssigneeType);
            setSelection(null);
        }}
        style={{ marginTop: "5px", width: "100%" }}
        variant="outlined"
        value={assigneeType}
      >
        <MenuItem value={"Assignee"}>
          <ListItemText primary="Assignee"></ListItemText>
        </MenuItem>
        <MenuItem value={"Candidate Group"}>
          <ListItemText primary="Candidate Group"></ListItemText>
        </MenuItem>
      </TextField>
      </div>
        <div className={"fieldDiv"}>
          <><InputLabel>{assigneeType == "Candidate Group" ? "Group Candidate" : "Assignee"}</InputLabel>
                <Picker disabled={false} validationError={""} pickerType={typeMap[assigneeType] as PickerType} value={selection} onChange={async p => {
                  setAssignee(p?.id);
                  setSelection(p);
                }}></Picker></>
        </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e)=>{
            e.stopPropagation();
            setOpen(false)}} color="primary">
            Cancel
          </Button>
          <Button onClick={async ()=>{
            try{
              if(assigneeType == "Assignee"){
                await axios.put(`${config.flowableUiApiBaseUrl}app/rest/tasks/${props.task.id}/action/assign`, {
                  assignee: assignee
                }, { withCredentials: true })
              } else if(assigneeType == "Candidate Group"){
                await axios.put(`${config.flowableUiApiBaseUrl}app/rest/tasks/${props.task.id}/action/setCandidateGroups`, {
                  groupIds: assignee
                }, { withCredentials: true })
              }
            } catch(e){
              console.error(e);
            } finally {
              setOpen(false)
              props.refreshFunc()
            }
          }} color="primary" variant="contained">
            Assign
          </Button>
        </DialogActions>
    </Dialog></MenuItem>
}

function EditCompletedTask(props:{processInstance: ProcessInstanceQueryResult}):JSX.Element{
  const [open, setOpen] = useState(false)
  const [editableTasks, setEditableTasks] = useState<Record<string,unknown>[]>([])
  const history = useHistory()

  return <MenuItem onClick={async ()=>{
    const fetchedEditableTasks = await getEditableTasks(props.processInstance);
    setEditableTasks(fetchedEditableTasks)
    setOpen(true)
  }}>
    Edit Completed Task
    <Dialog open={open}>
      <DialogTitle>Pick a task to edit</DialogTitle>
      <DialogContent>
        <List>
          { editableTasks.map(
              t =>
                <ListItem button key={`li-${t.name}`} onClick={ async () =>{
                  const createdAdHocTask = (await axios.post(`${config.flowableUiApiBaseUrl}app/rest/adHocTasks/${props.processInstance.id}/${t.id}`, {}, {withCredentials:true})).data as TaskQueryResult
                  history.push(`/siaRequest?${PROCESS_INSTANCE_ID_QS_KEY}=${createdAdHocTask?.processInstanceId}&${TASK_ID_QS_KEY}=${createdAdHocTask?.id}`)
                  setOpen(false)
                }}>{t.name as string}</ListItem>
            )
          }
        </List>
      </DialogContent>
      <DialogActions>
          <Button onClick={(e)=>{
            e.stopPropagation();
            setOpen(false)}} color="primary">
            Cancel
          </Button>
      </DialogActions>
    </Dialog>
  </MenuItem>
}

async function getEditableTasks(processInstance: ProcessInstanceQueryResult) {
  const completedTasks = await requestAll(`${config.flowableUiApiBaseUrl}app/rest/query/tasks`,
    { state: "completed", assignment: null, processInstanceId: processInstance.id });
  let adHocTasks = [];
  try {
    adHocTasks = (await axios.get(`${config.flowableUiApiBaseUrl}app/rest/adHocTasks/${processInstance.id}`, { withCredentials: true })).data;
  } catch (e) {
    console.error(e);
  }
  return _.intersectionBy(adHocTasks, completedTasks, (t: TaskQueryResult) => t.formKey);
}



const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      paddingTop:"30px"
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      paddingTop:"30px"
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      justifyContent: 'flex-end',
      ...theme.mixins.toolbar,
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }),
);



function DashboardDrawer(props: {menuOption: string, accountInfo: AccountInfo, assignedTasksNumber: number, candidateTasksNumber: number}){
  const classes = useStyles();
  return  <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            <div className={classes.toolbar} style={{marginTop:"45px"}} />
            <Divider />
            <List>
              <Link to={{pathname: "/dashboard/assigned" }} style={{ textDecoration: 'none' }}>
                <ListItem selected={props.menuOption === "assigned"} button >
                    <ListItemIcon>
                      <Badge badgeContent={props.assignedTasksNumber} color={"primary"}>
                        <AssignmentIcon />
                      </Badge>
                    </ListItemIcon>
                  <ListItemText style={{color: "black" }} primary={"Assigned"} />
                </ListItem>
              </Link>
              <Link to={{pathname: "/dashboard/candidate"}} style={{ textDecoration: 'none' }}>
                <ListItem selected={props.menuOption === "candidate"} button >
                    <ListItemIcon>
                      <Badge badgeContent={props.candidateTasksNumber} color={"primary"} overlap="rectangular">
                        <PeopleOutlineIcon />
                      </Badge>
                    </ListItemIcon>
                  <ListItemText style={{color: "black" }} primary={"Candidate"} />
                </ListItem>
              </Link>
              <Link to={{pathname: "/dashboard/my-processes"}} style={{ textDecoration: 'none' }}>
                <ListItem selected={props.menuOption === "my-processes"} button >
                  <ListItemIcon><AccountBoxIcon /></ListItemIcon>
                  <ListItemText style={{color: "black" }} primary={"My Processes"} />
                </ListItem>
              </Link>
              <Link to={{pathname: "/dashboard/completed"}} style={{ textDecoration: 'none' }}>
                <ListItem selected={props.menuOption === "completed"} button >
                  <ListItemIcon><AssignmentTurnedInIcon /></ListItemIcon>
                  <ListItemText style={{color: "black" }} primary={"Completed"} />
                </ListItem>
              </Link>
              {
                isSuperUser(props.accountInfo) ?
                <>
                <Link to={{pathname: "/dashboard/manage-processes"}} style={{ textDecoration: 'none' }}>
                  <ListItem selected={props.menuOption === "manage-processes"} button >
                    <ListItemIcon>
                      <Icon style={{ textAlign: 'center' }}>
                        <img style={{ height: "95%", color: "yellow" }} src={manageProcessesIcon} />
                      </Icon>
                    </ ListItemIcon>
                    <ListItemText style={{color: "black" }} primary={"Manage Processes"} />
                  </ListItem>
                </Link>
                <Link to={{pathname: "/dashboard/manage-tasks"}} style={{textDecoration: 'none'}}>
                <ListItem selected={props.menuOption === "manage-tasks"} button >
                  <ListItemIcon>
                    <Icon style={{ textAlign: 'center' }}>
                      <img style={{ height: "100%", color: "yellow" }} src={manageTasksIcon} />
                    </Icon>
                  </ListItemIcon>
                  <ListItemText style={{color: "black" }} primary={"Manage Tasks"} />
                </ListItem>
                </Link></> : <></>
                }
            </List>
      </Drawer>
}

// START FILTER COMPONENTS

function ColumnFilter(props:{children:JSX.Element, filterValue: unknown, isEmpty: (a:unknown)=>boolean}){
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  return (
  <>
    <IconButton size="small" onClick={(e) => {
      setAnchorEl(e.currentTarget)
      e.stopPropagation()}
      }
      color={props.isEmpty(props.filterValue) ? "secondary":"primary"}
      >
      <RiFilterFill></RiFilterFill>
    </IconButton>
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        horizontal:'left',
        vertical:'bottom'
      }}
      >
        <div style={{padding:"20px"}}>
          {props.children}
        </div>
    </Popover>
    </>)
}

function TextColumnFilter({
  column: { filterValue, setFilter}
}: {column:{ filterValue: string | ReadonlyArray<string> | number | undefined, preFilteredRows:Row[], setFilter:(filterValue: string | ReadonlyArray<string> | number | undefined)=>void}}):JSX.Element {
  return (
    <ColumnFilter filterValue={filterValue} isEmpty={_.isEmpty}><TextField
      variant="outlined"
      size="small"
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search...`}
      onClick={e => e.stopPropagation()}
    /></ColumnFilter>
  )
}

function SelectColumnFilter(props: { preFilteredRows:Row[], column:{id:string, setFilter:(s:string|undefined)=>void, filterValue:string}}) {
  const options = React.useMemo(() => {
    const options = new Set<string>()
    props.preFilteredRows.forEach(row => {
      const vals = row.values[props.column.id]
      // Account for rows where there might be multiple options separated by comma
      const opts = vals.includes(',') ? vals.split(',') : [vals]
      opts.forEach((o:string) => {
        options.add(o);
      })
    })
    return Array.from(options.values())
  }, [props.column.id, props.preFilteredRows])

  // Render a multi-select box
  return (
    <ColumnFilter filterValue={props.column.filterValue} isEmpty={v => _.isEmpty(v)} >
    <Select
      value={props.column.filterValue ? props.column.filterValue:"All"}
      variant="outlined"
      style={{maxHeight:"30px"}}
      defaultValue={"All"}
      onChange={e => {
        if(e.target.value === "All"){
          props.column.setFilter("")
        } else{
          props.column.setFilter((e.target.value as string | undefined)|| undefined)
        }
      }}
    >
      <MenuItem value="All">All</MenuItem>
      {options.map((option, i) => (
        <MenuItem key={`page-option-${i}`} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select></ColumnFilter>
  )
}

function DateRangeColumnFilter(props:{
  column: {
    filterValue: (string|undefined)[],
    preFilteredRows: Row[],
    setFilter:(f:(old?: never[]) => (string | undefined)[])=>void,
    id:string
  }})
{
const {filterValue, setFilter} = props.column

  return (
    <ColumnFilter filterValue={props.column.filterValue} isEmpty={v => _.isEmpty(v) || _.compact(v as string[]).length === 0} >
      <>
      <TextField
        variant="outlined"
        size="small"
        style={{width:"170px"}}
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [val ? val : undefined, old[1]])
        }}
        type="date"
        value={filterValue?.length > 1 ? filterValue[0] : ''}
      />
      <div style={{textAlign:"center"}}>to</div>
      <TextField
        variant="outlined"
        size="small"
        style={{width:"170px"}}
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? val.concat('T23:59:59.999Z') : undefined])
        }}
        type="date"
        value={filterValue?.length >= 1 ? filterValue[1]?.slice(0, 10) : ''}
      /></>
    </ColumnFilter>
  )
}

function FilterChips(props:{onFiltersChange:(filterObj:Record<string,unknown>)=>void,  filters: Record<string, unknown>, filterOptions: Record<string,string>}){
  const filterObjDivs = []
  for(const [k,v] of Object.entries(props.filters)){
    filterObjDivs.push(<Chip
      style={{marginLeft:"2px", marginRight:"2px"}}
      color="primary" onDelete={()=>{
      const filterObjCopy = {...props.filters}
      delete filterObjCopy[k]
      props.onFiltersChange(filterObjCopy)
    }} label={`${props.filterOptions[k]} : ${(v+"").slice(1, -1)}`}></Chip>)
  }
  return <>{filterObjDivs}</>
}

function AdditionalFilters(props:{onFiltersChange:(filterObj:Record<string,unknown>)=>void, filters: Record<string, unknown>, filterOptions: Record<string,string>}):JSX.Element{
  const [key, setKey] = useState("")
  const [value, setValue] = useState("")
  const menuOptions = [<MenuItem value="" key={"additional_filter_empty_menu"} >Select One...</MenuItem>]
  for(const [key, val] of Object.entries(props.filterOptions)){
    menuOptions.push(
      <MenuItem key={"additional_filter_"+key} value={key}>{val}</MenuItem>
    )
  }
  return (
    <>
    <FormControl size="small" style={{paddingRight:"7px"}}>
      <Select  displayEmpty defaultValue="" variant="outlined" onChange={e => setKey(e.target.value as string)}>
        {menuOptions}
      </Select>
    </FormControl>
    {/* <TextField label="Key" variant="outlined" size="small" onChange={e => setKey(e.target.value)} value={key}></TextField> */}
    <TextField label="Value" variant="outlined" size="small" onChange={e => setValue(e.target.value)} value={value}></TextField>
    <IconButton size="small" onClick={() => {
      if(!key || ! value){
        return
      }
      const filterObjCopy = {...props.filters}
      filterObjCopy[key] = `%${value}%`
      props.onFiltersChange(filterObjCopy)
      setValue("")
      setKey("")
    }}><AddCircle /></IconButton>
    </>
  )
}

// END FILTER COMPONENTS
