import { Button, Dialog, DialogContent, DialogTitle, IconButton, InputLabel, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { fieldValidationError } from "./ISARequest";
import { FileUpload } from "../components/FileUpload";
import config from '../config';
import axios from "axios";
import { AccountInfo } from "../types/UserInfoTypes";

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { StyledButton } from "../header/Header";
import AddIcon from '@material-ui/icons/Add';
import _ from "lodash";
import {FlowableFormField, FormState} from "../types/FlowableFormTypes";
import {renderField} from "./FieldRenderer";
import { getExpressionFunc } from "./FormUtils";
import flatten from "flat";

export type PickerInfo = AccountInfo;

export function FormModal(props: { formState: FormState, modalFields: FlowableFormField[], required: boolean,
      modalButtonText: (string|null), modalTableName: string, modalTableColumns: {[key: string]: string},
      addToFormState: (fs: FormState, key:string, value:unknown) => FormState,
      handleModalSubmit:((tableName:string, table: FormState[])=>void),
  processInstanceId:string|null, disabled:boolean, taskId:string|null, requestType:string|null|unknown}): JSX.Element{

  const [modalState, setModalState] = useState<FormState>({})
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(-1);
  const [allUsers, setUsers] = useState<AccountInfo[] | undefined>();

  useEffect(() => {
    // Async effects could cause memory leaks so it is important to perform cleanup on component unmount.
    const abortController = new AbortController();
    const getUsers = async () => {
      const urlEndpoint = "app/rest/workflow-users";
      const response = await axios.get(`${config.flowableUiApiBaseUrl}${urlEndpoint}`, { withCredentials: true })
      const users = response.data.data as PickerInfo[];
      setUsers(users);
    }
    getUsers();

    return () => {
      abortController.abort(); // cancel pending fetch request on component unmount
    };
  }, []);

  const handleOpen = (row: FormState) => {
      setOpen(true);
      setModalState(row);
      const table = props.formState[props.modalTableName] as FormState[]
      const index = table ? table.indexOf(row) : -1
      setEdit(index)
  };

  const handleClose = () => {
      setOpen(false);
      setModalState({});
      setEdit(-1);
  };

  function isThereDuplicateEmails(table:FormState[]){
    for(let i = 0; allUsers != undefined && i < allUsers.length; i++){
      if(allUsers[i].email == table[table.length - 1].email){
        return true;
      }
    }

    if(table.length - 1 > 0){
      const lastestEmailEntered = table[table.length - 1].email;
      for(let i = 0; i < table.length - 1; i++){
        if(table[i].email == lastestEmailEntered){
          return true;
        }
      }
      return false;
    }
  }

  const handleSubmit = () => {
    setOpen(false);

    let table:FormState[] = [];
    if (props.formState[props.modalTableName] as FormState[]) {
        table = (props.formState[props.modalTableName] as FormState[]).slice();
    }

    if (edit < 0){
      table.push(modalState)
    } else{
      table.splice(edit, 1, modalState)
    }

    if(props.modalTableName == "newusers"){
      if(!isThereDuplicateEmails(table)){
        props.handleModalSubmit(props.modalTableName, table);
        setModalState({});
      }else{
        alert("Email already in use.")
        setOpen(true);
      }
    }else{
      props.handleModalSubmit(props.modalTableName, table);
      setModalState({});
    }
  };

  const deleteRow = (row: FormState) => {
    const table = props.formState[props.modalTableName] as FormState[]
    const index = table.indexOf(row)
    table.splice(index, 1)
    props.handleModalSubmit(props.modalTableName, table);
  }

  const modalMissingRequiredFields = () =>{
    return props.modalFields.some(field => {
      const visibilityFunc = getExpressionFunc(field.params?.visibilityExpression, true)
      const isVisible = visibilityFunc(flatten(modalState, {safe:true}))
      if(isVisible) {
        return fieldValidationError(field, modalState)
      } else {
        return false
      }
    })
  }

  const generateModalTable = () => {
    const modalFieldsById = _.keyBy(props.modalFields, mf => (mf.id))
    const modalTableState = props.formState[props.modalTableName] as FormState[]
    if (modalTableState?.length > 0){
      const keys = Object.keys(props.modalTableColumns)
      const rows = []
      for (const row of modalTableState){
        const columns = []
        for (const key of keys){
          const fieldDefinition = modalFieldsById[key]
          let cellBody = null
          if(fieldDefinition.type === "upload" && row[key]){
            const fileIds = (row[key] as string).split(",")
            cellBody = <FileUpload
            onFileAdd={() => {return}}
            onFileRemove={() => {return}}
            readonly = {true}
            acceptedFileTypes = {(fieldDefinition.params?.allowedFileTypes ?? "").split(",")}
            fileIds={fileIds}
            multiple={fieldDefinition.params?.multiple}
            taskId={props.taskId}
            />
          }else{
            cellBody = row[key] ? String(row[key]) : ''
          }
          columns.push(<TableCell key={key}>{cellBody}</TableCell>)
        }
        columns.push(
          <TableCell key="edit">
            <IconButton edge="end" onClick={()=>{handleOpen(row)}} disabled={props.disabled}>
              <EditIcon/>
            </IconButton>
            <IconButton edge="end" onClick={()=>{deleteRow(row)}} disabled={props.disabled}>
              <DeleteIcon/>
            </IconButton>
          </TableCell>
        )
        rows.push(<TableRow > {columns} </TableRow>)
      }
      return <Table>
          <TableHead>
            {keys.map(key =>
              <TableCell key={key}>{props.modalTableColumns[key].toUpperCase()}</TableCell>)}
            <TableCell key="edit">MODIFY</TableCell>
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
    }
    else if (props.required){
      return (
        <InputLabel error={true}>
          required*
        </InputLabel>
      )
    }
  }

  return(
    <span>
      {
        generateModalTable()
      }
      <StyledButton startIcon={<AddIcon />} onClick={()=>handleOpen({})} color="secondary" variant="contained"
          style={{marginBottom:"20px", marginTop:"10px", color:"white"}} disabled={props.disabled}>
        {props.modalButtonText}
      </StyledButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="form-dialog-title">
          {props.modalButtonText}
        </DialogTitle>
        <DialogContent>

          {props.modalFields.map(field => {
             if(props.requestType != "SIPR" || field.id != "publicfacingserverip"){
                return renderField(field, props.modalFields, modalState, setModalState, props.addToFormState,props.taskId)
              }
            })
          }
          <span style={{float: "right", marginBottom:"10px"}}>
            <Button variant="contained" onClick={handleClose} style={{marginRight:"7px"}}>
              CANCEL
            </Button>
            <Button color="primary" variant="contained"
                onClick={handleSubmit}
                disabled={modalMissingRequiredFields()} >
              OK
            </Button>
          </span>
        </DialogContent>
      </Dialog>

    </span>
  )
}
